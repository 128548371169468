import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import forgetPasswordImage from "../../../assets/svg/forgetPasswordImage.svg";
import emailIcon from "../../../assets/svg/emailIcon.svg";
import { useNavigate } from "react-router-dom";
import { useForgetPasswordMutation } from "../../../api/auth";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [forgetPassword] = useForgetPasswordMutation();

  const handleForgetPassword = async (values) => {
    const data = await forgetPassword(values);
    if (data?.data?.email) {
      localStorage.setItem("token", data?.data?.token);
      navigate("/verification", { state: values });
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
  });

  return (
    <>
      <div className="container">
        <div className="w-full row items-center justify-center h-screen">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-none d-lg-block">
            <div className="mr-16">
              <img alt="loginImage" src={forgetPasswordImage} />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <span className="text-[#8656A5] font-sfpro font-bold text-3xl">
              Please enter email address
            </span>
            <br />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleForgetPassword}
            >
              {({ handleSubmit }) => (
                <>
                  <Form onSubmit={handleSubmit}>
                    <div>
                      <label className="w-full h-[100px] mt-16 text-start font-sfpro font-bold text-base text-[#939393]">
                        Email
                        <div className="relative mt-2">
                          <img
                            alt="email"
                            className="absolute z-10 top-4 left-4"
                            src={emailIcon}
                          />
                          <Field
                            type="text"
                            name="email"
                            className="w-full relative font-semibold font-sfpro text-[#8656A5] text-base border-2 rounded-full border-[#8656A5] pl-12 pr-2 py-2"
                          />
                        </div>
                        <div className="text-red-600 text-sm">
                          <ErrorMessage name="email" />
                        </div>
                      </label>
                    </div>
                    <div>
                      <label className="w-full mt-3 text-start font-sfpro font-medium text-base text-black">
                        We will send you the 6 digit verification code on this
                        email address.
                      </label>
                    </div>
                    <div className="flex items-center justify-center mt-16">
                      <button
                        type="submit"
                        onSubmit={handleForgetPassword}
                        className="flex justify-center gap-4 items-center font-sfpro bg-[#8656A5] text-white w-[30rem] py-2 border-3 border-gray-300 rounded-xl"
                      >
                        Generate OTP
                      </button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
