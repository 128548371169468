import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import leftSideArrow from "../../../assets/svg/leftSideArrow.svg";
import * as _ from "lodash";
import dotIcon from "../../../assets/svg/dotIcon.svg";
import LayoutContainer from "../../layout/LayoutContainer";
import { Navbar } from "react-bootstrap";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useLiveStudentByGameMutation } from "../../../api/game";
import { socket } from "../../../utils/constants/url";
import userImg from "../../../assets/svg/userImg.jpeg";

const FitBoundsMap = ({ markers }) => {
  const map = useMap();

  if (markers.length === 0) {
    return null;
  }

  const markerLatitudes = markers.map((marker) => marker.latitude);
  const markerLongitudes = markers.map((marker) => marker.longitude);

  const bounds = L.latLngBounds(
    L.latLng(Math.min(...markerLatitudes), Math.min(...markerLongitudes)),
    L.latLng(Math.max(...markerLatitudes), Math.max(...markerLongitudes))
  );

  map.fitBounds(bounds);

  return null;
};

const LiveLocation = () => {
  const location = useLocation();
  const gameName = location.state?.gameName || location.state?.name;
  const gameId = location.state?.gameId;
  const tourId = location.state?.tourId;

  const [studentData, setStudentData] = useState([]);
  const [socketStudentData, setsocketStudentData] = useState([]);
  const [selectedStudentLatitude, setSelectedStudentLatitude] = useState("");
  const [selectedStudentLongitude, setSelectedStudentLongitude] = useState("");

  const [liveStudent] = useLiveStudentByGameMutation();

  useEffect(() => {
    const ws = new WebSocket(socket.baseURL);
    ws.onopen = () => {
      console.log("Connected with server");
      // ws.send(gameId);
    };
    ws.onmessage = (data) => {
      // console.log("Message from server=========", data);
      const records = JSON.parse(data?.data);
      if (data?.data) {
        setsocketStudentData([records]);
      } else {
        setsocketStudentData([]);
      }
    };
  }, []);

  const handleLiveGame = async () => {
    const values = { gameId, tourId };
    const data = await liveStudent(values);
    if (data?.data?.status === "success") {
      setStudentData(data?.data?.game);
    }
  };

  const handleStudentLocation = (latitude, longitude) => {
    setSelectedStudentLatitude(latitude);
    setSelectedStudentLongitude(longitude);
  };

  useEffect(() => {
    handleLiveGame();
    // const interval = setInterval(() => handleLiveGame(), 10000);
    // return () => {
    //   clearInterval(interval);
    // };
  }, [liveStudent]);

  useEffect(() => {
    const updatedStudentData = [...studentData];
    socketStudentData.forEach((socketItem) => {
      const index = updatedStudentData.findIndex(
        (item) => item.studentId === socketItem.studentId
      );
      if (index !== -1) {
        updatedStudentData[index] = socketItem;
      } else {
        updatedStudentData.push(socketItem);
      }
      setStudentData(updatedStudentData);
    });
  }, [socketStudentData]);

  const allStudentData = _.map(studentData, (item) => {
    const firstName = _.get(item, "firstName", "");
    const lastName = _.get(item, "lastName", "");
    return {
      studentId: _.get(item, "studentId", ""),
      userId: _.get(item, "userId", ""),
      code: _.get(item, "code", ""),
      gameDetailId: _.get(item, "gameDetailId", ""),
      studentName:
        firstName.charAt(0).toUpperCase() +
        firstName.slice(1) +
        " " +
        lastName.charAt(0).toUpperCase() +
        lastName.slice(1),
      profileImage: _.get(item, "profileImage", ""),
      latitude: _.get(item, "findLocation.latitude", ""),
      longitude: _.get(item, "findLocation.longitude", ""),
    };
  });

  const onImageError = (e) => {
    e.target.src = userImg;
  };


  return (
    <>
      <LayoutContainer />
      <div className="max-[639px]:pt-20 sm:pt-8 max-[639px]:px-2 px-8 max-[330px]:pt-0">
        <div className="h-[36rem] max-[639px]:h-[28rem] overflow-y-auto">
          <div className="h-full sm:grid grid-flow-row lg:grid-cols-5 md:grid-flow-row gap-5 grid-box">
            <div className="sm:col-span-3 col-span-1 lg:col-span-1 d-none d-lg-block">
              <Navbar expand="md" className="py-0 mb-4">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <ul className="border-2 rounded-3xl pl-0 mb-0 overflow-hidden w-full">
                    <div className="overflow-auto lg:h-full md:h-96 sm:h-96 height">
                      <li className="border-b border-gray-200">
                        <div className="flex flex-row justify-between items-center p-2 px-3 font-bold">
                          <div
                            onClick={() => window.history.back()}
                            className="flex gap-1 cursor-pointer"
                          >
                            <img
                              alt="leftSideArrow"
                              src={leftSideArrow}
                              className="w-2"
                            />
                            <span className="mb-0">{gameName}</span>
                          </div>
                          <div className="flex gap-1 cursor-default">
                            <div className="flex ">
                              <img src={dotIcon} alt="dotIcon" className="" />
                              <span className="align-middle ml-3">Live</span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <div className="flex flex-col px-3">
                        {!_.isEmpty(studentData) ? (
                          _.map(allStudentData, (item) => {
                            return (
                              <div
                                key={item.gameDetailId}
                                className={`flex flex-col py-1 ${
                                  !_.isEmpty(item.latitude || item.longitude)
                                    ? "cursor-pointer"
                                    : "cursor-not-allowed bg-red-50"
                                }`}
                                onClick={() =>
                                  handleStudentLocation(
                                    item.latitude,
                                    item.longitude
                                  )
                                }
                              >
                                <span className="text-[#344054] font-sfpro font-medium text-lg block">
                                  {item.studentName}
                                </span>
                                <span className="text-[#939393] font-sfpro font-medium text-base block">
                                  {item.code}
                                </span>
                              </div>
                            );
                          })
                        ) : (
                          <span className="font-sfpro font-semibold text-lg flex justify-center py-3">
                            No students record
                          </span>
                        )}
                      </div>
                    </div>
                  </ul>
                </Navbar.Collapse>
              </Navbar>
            </div>
            <div className="sm:col-span-4 border rounded-3xl xl:h-auto md:h-auto sm:h-auto h-96 overflow-hidden">
              <div className="sm:hidden">
                <div className="flex flex-row justify-between items-center p-2 px-3 font-bold">
                  <div
                    onClick={() => window.history.back()}
                    className="flex gap-1 cursor-pointer"
                  >
                    <img
                      alt="leftSideArrow"
                      src={leftSideArrow}
                      className="w-2"
                    />
                    <span className="mb-0">{gameName}</span>
                  </div>
                  <div className="flex gap-1 cursor-default">
                    <div className="flex ">
                      <img src={dotIcon} alt="dotIcon" className="" />
                      <span className="align-middle ml-3">Live</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-[36rem] max-[639px]:h-[28rem] overflow-y-auto">
                <MapContainer
                  key={
                    selectedStudentLatitude || selectedStudentLongitude
                      ? selectedStudentLatitude || selectedStudentLongitude
                      : "default"
                  }
                  center={[selectedStudentLatitude, selectedStudentLongitude]}
                  zoom={13}
                  style={{ width: "100%", height: "100%" }}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  {_.map(allStudentData, (item) => {
                    const profileIcon = L.icon({
                      iconUrl: item.profileImage,
                      iconSize: [40, 40],
                      className: "rounded-full border-2 border-[#8656a533]",
                    });
                    return (
                      <Marker
                        key={item.gameDetailId}
                        position={[item.latitude, item.longitude]}
                        icon={profileIcon}
                      >
                        <div className="w-8 h-8 rounded-full border-2 border-[#8656a533]">
                          <img
                            alt="profileImage"
                            src={item.profileImage}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            onError={onImageError}
                          />
                        </div>
                        <Popup>{item.studentName}</Popup>
                      </Marker>
                    );
                  })}
                  <FitBoundsMap markers={allStudentData} />
                </MapContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveLocation;
