import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useUpdateStopMutation } from "../../../api/stop";
import closeIcon from "../../../assets/svg/closeIcon.svg";
import updateActivityImage from "../../../assets/svg/updateActivityImage.svg";
import DeleteModal from "../components/DeleteModal";

const UpdateStops = (props) => {
  const {
    setShowUpdateStopsForm,
    refetchUpdatedStopData,
    refetchDeletedStopData,
    stops,
    tourId,
  } = props;

  const stopId = stops.stopId;
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [updateStop] = useUpdateStopMutation();

  const handleUpdate = async (values) => {
    const data = await updateStop({ values, stopId });
    if (data?.data?.status === "success") {
      toast("Stop updated successfully", { autoClose: 1000 });
      setShowUpdateStopsForm(false);
      refetchUpdatedStopData();
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  const initialValues = {
    name: stops.stopName,
    latitude: stops.latitude,
    longitude: stops.longitude,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    latitude: Yup.string().required("Latitude is required"),
    longitude: Yup.string().required("Longitude is required"),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
      >
        {({ values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="fixed inset-0 bg-black/25 w-auto h-auto z-1 p-8 flex">
              <div className="relative bg-white p-4 sm:p-10 m-auto w-auto max-h-full overflow-y-auto shadow-xl rounded-md">
                <div className="flex justify-end">
                  <img
                    alt="closeIcon"
                    src={closeIcon}
                    className="cursor-pointer p-2 border-2 border-gray-500 border-solid rounded-xl"
                    onClick={() => setShowUpdateStopsForm(false)}
                  />
                </div>
                <div className="max-[639px]:py-4 sm:py-20 sm:px-8 gap-4 flex flex-row items-center justify-center">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-none d-lg-block">
                    <img alt="updateActivityImage" src={updateActivityImage} />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="flex flex-col sm:gap-5 max-[639px]:gap-4">
                      <span className="flex justify-center text-black font-sfpro font-semibold sm:text-3xl text-2xl">
                        Update stop
                      </span>
                      <div className="flex flex-col sm:gap-8 max-[639px]:gap-3">
                        <div className="h-20 w-full">
                          <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                            New stop name
                            <Field
                              type="text"
                              name="name"
                              value={values.name}
                              className="mt-2 font-sfpro font-medium text-lg sm:text-xl text-black leading-6 w-full max-[639px]:py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                            />
                            <div className="text-red-600 text-sm">
                              <ErrorMessage name="name" />
                            </div>
                          </span>
                        </div>
                        <div className="h-20">
                          <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                            Latitude
                            <Field
                              type="text"
                              name="latitude"
                              value={values.latitude}
                              className="mt-2 font-sfpro font-medium text-lg sm:text-xl text-black leading-6 w-full max-[639px]:py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                            />
                            <div className="text-red-600 text-sm">
                              <ErrorMessage name="latitude" />
                            </div>
                          </span>
                        </div>
                        <div className="h-20">
                          <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                            Longitude
                            <Field
                              type="text"
                              name="longitude"
                              value={values.longitude}
                              className="mt-2 font-sfpro font-medium text-lg sm:text-xl text-black leading-6 w-full max-[639px]:py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                            />
                            <div className="text-red-600 text-sm">
                              <ErrorMessage name="longitude" />
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="w-full flex flex-col sm:flex-row justify-center gap-3 items-center">
                        <button
                          type="submit"
                          onSubmit={handleUpdate}
                          className="py-2 px-20 font-sfpro font-medium text-base leading-6 text-gray-700 border-1 border-solid rounded-xl"
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          className="py-2 px-20 font-sfpro font-medium text-base leading-5 bg-[#8656A5] text-white border-3 border-gray-300 rounded-xl"
                          onClick={() => setShowDeleteModal(true)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {showDeleteModal && (
        <DeleteModal
          setShowUpdateStopsForm={setShowUpdateStopsForm}
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          stopId={stopId}
          tourId={tourId}
          refetchDeletedStopData={refetchDeletedStopData}
        />
      )}
    </>
  );
};

export default UpdateStops;
