import React from "react";
import { Grid } from "react-loader-spinner";

const Loader = () => {
  return (
    <div className="flex justify-center m-52 pl-16">
      <Grid color="#8152a033" radius="12.5" height="60%" width="60%" />
    </div>
  );
};

export default Loader;
