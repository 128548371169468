import "./App.css";
import { Fragment, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import RouterWrapper from "./Routes";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { useGetProfileQuery } from "./api/profile";

function App() {
  const navigate = useNavigate();
  const token = localStorage.getItem("authorization");
  const { data: profile, refetch } = useGetProfileQuery();

  useEffect(() => {
    if (!token) {
      navigate("/");
      localStorage.removeItem("authorization");
      localStorage.removeItem("token");
      localStorage.removeItem("userRole");
      localStorage.removeItem("email");
      localStorage.removeItem("teacherId");
      localStorage.removeItem("lastName");
      localStorage.removeItem("profileImage");
      localStorage.removeItem("profileId");
      localStorage.removeItem("groupId");
      localStorage.removeItem("tourId");
      localStorage.removeItem("gameId");
    }
  }, [refetch, token]);

  return (
    <Fragment>
      <RouterWrapper />
      <ToastContainer />
    </Fragment>
  );
}

export default App;
