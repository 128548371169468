import React, { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import * as _ from "lodash";
import * as Yup from "yup";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { useCreateGameMutation } from "../../../api/game";
import { useGetTeacherQuery } from "../../../api/teacher";
import { useGetTourQuery } from "../../../api/tour";
import closeIcon from "../../../assets/svg/closeIcon.svg";
import calendarIcon from "../../../assets/svg/calendarIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import userImg from "../../../assets/svg/userImg.jpeg";

const CreateGame = (props) => {
  const { setShowCreateGameModal, refetchCreatedGameData } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const role = localStorage.getItem("userRole");
  const admin = role === "admin";
  const teacher = role === "teacher";
  const tourId = localStorage.getItem("tourId");
  const tId = localStorage.getItem("teacherId");
  const game = location.pathname === "/tours";
  const tourGame = /^\/StopsFromLocation\/tourId=/.test(location.pathname);

  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectedTour, setSelectedTour] = useState([]);
  const [Error, setError] = useState(false);
  const datePickerRef = useRef(null);

  const [createGame] = useCreateGameMutation();
  const { data: teacherData } = useGetTeacherQuery();
  const { data: tourData } = useGetTourQuery();
  const teachers = role === "admin" ? teacherData : "";

  // *** Date formate for selected date *** //
  const handleDate = (date) => {
    setStartDate(date);
    const selectedDateFormate = `${date?.getDate()} ${date?.toLocaleString(
      "default",
      {
        month: "short",
      }
    )} ${date?.getFullYear()}`;
    const selectedTimeFormate = date?.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: false,
    });
    const selectedDateTimeFormatted = `${selectedDateFormate} ${selectedTimeFormate}`;
    setSelectedDate(new Date(selectedDateTimeFormatted));
  };

  const dateError = selectedDate <= new Date();

  const teacherId = selectedTeachers.value;

  const list = _.map(teachers?.data, (item) => {
    return {
      name: item.profile.firstName + " " + item.profile.lastName,
      profileImage: item.profile.profileImage,
      id: item.teacherId,
    };
  });

  const onImageError = (e) => {
    e.target.src = userImg;
  };

  const teacherOptions = _.map(list, (item) => ({
    value: item.id,
    label: (
      <>
        <div className="flex flex-row w-24 h-10 whitespace-nowrap gap-3 p-1">
          <img
            src={item.profileImage}
            alt="profileImage"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "5px",
            }}
            onError={onImageError}
            />
          <span className="font-sfpro mt-1 font-semibold text-base text-neutral-800">
            {item.name}
          </span>
        </div>
      </>
    ),
  }));

  const teacherError = _.isEmpty(selectedTeachers);

  const handleTeacherSelectChange = (selectedOptions) => {
    setSelectedTeachers(selectedOptions);
  };

  const trId = selectedTour.value;

  const tourList = _.map(tourData?.data, (item) => {
    return {
      name: item.name,
      id: item.tourId,
    };
  });

  const tourOptions = _.map(tourList, (item) => ({
    value: item.id,
    label: (
      <span className="font-sfpro h-20 font-semibold text-base text-neutral-800">
        {item.name}
      </span>
    ),
  }));

  const tourError = _.isEmpty(selectedTour);

  const handleTourSelectChange = (selectedOptions) => {
    setSelectedTour(selectedOptions);
  };

  const handleSave = async (values) => {
    setError(
      game && admin
        ? teacherError || dateError || tourError
        : game && teacher
        ? dateError || tourError
        : tourGame && admin
        ? teacherError || dateError
        : tourGame && teacher
        ? dateError
        : ""
    );
    if (
      game && admin
        ? !(teacherError || dateError || tourError)
        : game && teacher
        ? !(dateError || tourError)
        : tourGame && admin
        ? !(teacherError || dateError)
        : tourGame && teacher
        ? !dateError
        : ""
    ) {
      const data = await createGame(values);
      if (data?.data?.status === "success") {
        toast("Tour added successfully", { autoClose: 1000 });
        setShowCreateGameModal(false);
        const gameName = data?.data?.data?.gameName;
        const gameId = data?.data?.data?.gameId;
        localStorage.setItem("gameId", gameId);
        const url = `/groupsFromCreateGame/gameId=${gameId}`;
        navigate(url, { state: gameName });
        refetchCreatedGameData();
      } else {
        toast.error(data?.error?.data?.message, { autoClose: 1000 });
      }
    }
  };

  const initialValues = {
    name: "",
    date: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Tour name is required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const data = {
          ...values,
          teacherId: role === "admin" ? teacherId : role === "teacher" && tId,
          tourId: game ? trId : tourId,
          date: selectedDate,
        };
        handleSave(data);
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className="fixed inset-0 bg-black/25 w-auto h-auto z-1 p-8 flex">
            <div className="relative bg-white p-4 sm:p-10 m-auto w-auto max-h-full overflow-y-auto shadow-xl rounded-md">
              <div className="flex justify-end">
                <img
                  alt="closeIcon"
                  src={closeIcon}
                  className="cursor-pointer p-2 border-2 border-gray-500 border-solid rounded-xl"
                  onClick={() => setShowCreateGameModal(false)}
                />
              </div>
              <div className="flex flex-col max-[639px]:gap-5 sm:gap-7">
                <div>
                  <span className="text-black font-sfpro font-semibold text-2xl sm:text-3xl">
                    Add tour
                  </span>
                </div>
                <div className="h-20 w-full">
                  <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                    Tour Name
                    <Field
                      type="text"
                      name="name"
                      className="mt-2 font-sfpro font-medium text-lg sm:text-xl text-black leading-6 w-full py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                    />
                    <div className="text-red-600 text-sm">
                      <ErrorMessage name="name" />
                    </div>
                  </span>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                    Date
                  </span>
                  <div className="h-12">
                    <div className="border-1 border-solid border-[#939393] rounded-3xl p-2">
                      <div onClick={() => datePickerRef.current.setOpen(true)}>
                        <DatePicker
                          ref={datePickerRef}
                          name="date"
                          className="focus:outline-none relative cursor-pointer"
                          selected={startDate}
                          onChange={handleDate}
                          timeInputLabel="Time:"
                          dateFormat="dd MMM yyyy hh:mm aa"
                          showTimeInput
                        />
                        <img
                          className={`max-[320px]:hidden absolute right-8 ${
                            game && role === "admin"
                              ? "max-[639px]:bottom-[19.3rem] sm:bottom-[20.7rem]"
                              : tourGame && role === "admin"
                              ? "max-[639px]:bottom-[13.1rem] sm:bottom-[14.1rem]"
                              : game && role === "teacher"
                              ? "max-[639px]:bottom-[13.1rem] sm:bottom-[14.1rem]"
                              : tourGame &&
                                role === "teacher" &&
                                "max-[639px]:bottom-[6.8rem] sm:bottom-[7.3rem]"
                          }`}
                          alt="calenderIcon"
                          src={calendarIcon}
                        />
                      </div>
                    </div>
                    {Error && dateError && (
                      <div className="text-red-600 font-semibold text-sm">
                        <span>Selected date should be a future date</span>
                      </div>
                    )}
                  </div>
                </div>
                {role === "admin" && (
                  <div className="h-20 w-full">
                    <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                      Teacher list
                      <Select
                        name="teacher"
                        field="teacher"
                        setFieldValue={setFieldValue}
                        options={teacherOptions}
                        value={selectedTeachers}
                        onChange={handleTeacherSelectChange}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={true}
                      />
                      {Error && teacherError && (
                        <div className="text-red-600 font-semibold text-sm">
                          <span>Please select a teacher</span>
                        </div>
                      )}
                    </span>
                  </div>
                )}
                <div className="h-20 w-full">
                  <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                    Location list
                    <Select
                      name="tour"
                      field="tour"
                      setFieldValue={setFieldValue}
                      options={tourOptions}
                      value={selectedTour}
                      onChange={handleTourSelectChange}
                      hideSelectedOptions={false}
                      closeMenuOnSelect={true}
                    />
                    {Error && tourError && (
                      <div className="text-red-600 font-semibold text-sm">
                        <span>Please select an tour</span>
                      </div>
                    )}
                  </span>
                </div>
                <div className="w-full flex justify-center">
                  <button
                    type="submit"
                    onSubmit={handleSave}
                    className="flex flex-row items-center gap-2 bg-[#8656A5] py-2 px-12 border-3 border-[#AFB7D6] rounded-full text-white font-sfpro text-lg leading-7"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateGame;
