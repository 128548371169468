import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";

export const csvApi = createApi({
  reducerPath: "csvApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/csv`,
    prepareHeaders,
  }),
  tagTypes: ["CSV"],
  endpoints: (builder) => ({
    teacherCSV: builder.mutation({
      query: (body) => {
        return {
          url: "/teacherCSV",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CSV"],
    }),
    studentCSV: builder.mutation({
      query: (body) => {
        return {
          url: "/studentCSV",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CSV"],
    }),
  }),
});

export const { useTeacherCSVMutation, useStudentCSVMutation } = csvApi;
