import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { MaterialReactTable } from "material-react-table";
import LayoutContainer from "../../layout/LayoutContainer";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import leftSideArrow from "../../../assets/svg/leftSideArrow.svg";
import userImg from "../../../assets/svg/userImg.jpeg";
import pdfIcon from "../../../assets/png/pdfIcon.png";
import { useFindByGameIdQuery } from "../../../api/game";

const StudentHistory = () => {
  const location = useLocation();
  const gameId = location.state;
  const tId = localStorage.getItem("teacherId");

  const [studentHistoryData, setStudentHistoryData] = useState([]);
  const { data: studentHistory, refetch } = useFindByGameIdQuery({
    gameId,
    tId,
  });

  useEffect(() => {
    refetch();
    if (studentHistory?.data2?.status === "success") {
      return;
    } else {
      toast.error(studentHistory?.error?.data?.message, { autoClose: 1000 });
    }
    if (studentHistory) {
      setStudentHistoryData(studentHistory.data2);
    } else {
      setStudentHistoryData([]);
    }
  }, [studentHistory, refetch]);

  const rows =
    studentHistoryData &&
    _.map(studentHistoryData, (item) => {
      return {
        id: _.get(item, "studentId", ""),
        profileImage: _.get(item, "profileImage", ""),
        studentName:
          _.get(item, "firstName", "") + " " + _.get(item, "lastName", ""),
        email: _.get(item, "email", ""),
        class: _.get(item, "class", ""),
        age: _.get(item, "age", ""),
        pdf: _.map(item.pdf, (data) => data.pdfPath),
      };
    });

  const downloadPdf = (item) => {
    const zip = document.createElement("a");
    zip.href = item;
    window.open(zip.href);
  };

  const onImageError = (e) => {
    e.target.src = userImg;
  };

  const columns = [
    {
      accessorKey: "profileImage",
      header: "Profile",
      size: 80,
      enableColumnActions: false,
      muiTableHeadCellProps: { align: "center" },
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Profile
        </span>
      ),
      Cell: (params) => {
        return (
          <div className="flex justify-center items-center w-full">
            <div className="w-12 h-12 rounded-full p-1">
              <img
                className="rounded-full"
                alt="profileImage"
                src={params.renderedCellValue || userImg}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
            onError={onImageError}
            />
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "studentName",
      header: "Student Name",
      enableColumnActions: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Student Name
        </span>
      ),
      Cell: (params) => {
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {params.row.original.studentName}
          </span>
        );
      },
    },
    {
      accessorKey: "email",
      header: "Email",
      enableColumnActions: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Email
        </span>
      ),
      Cell: (params) => {
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {params.row.original.email}
          </span>
        );
      },
    },
    {
      accessorKey: "class",
      header: "Class",
      enableColumnActions: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Class
        </span>
      ),
      Cell: (params) => {
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {params.row.original.class}
          </span>
        );
      },
    },
    {
      accessorKey: "age",
      header: "Age",
      enableColumnActions: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Age
        </span>
      ),
      Cell: (params) => {
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {params.row.original.age}
          </span>
        );
      },
    },
    {
      accessorKey: "pdf",
      header: "File",
      size: 50,
      enableColumnActions: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          File
        </span>
      ),
      Cell: (params) => {
        const item = params.renderedCellValue;
        return (
          <div>
            {!_.isEmpty(params.renderedCellValue) && (
              <div
                onClick={() => downloadPdf(item)}
                className="cursor-pointer justify-start flex flex-row gap-1 w-full"
              >
                <img alt="pdfIcon" src={pdfIcon} />
                <span className="text-[#8656A5] font-sfpro font-bold text-xl">
                  PDF
                </span>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <LayoutContainer />
      <div className="max-[639px]:px-2 sm:px-8 pb-2 sm:pb-8 max-[330px]:pt-0 grid_border">
        <div className="flex flex-col sm:gap-3">
          <div
            onClick={() => window.history.back()}
            className="flex flex-row gap-3 cursor-pointer"
          >
            <img alt="leftSideArrow" src={leftSideArrow} />
            <span className="font-sfpro text-2xl font-bold text-[#8656A5]">
              History
            </span>
          </div>
          <div className="h-[32rem] max-[320px]:h-[23rem] max-[375px]:h-[28rem] max-[390px]:h-[28rem] min-[391px]:h-[35rem] min-[400px]:h-[40rem] sm:h-[28rem] md:h-[36rem] lg:h-[36rem] xl:h-[44rem] border-b-2 overflow-y-auto overflow-x-hidden">
            <MaterialReactTable
              columns={columns}
              data={rows}
              enableTopToolbar={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentHistory;
