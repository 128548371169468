import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import * as _ from "lodash";
import LayoutContainer from "../../layout/LayoutContainer";
import laptop from "../../../assets/svg/laptop.svg";
import plusIcon from "../../../assets/svg/plusIcon.svg";
import { toast } from "react-toastify";
import editIcon from "../../../assets/svg/editIcon.svg";
import deleteIcon from "../../../assets/svg/deleteIcon.svg";
import Modal from "../../pages/components/Modal";
import CreateTeacher from "./CreateTeacher";
import "../../../assets/css/pages.css";
import { useGetTeacherQuery } from "../../../api/teacher";
import UpdateTeacher from "./UpdateTeacher";
import userImg from "../../../assets/svg/userImg.jpeg";
import Csv from "../components/Csv";
import DeleteModal from "../components/DeleteModal";

import Loader from "../components/Loader";
import searchIcon1 from "../../../assets/svg/searchIcon1.svg";

const TeacherTab = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const teacherSearchText = searchParams.get("search");
  const role = localStorage.getItem("userRole");
  const teacher = location.pathname === "/teacher";

  const [showCreateTeacherForm, setShowCreateTeacherForm] = useState(false);
  const [showUpdateTeacherForm, setShowUpdateTeacherForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCsvModal, setShowCsvModal] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [teacherId, setTeacherId] = useState("");
  const [teacherData, setTeacherData] = useState([]);

  const { data, refetch, isFetching } = useGetTeacherQuery();

  useEffect(() => {
    if (role === "admin") {
      refetch();
      if (data?.data?.status === "success") {
        return;
      } else {
        toast.error(data?.error?.data?.message, { autoClose: 1000 });
      }
      if (data) {
        const filterdTeacherData = teacherSearchText
          ? _.filter(teacherData, (teacher) => {
              const fullName =
                `${teacher.profile.firstName} ${teacher.profile.lastName}`.toLowerCase();
              const searchText = teacherSearchText.toLowerCase();
              const firstNameMatch = teacher.profile.firstName
                .toLowerCase()
                .includes(searchText);
              const lastNameMatch = teacher.profile.lastName
                .toLowerCase()
                .includes(searchText);
              const fullNameMatch = fullName.includes(searchText);
              return firstNameMatch || lastNameMatch || fullNameMatch;
            })
          : data.data;
        if (filterdTeacherData <= 0) {
          setIsTrue(true);
        } else {
          setTeacherData(filterdTeacherData);
          setIsTrue(false);
        }
      } else {
        setTeacherData([]);
      }
    }
  }, [data, role, teacherSearchText, teacherData, teacher, refetch]);

  const onImageError = (e) => {
    e.target.src = userImg;
  };

  const rows =
    teacherData &&
    _.map(teacherData, (item) => {
      const firstName = _.get(item, "profile.firstName", "");
      const lastName = _.get(item, "profile.lastName", "");
      return {
        id: _.get(item, "teacherId", ""),
        firstName: _.get(item, "profile.firstName", ""),
        lastName: _.get(item, "profile.lastName", ""),
        profileImage: _.get(item, "profile.profileImage", ""),
        teacherName:
          firstName.charAt(0).toUpperCase() +
          firstName.slice(1) +
          " " +
          lastName.charAt(0).toUpperCase() +
          lastName.slice(1),
        email: _.get(item, "user.email", ""),
        password: _.get(item, "user.password", ""),
      };
    });

  const columns = [
    {
      accessorKey: "profileImage",
      header: "Profile",
      size: 80,
      enableColumnActions: false,
      muiTableHeadCellProps: { align: "center" },
      enableSorting: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Profile
        </span>
      ),
      Cell: (params) => {
        return (
          <div className="flex justify-center items-center w-full">
            <div className="w-12 h-12 rounded-full p-1">
              <img
                className="rounded-full"
                alt="profileImage"
                src={params.renderedCellValue || userImg}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                onError={onImageError}
              />
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "teacherName",
      header: "Teacher Name",
      enableColumnActions: false,
      enableSorting: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Teacher Name
        </span>
      ),
      Cell: (params) => {
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {params.row.original.teacherName}
          </span>
        );
      },
    },
    {
      accessorKey: "email",
      header: "Email",
      enableColumnActions: false,
      enableSorting: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Email
        </span>
      ),
      Cell: (params) => {
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {params.row.original.email}
          </span>
        );
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      maxSize: 50,
      muiTableHeadCellProps: { align: "center" },
      enableColumnActions: false,
      enableSorting: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Action
        </span>
      ),
      Cell: (params) => {
        return (
          <div className="justify-center items-center flex flex-row gap-2 w-full">
            <img
              alt="editIcon"
              src={editIcon}
              className="cursor-pointer"
              onClick={() => {
                setShowUpdateTeacherForm(true);
                setTeacherId(params.row.original);
              }}
            />
            <img
              alt="deleteIcon"
              src={deleteIcon}
              className="cursor-pointer"
              onClick={() => {
                setShowDeleteModal(true);
                setTeacherId(params.row.original.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <LayoutContainer />
      <div className="max-[639px]:px-2 sm:px-8 pb-2 sm:pb-8 max-[330px]:pt-0 grid_border">
        {isTrue && !_.isEmpty(teacherData) ? (
          <div className="mt-20 flex flex-col justify-center items-center max-[639px]:gap-6 sm:gap-8">
            <span>
              <img alt="searchIcon1" src={searchIcon1} />
            </span>
            <span className="font-sfpro text-center text-2xl sm:text-4xl font-medium leading-6">
              Search not found
            </span>
            <span className="font-sfpro text-center text-xl sm:text-3xl font-normal leading-5 text-gray-500">
              Your search “String” did not match any teacher. Please try again.
            </span>
          </div>
        ) : !_.isEmpty(teacherData) ? (
          <div className="flex flex-col gap-2 sm:gap-8">
            <div className="h-[32rem] max-[320px]:h-[22rem] max-[375px]:h-[28rem] max-[390px]:h-[28rem] min-[391px]:h-[35rem] min-[400px]:h-[40rem] sm:h-[28rem] md:h-[32rem] lg:h-[32rem] xl:h-[35rem] border-b-2 overflow-y-auto overflow-x-hidden">
              <MaterialReactTable
                columns={columns}
                data={rows}
                enableTopToolbar={false}
                enableBottomToolbar={false}
              />
            </div>
            <div className="flex sm:flex-col md:flex-row gap-3 w-full sm:justify-end items-end">
              <button
                className="flex justify-center lg:w-1/5 md:w-1/4 sm:w-1/2 w-1/2 gap-2 items-center uppercase font-sfpro font-medium text-sm sm:text-base leading-5 bg-[#8656A5] text-white py-2 border-3 border-gray-300 rounded-xl"
                onClick={() => setShowCreateTeacherForm(true)}
              >
                <img alt="plusIcon" src={plusIcon} />
                Add teacher
              </button>
              <button
                className="flex justify-center lg:w-1/5 md:w-1/4 sm:w-1/2 w-1/2 gap-2 items-center uppercase font-sfpro font-medium text-sm sm:text-base leading-5 bg-[#8656A5] text-white py-2 border-3 border-gray-300 rounded-xl"
                onClick={() => setShowCsvModal(true)}
              >
                <img alt="plusIcon" src={plusIcon} />
                Import
              </button>
            </div>
          </div>
        ) : !_.isEmpty(teacherData) || isFetching ? (
          <Loader />
        ) : (
          _.isEmpty(teacherData) && (
            <div className="mt-12 flex flex-col justify-center items-center md:gap-5 sm:gap-4 min-[260px]:gap-3">
              <div className="flex justify-center">
                <img alt="laptop" src={laptop} className="responsive_img" />
              </div>
              <div className="flex flex-col justify-center items-center md:gap-4 sm:gap-2">
                <span className="font-sfpro font-medium md:text-4xl sm:text-3xl text-gray-900 leading-6">
                  Let’s create teacher
                </span>
                <span className="font-sfpro font-normal md:text-3xl sm:text-2xl text-gray-500 leading-5">
                  Your teacher data not found.
                </span>
              </div>
              <div className="flex flex-col  justify-center items-center md:gap-4 sm:gap-2">
                <button
                  className="flex justify-center gap-2 items-center uppercase font-sfpro font-medium md:text-sm sm:text-xs min-[300px]:text-xs leading-5 bg-[#8656A5] text-white w-full py-2 px-4 border-3 border-gray-300 rounded-lg"
                  onClick={() => setShowCreateTeacherForm(true)}
                >
                  <img alt="plusIcon" src={plusIcon} />
                  Add teacher
                </button>
                <span className="uppercase">or</span>
                <button
                  className="flex justify-center gap-2 items-center uppercase font-sfpro font-medium md:text-sm sm:text-xs min-[300px]:text-xs leading-5 bg-[#8656A5] text-white w-full py-2 px-4 border-3 border-gray-300 rounded-lg"
                  onClick={() => setShowCsvModal(true)}
                >
                  <img alt="plusIcon" src={plusIcon} />
                  Import
                </button>
              </div>
            </div>
          )
        )}
      </div>
      {showCreateTeacherForm && (
        <Modal>
          {showCreateTeacherForm && (
            <CreateTeacher
              setShowCreateTeacherForm={setShowCreateTeacherForm}
              refetchCreatedTeacherData={refetch}
            />
          )}
        </Modal>
      )}
      {showUpdateTeacherForm && (
        <Modal>
          {showUpdateTeacherForm && (
            <UpdateTeacher
              setShowUpdateTeacherForm={setShowUpdateTeacherForm}
              teacherId={teacherId}
              refetchUpdatedTeacherData={refetch}
            />
          )}
        </Modal>
      )}
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          refetchDeletedTeacherData={refetch}
          teacherId={teacherId}
        />
      )}
      {showCsvModal && (
        <Csv setShowCsvModal={setShowCsvModal} refetchTeacherCsv={refetch} />
      )}
    </>
  );
};

export default TeacherTab;
