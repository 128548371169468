import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import LayoutContainer from "../../layout/LayoutContainer";
import { useLastOneMonthHistoryOfCompletedGamesQuery } from "../../../api/game";
import pdfIcon from "../../../assets/png/pdfIcon.png";
import { useNavigate } from "react-router-dom";

import Loader from "../components/Loader";
import { TbMoodEmpty } from "react-icons/tb";

const Recent = () => {
  const navigate = useNavigate();
  const tId = localStorage.getItem("teacherId");
  const [recentData, setRecentData] = useState([]);

  const {
    data: recent,
    refetch,
    isFetching,
  } = useLastOneMonthHistoryOfCompletedGamesQuery(tId);

  useEffect(() => {
    if (recent?.data?.status === "success") return;
    if (recent) {
      setRecentData(recent.data);
    } else {
      setRecentData([]);
    }
  }, [recent, refetch]);

  const allRecentData = _.map(recentData, (item) => {
    const itemDate = item.date;
    const dateObject = new Date(parseInt(itemDate));
    const dateFormate = `${dateObject?.getDate()} ${dateObject?.toLocaleString(
      "numeric",
      {
        month: "long",
      }
    )} ${dateObject?.getFullYear()}`;
    const timeFormate = dateObject?.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return {
      gameId: _.get(item, "gameId", ""),
      name: _.get(item, "name", ""),
      date: dateFormate,
      time: timeFormate,
      zip: _.get(item, "zip", ""),
    };
  });

  const downloadPdf = (item) => {
    const zip = document.createElement("a");
    zip.href = item.zip;
    window.location.replace(zip.href);
  };

  return (
    <>
      <LayoutContainer />
      <div className="max-[639px]:px-2 px-8 max-[330px]:pt-0">
        <div className="flex flex-row gap-5">
          <span className="font-sfpro text-xl sm:text-2xl cursor-default px-2 border-b-2 font-bold border-[#8656A5] text-[#8656A5]">
            Recent
          </span>
          <span
            className="font-sfpro text-xl sm:text-2xl cursor-pointer"
            onClick={() => navigate("/allToursHistory")}
          >
            All locations
          </span>
        </div>
        <div className="h-[32rem] max-[320px]:h-[23rem] max-[390px]:h-[30rem] max-[395px]:h-[36rem] min-[400px]:h-[42rem] sm:h-[26rem] md:h-[34rem] lg:h-[34rem] xl:h-[40rem] overflow-y-auto border-b-2">
          <div className="py-4 flex flex-row flex-wrap gap-4">
            {!_.isEmpty(recentData) ? (
              _.map(allRecentData, (item) => {
                return (
                  <div
                    key={item.gameId}
                    className="cursor-default flex flex-row justify-between max-[639px]:p-4 sm:p-6 border-2 border-solid rounded-3xl shadow-sm 2xl:w-[37rem] lg:w-[30rem] md:w-[25rem] w-[18rem]"
                  >
                    <div className="flex flex-col gap-2">
                      <span className="text-black font-sfpro font-medium text-xl sm:text-2xl">
                        {item.name}
                      </span>
                      <span className="text-[#939393] font-sfpro font-medium text-base sm:text-lg">
                        {item.date}
                      </span>
                      <span className="text-[#939393] font-sfpro font-medium text-base sm:text-lg">
                        {item.time}
                      </span>
                    </div>
                    {item.zip !== "" && (
                      <div
                        onClick={() => {
                          downloadPdf(item);
                        }}
                        className="flex flex-row gap-1 justify-center items-center cursor-pointer"
                      >
                        <img alt="pdfIcon" src={pdfIcon} />
                        <span className="text-[#8656A5] font-sfpro font-bold text-xl">
                          PDF
                        </span>
                      </div>
                    )}
                  </div>
                );
              })
            ) : !_.isEmpty(recentData) || isFetching ? (
              <Loader />
            ) : (
              _.isEmpty(recentData) && (
                <div className="mx-auto mt-20 py-4 flex flex-col justify-center gap-3">
                  <span className="flex justify-center">
                    <TbMoodEmpty size={100} />
                  </span>
                  <span className="flex justify-center text-gray-800 font-sfpro text-lg leading-7">
                    No recent data..!
                  </span>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Recent;
