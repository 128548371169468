import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import activityImage from "../../../assets/svg/activityImage.svg";
import editIcon1 from "../../../assets/svg/editIcon1.svg";
import plusIcon from "../../../assets/svg/plusIcon.svg";
import { toast } from "react-toastify";
import LayoutContainer from "../../layout/LayoutContainer";
import { useGetTourQuery } from "../../../api/tour";
import "../../../assets/css/pages.css";
import { useNavigate } from "react-router-dom";

import Loader from "../components/Loader";
import CreateTour from "./CreateTour";
import UpdateTour from "./UpdateTour";

const TourTab = () => {
  const navigate = useNavigate();
  const tourId = localStorage.getItem("tourId");
  const role = localStorage.getItem("userRole");

  const [tourData, setTourData] = useState([]);
  const [tours, setTours] = useState([]);
  const [showCreateTourForm, setShowCreateTourForm] = useState(false);
  const [showUpdateTourForm, setShowUpdateTourForm] = useState(false);
  const [showStopsFromTour, setShowStopsFromTour] = useState(false);

  const { data: tour, refetch, isFetching } = useGetTourQuery();

  useEffect(() => {
    if (showStopsFromTour) {
      navigate(`/StopsFromLocation/tourId=${tourId}`, {
        state: tours,
      });
    }
  }, [showStopsFromTour, navigate, tourId, tours]);

  useEffect(() => {
    if (tour?.data?.status === "success") {
      return;
    } else {
      toast.error(tour?.error?.data?.message, { autoClose: 1000 });
    }
    if (tour) {
      setTourData(tour.data);
    } else {
      setTourData([]);
    }
  }, [tour, refetch]);

  const allTourData = _.map(tourData, (item) => {
    return {
      tourId: _.get(item, "tourId", ""),
      name: _.get(item, "name", ""),
      latitude: _.get(item, "latitude", ""),
      longitude: _.get(item, "longitude", ""),
    };
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const totalPages = Math.ceil(allTourData.length / itemsPerPage);

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <LayoutContainer />
      {!_.isEmpty(tourData) ? (
        <div className="max-[639px]:px-2 px-8 max-[330px]:pt-0">
          <div>
            <span className="text-[#8656A5] font-sfpro font-bold text-lg sm:text-2xl border-b-2 pb-1 border-[#8656A5]">
              New locations
            </span>
          </div>

          <div className="h-[32rem] max-[320px]:h-[20rem] max-[390px]:h-[26rem] max-[395px]:h-[33rem] min-[400px]:h-[39rem] sm:h-[26rem] md:h-[32rem] lg:h-[32rem] xl:h-[33rem] overflow-y-auto border-b-2">
            <div className="py-4 flex flex-row flex-wrap gap-4">
              {allTourData
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((item) => {
                  return (
                    <div
                      key={item.tourId}
                      className="flex flex-col gap-2 p-6 border-2 border-solid rounded-3xl shadow-sm sm:w-[25rem] max-[639px]:w-[15rem]"
                    >
                      <div className="flex flex-row gap-2 justify-between">
                        <span
                          onClick={() => {
                            setTours(item);
                            localStorage.setItem("tourId", item.tourId);
                            setShowStopsFromTour(true);
                          }}
                          className="cursor-pointer text-black font-sfpro font-medium text-lg sm:text-2xl"
                        >
                          {item.name}
                        </span>
                        {role === "admin" && (
                          <img
                            alt="editIcon1"
                            src={editIcon1}
                            className="cursor-pointer"
                            onClick={() => {
                              setShowUpdateTourForm(true);
                              setTours(item);
                            }}
                          />
                        )}
                      </div>
                      <div className="flex justify-start">
                        <span className="text-[#939393] font-sfpro font-medium text-md sm:text-lg">
                          {item.latitude + ", " + item.longitude}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="flex flex-col sm:flex-row btnGrp_flex justify-between">
            {role === "admin" && (
              <div className="flex justify-end mt-3">
                <button
                  className="flex flex-row items-center gap-2 bg-[#8656A5] py-2 px-12 border-3 border-[#AFB7D6] rounded-2xl text-white font-sfpro text-lg leading-7"
                  type="button"
                  onClick={() => setShowCreateTourForm(true)}
                >
                  <img alt="plusIcon" src={plusIcon} />
                  New location
                </button>
              </div>
            )}
            <div className="flex justify-end mt-3 gap-3">
              {currentPage > 1 && (
                <button
                  type="button"
                  onClick={goToPreviousPage}
                  className="flex flex-row items-center gap-2 py-2 px-3.5 border-1 border-solid rounded-xl text-gray-700 font-sfpro text-lg leading-7"
                >
                  Previous
                </button>
              )}
              {currentPage < totalPages && (
                <button
                  type="button"
                  onClick={goToNextPage}
                  className="flex flex-row items-center gap-2 py-2 px-3.5 border-1 border-solid rounded-xl text-gray-700 font-sfpro text-lg leading-7"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      ) : !_.isEmpty(tourData) || isFetching ? (
        <Loader />
      ) : (
        <div className="w-full p-10 flex flex-row items-center justify-center">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <div className="flex flex-col gap-8">
              <div>
                <span className="text-[#0F172A] font-sfpro font-bold min-[200px]:text-4xl sm:text-5xl xl:text-7xl leading-[86px]">
                  {role === "admin" ? "Let’s" : "No"}
                </span>{" "}
                <br />
                <span className="text-[#0F172A] font-sfpro font-bold min-[200px]:text-4xl sm:text-5xl xl:text-7xl leading-[86px]">
                  {role === "admin" ? "Create" : "Locations"}{" "}
                  <span className="text-[#8656A5] font-sfpro font-bold min-[200px]:text-4xl sm:text-5xl xl:text-7xl leading-[86px]">
                    {role === "admin" ? "a locations" : "Data"}
                  </span>
                </span>
              </div>
              <span className="text-[#4B5262] font-sfpro text-2xl leading-8">
                "Step out and learn"
              </span>
              {role === "admin" && (
                <button
                  className="bg-[#8656A5] py-2 min-[450px]:w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/3 border-3 border-[#AFB7D6] rounded-lg text-white font-sfpro text-lg leading-7"
                  type="button"
                  onClick={() => setShowCreateTourForm(true)}
                >
                  Start to create location
                </button>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-none d-lg-block">
            <div className="">
              <img alt="activityImage" src={activityImage} />
            </div>
          </div>
        </div>
      )}

      {showCreateTourForm && (
        <CreateTour
          setShowCreateTourForm={setShowCreateTourForm}
          refetchCreatedTourData={refetch}
        />
      )}
      {showUpdateTourForm && (
        <UpdateTour
          setShowUpdateTourForm={setShowUpdateTourForm}
          refetchUpdatedTourData={refetch}
          tours={tours}
        />
      )}
    </>
  );
};

export default TourTab;
