import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseQueryWithAuthInterceptor = (baseQueryArgs) => {
  const baseQuery = fetchBaseQuery(baseQueryArgs);
  return async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result?.error?.data?.status === 401)
      localStorage.removeItem("authorization");
    return result;
  };
};

export const prepareHeaders = (headers) => {
  const token = localStorage.getItem("authorization");
  if (token) headers.set("authorization", `Bearer ${token}`);
  return headers;
};
