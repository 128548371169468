import React, { useCallback } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import searchIcon from "../../assets/svg/searchIcon.svg";
import { InputAdornment, TextField } from "@mui/material";
import * as _ from "lodash";
import "../../assets/css/layout.css";
import { Nav, Navbar } from "react-bootstrap";

const NavbarMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const role = localStorage.getItem("userRole");
  const tourId = localStorage.getItem("tourId");
  const gameId = localStorage.getItem("gameId");
  const groupId = localStorage.getItem("groupId");

  const showSearchBox =
    location.pathname === "/locations" ||
    location.pathname === `/StopsFromLocation/tourId=${tourId}` ||
    location.pathname === "/recentHistory" ||
    location.pathname === "/allToursHistory" ||
    location.pathname === `/completedTours/tourId=${tourId}` ||
    location.pathname === "/tours" ||
    location.pathname === "/groups" ||
    location.pathname === "/liveTour" ||
    location.pathname === `/liveTour/gameId=${gameId}` ||
    location.pathname === `/groupsFromGame/gameId=${gameId}` ||
    location.pathname === `/participatedStudents/gameId=${gameId}`;

  const handleTeacherSearch = useCallback(
    (searchText) => {
      navigate(!_.isEmpty(searchText) ? `/teacher?search=${searchText}` : "");
    },
    [navigate]
  );

  const handleStudentSearch = useCallback(
    (searchText) => {
      navigate(!_.isEmpty(searchText) ? `/student?search=${searchText}` : "");
    },
    [navigate]
  );

  return (
    <>
      <div
        className={`${
          location.pathname === `/participatedStudents/gameId=${gameId}` ||
          location.pathname === `/studentFromGroup/groupId=${groupId}` ||
          location.pathname === `/groupsFromCreateGame/gameId=${gameId}` ||
          location.pathname === `/studentFromTourGame/gameId=${gameId}` ||
          location.pathname === `/studentFromTours/gameId=${gameId}`
            ? "mb-0"
            : "sm:my-6"
        }`}
      >
        <Navbar expand="xl" className={`${showSearchBox ? "sm:py-4" : ""}`}>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="shadow-none"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="flex">
              <NavLink
                to="/liveTour"
                className={`text-[#191D23] min-[1200px]:px-6 xl:px-6 2xl:px-10 ${
                  location.pathname === "/liveTour" ||
                  location.pathname === "/" ||
                  location.pathname === `/liveTour/gameId=${gameId}`
                    ? "font-bold border-b-0 xl:border-b-2 pb-1 border-[#8656A5] no-underline"
                    : "no-underline"
                }`}
              >
                Live Tour
              </NavLink>
              {role === "admin" && (
                <NavLink
                  to="/teacher"
                  className={`text-[#191D23] min-[1200px]:px-6 xl:px-6 2xl:px-10 ${
                    location.pathname === "/teacher"
                      ? "font-bold border-b-0 xl:border-b-2 pb-1 border-[#8656A5] no-underline"
                      : "no-underline"
                  }`}
                >
                  Teacher
                </NavLink>
              )}
              <NavLink
                to="/student"
                className={`text-[#191D23] min-[1200px]:px-6 xl:px-6 2xl:px-10 ${
                  location.pathname === "/student"
                    ? "font-bold border-b-0 xl:border-b-2 pb-1 border-[#8656A5] no-underline"
                    : "no-underline"
                }`}
              >
                Student
              </NavLink>
              <NavLink
                to="/locations"
                className={`text-[#191D23] min-[1200px]:px-6 xl:px-6 2xl:px-10 ${
                  location.pathname === "/locations" ||
                  location.pathname === `/StopsFromLocation/tourId=${tourId}` ||
                  location.pathname === `/groupsFromGame/gameId=${gameId}` ||
                  location.pathname ===
                    `/studentFromTourGame/gameId=${gameId}`
                    ? "font-bold border-b-0 xl:border-b-2 pb-1 border-[#8656A5] no-underline"
                    : "no-underline"
                }`}
              >
                Locations
              </NavLink>
              <NavLink
                to="/tours"
                className={`text-[#191D23] min-[1200px]:px-6 xl:px-6 2xl:px-10 ${
                  location.pathname === "/tours" ||
                  location.pathname === `/studentFromTours/gameId=${gameId}` ||
                  location.pathname === `/groupsFromCreateGame/gameId=${gameId}` ||
                  location.pathname ===
                  `/studentFromTourCreateGame/groupId=${groupId}`||
                  location.pathname === `/liveTourFromTours/gameId=${gameId}`
                    ? "font-bold border-b-0 xl:border-b-2 pb-1 border-[#8656A5] no-underline"
                    : "no-underline"
                }`}
              >
                Tours
              </NavLink>
              <NavLink
                to="/groups"
                className={`text-[#191D23] min-[1200px]:px-6 xl:px-6 2xl:px-10 ${
                  location.pathname === "/groups" ||
                  location.pathname === `/studentFromGroup/groupId=${groupId}`
                    ? "font-bold border-b-0 xl:border-b-2 pb-1 border-[#8656A5] no-underline"
                    : "no-underline"
                }`}
              >
                Groups
              </NavLink>
              <NavLink
                to="/recentHistory"
                className={`text-[#191D23] min-[1200px]:px-6 xl:px-6 2xl:px-10 ${
                  location.pathname === "/recentHistory" ||
                  location.pathname === "/allToursHistory" ||
                  location.pathname === `/completedTours/tourId=${tourId}` ||
                  location.pathname === `/participatedStudents/gameId=${gameId}`
                    ? "font-bold border-b-0 xl:border-b-2 pb-1 border-[#8656A5] no-underline"
                    : "no-underline"
                }`}
              >
                History
              </NavLink>
            </Nav>
          </Navbar.Collapse>
          <Navbar href="" className="relative">
            {!showSearchBox && (
              <div className="flex justify-end items-center layout_container">
                <TextField
                  type="text"
                  id="navbarMenu"
                  autoComplete="off"
                  variant="outlined"
                  placeholder="Search..."
                  onChange={(e) => {
                    if (location.pathname === "/teacher") {
                      handleTeacherSearch(e.target.value);
                    } else if (location.pathname === "/student") {
                      handleStudentSearch(e.target.value);
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          alt="searchIcon"
                          src={searchIcon}
                          className="max-w-none"
                        />
                      </InputAdornment>
                    ),
                  }}
                  className="xl:w-96 sm:w-80 p-0"
                />
              </div>
            )}
          </Navbar>
        </Navbar>
      </div>
    </>
  );
};

export default NavbarMenu;
