import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import * as _ from "lodash";
import { toast } from "react-toastify";
import { useLoginMutation } from "../../../api/auth";
import * as Yup from "yup";
import loginImage from "../../../assets/svg/loginImage.svg";
import rightSideArrow from "../../../assets/svg/rightSideArrow.svg";
import emailIcon from "../../../assets/svg/emailIcon.svg";
import passowrdIcon from "../../../assets/svg/passwordIcon.svg";
import { BsEyeSlash, BsEye } from "react-icons/bs";

const Login = () => {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [login] = useLoginMutation();

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  const handleLogin = async (values) => {
    const data = await login(values);
    if (data?.data?.status === 202) {
      toast("Login successfully", { autoClose: 1000 });
      // sessionStorage.setItem("hasLoggedIn", "true");
      localStorage.setItem("authorization", data?.data?.token);
      localStorage.setItem("userRole", data?.data?.userRole);
      localStorage.setItem("email", data?.data?.email);
      localStorage.setItem("profileId", data?.data?.profileId);
      navigate("/liveTour");
      if (data?.data?.userRole === "teacher") {
        localStorage.setItem("teacherId", data?.data?.teacherId);
      }
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  const initialValues = {
    email: "",
    password: "",
    notificationToken: "Hello",
    deviceName: "iphone 14plus",
    platform: "IOS",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  return (
    <>
      <div className="container">
        <div className="w-full flex flex-row gap-20 items-center justify-center h-screen">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-none d-lg-block">
            <img alt="loginImage" src={loginImage} />
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <div className="flex flex-col gap-16">
              <div className="flex flex-col">
                <span className="text-[#8656A5] font-sfpro font-bold text-3xl leading-[3rem]">
                  Login to account
                </span>
                <span className="text-[#939393] font-sfpro font-bold text-xl">
                  Please enter email and password to continue
                </span>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
              >
                {({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-28">
                      <div className="flex flex-col gap-4">
                        <div className="h-20 w-full">
                          <span className="w-full h-28 mt-16 text-start font-sfpro font-bold text-base text-[#939393]">
                            Email
                            <div className="relative mt-2">
                              <img
                                alt="email"
                                className="absolute z-10 top-4 left-4"
                                src={emailIcon}
                              />
                              <Field
                                type="text"
                                name="email"
                                autoComplete="username"
                                className="w-full relative font-semibold font-sfpro text-[#8656A5] text-base border-2 rounded-full border-[#8656A5] pl-12 pr-2 py-2"
                              />
                            </div>
                            <div className="text-red-600 text-sm">
                              <ErrorMessage name="email" />
                            </div>
                          </span>
                        </div>
                        <div className="h-20 w-full">
                          <span className="w-full h-24 mt-2 text-start font-sfpro font-bold text-base text-[#939393]">
                            Password
                            <div className="relative mt-2">
                              <img
                                alt="password"
                                className="absolute z-10 top-3 left-5"
                                src={passowrdIcon}
                              />
                              <Field
                                name="password"
                                type={passwordVisible ? "text" : "password"}
                                autoComplete="current-password"
                                className="w-full relative font-semibold font-sfpro text-[#8656A5] text-base border-2 rounded-full border-[#8656A5] pl-12 pr-2 py-2"
                              />
                              <button
                                type="button"
                                className="absolute z-10 top-3 right-5"
                                onClick={togglePasswordVisibility}
                              >
                                {passwordVisible ? (
                                  <BsEye size={22} />
                                ) : (
                                  <BsEyeSlash size={22} />
                                )}
                              </button>
                            </div>
                            <div className="text-red-600 text-sm">
                              <ErrorMessage name="password" />
                            </div>
                          </span>
                          <div className="flex justify-end">
                            <span
                              className="mt-4 cursor-pointer font-bold font-sfpro not-italic text-base text-[#939393]"
                              onClick={() => {
                                navigate("/forgetPassword");
                              }}
                            >
                              Forgot password ?
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-center">
                        <button
                          type="submit"
                          onSubmit={handleLogin}
                          className="flex justify-center gap-4 items-center uppercase font-sfpro bg-[#8656A5] text-white w-10/12 py-2 border-3 border-gray-300 rounded-xl"
                        >
                          Sign In
                          <img alt="rightSideArrow" src={rightSideArrow} />
                        </button>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="login-as-teacher-btn fw-bold text-[#939393] mx-auto d-block mt-2"
                      onClick={() => navigate("/teacher-signup")}
                    >
                      Register as teacher{" "}
                      <span className="fw-bold text-[#8656A5]">&#8594;</span>
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
