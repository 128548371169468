import React, { useState } from "react";
import Select from "react-select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as _ from "lodash";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "../../../assets/css/pages.css";
import { useCreateStudentMutation } from "../../../api/student";
import { useGetTeacherQuery } from "../../../api/teacher";
import userImg from "../../../assets/svg/userImg.jpeg";

const CreateStudent = (props) => {
  const { setShowCreateStudentForm, refetchCreatedStudentData } = props;

  const role = localStorage.getItem("userRole");

  const [selectedImage, setSelectedImage] = useState();
  const [Error, setError] = useState(false);

  const [createStudent] = useCreateStudentMutation();
  const { data: teacherData } = useGetTeacherQuery();
  const teachers = role === "admin" ? teacherData : "";
  const [selectedTeachers, setSelectedTeachers] = useState([]);

  const teacherIds = _.map(selectedTeachers, (item) => {
    return { teacherId: item.value };
  });

  const list = _.map(teachers?.data, (item) => {
    return {
      name: item.profile.firstName + " " + item.profile.lastName,
      profileImage: item.profile.profileImage,
      id: item.teacherId,
    };
  });

  const onImageError = (e) => {
    e.target.src = userImg;
  };

  const teacherOptions = _.map(list, (item) => ({
    value: item.id,
    label: (
      <>
        <div className="flex flex-row w-24 h-10 whitespace-nowrap gap-3 p-1">
          <img
            src={item.profileImage}
            alt="profileImage"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "5px",
            }}
            onError={onImageError}
          />
          <span className="font-sfpro mt-1 font-semibold text-base text-neutral-800">
            {item.name}
          </span>
        </div>
      </>
    ),
  }));

  const error = role === "admin" && _.isEmpty(selectedTeachers);

  const handleTeacherSelectChange = (selectedOptions) => {
    setSelectedTeachers(selectedOptions);
  };

  const handlePhoto = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleSave = async (values) => {
    setError(error);
    const tId = localStorage.getItem("teacherId");
    const formData = new FormData();
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("email", values.email);
    formData.append("age", parseInt(values.age, 10));
    formData.append("class", values.class);
    formData.append("teachers", JSON.stringify(teacherIds));
    formData.append("profileImage", selectedImage);
    if (!error) {
      const data = await createStudent({ formData, tId });
      if (data?.data?.status === "success") {
        toast("Student added successfully", { autoClose: 1000 });
        setShowCreateStudentForm(false);
        refetchCreatedStudentData();
      } else {
        toast.error(data?.error?.data?.message, { autoClose: 1000 });
      }
    }
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    age: "",
    class: "",
    teachers: [],
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email().required("Email is required"),
    age: Yup.number()
      .required("Age is required")
      .typeError("Age must be a number"),
    class: Yup.string()
      .required("Year is required")
      .typeError("Year must be a number"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSave}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className="flex flex-col justify-start items-start max-[639px]:gap-3 sm:gap-10">
            <span className="w-full flex justify-center font-sfpro font-medium text-3xl text-black leading-9 custom_font">
              Add student details
            </span>

            <div className="w-full grid md:grid-rows-2 sm:gap-2 md:gap-4">
              <div className="flex custome_create_col sm:flex-col md:flex-row justify-between sm:gap-3 md:gap-5">
                <div className="sm:w-full md:w-1/2 flex flex-col gap-3">
                  <span className="font-sfpro h-20 font-medium sm:text-xl text-[#939393] leading-6">
                    First Name
                    <Field
                      type="text"
                      name="firstName"
                      className="font-sfpro font-medium sm:text-xl text-black leading-6 w-full py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                    />
                    <div className="text-red-600 text-sm">
                      <ErrorMessage name="firstName" />
                    </div>
                  </span>
                </div>
                <div className="sm:w-full md:w-1/2 flex flex-col gap-3">
                  <span className="h-20 font-sfpro font-medium sm:text-xl text-[#939393] leading-6">
                    Last Name
                    <Field
                      type="text"
                      name="lastName"
                      className="font-sfpro font-medium sm:text-xl text-black leading-6 w-full py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                    />
                    <div className="text-red-600 text-sm">
                      <ErrorMessage name="lastName" />
                    </div>
                  </span>
                </div>
              </div>

              <div className="w-full flex flex-col gap-3">
                <span className="h-20 font-sfpro font-medium sm:text-xl text-[#939393] leading-6">
                  Email
                  <Field
                    type="text"
                    name="email"
                    className="font-sfpro font-medium sm:text-xl text-black leading-6 w-full py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                  />
                  <div className="text-red-600 text-sm">
                    <ErrorMessage name="email" />
                  </div>
                </span>
              </div>

              <div className="flex custome_create_col sm:flex-col md:flex-row justify-between sm:gap-3 md:gap-5">
                <div className="sm:w-full md:w-1/2 flex flex-col gap-3">
                  <span className="h-20 font-sfpro font-medium sm:text-xl text-[#939393] leading-6">
                    Age
                    <Field
                      type="text"
                      name="age"
                      className="font-sfpro font-medium sm:text-xl text-black leading-6 w-full py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                    />
                    <div className="text-red-600 text-sm">
                      <ErrorMessage name="age" />
                    </div>
                  </span>
                </div>
                <div className="sm:w-full md:w-1/2 flex flex-col gap-3">
                  <span className="h-20 font-sfpro font-medium sm:text-xl text-[#939393] leading-6">
                    Year
                    <Field
                      type="text"
                      name="class"
                      className="font-sfpro font-medium sm:text-xl text-black leading-6 w-full py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                    />
                    <div className="text-red-600 text-sm">
                      <ErrorMessage name="class" />
                    </div>
                  </span>
                </div>
              </div>

              <div className="h-20 w-full flex flex-col gap-1">
                <label className="font-sfpro font-medium sm:text-xl text-[#939393] leading-6">
                  Profile picture
                </label>
                <div className="col-span-3 rounded-3xl">
                  <input
                    type="file"
                    accept="image/*"
                    class="input_border"
                    onChange={handlePhoto}
                  />
                </div>
              </div>

              {role === "admin" && (
                <div className="w-full flex flex-col gap-2">
                  <label className="font-sfpro font-medium sm:text-xl text-[#939393] leading-6">
                    Teacher list
                  </label>
                  <Select
                    className="select"
                    name="teacher"
                    field="teacher"
                    setFieldValue={setFieldValue}
                    options={teacherOptions}
                    isMulti
                    value={selectedTeachers}
                    onChange={handleTeacherSelectChange}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                  />
                  {Error && error && (
                    <div className="text-red-600 font-semibold text-sm">
                      <span>At list one teacher must be provided</span>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="w-full flex justify-center gap-3 items-center">
              <button
                type="button"
                onClick={() => setShowCreateStudentForm(false)}
                className="font-sfpro font-medium text-base leading-6 text-gray-700 border-1 border-solid py-2 min-[200px]:px-6 sm:px-6 md:px-10 lg:px-16 xl:px-24 rounded-xl"
              >
                Cancel
              </button>
              <button
                type="submit"
                onSubmit={handleSave}
                className="font-sfpro font-medium text-base leading-5 bg-[#8656A5] text-white border-3 border-gray-300 py-2 min-[200px]:px-6 sm:px-6 md:px-10 lg:px-16 xl:px-24 rounded-xl"
              >
                Save
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateStudent;
