import React from "react";

const Modal = (props) => {
  const { className } = props;

  return (
    <div className="fixed inset-0 bg-black/25 h-auto w-auto z-1 p-8 flex">
      <div
        className={`${className} relative bg-white p-4 sm:p-10 m-auto w-auto h-full overflow-y-auto shadow-xl rounded-md`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
