import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as _ from "lodash";
import leftSideArrow from "../../../assets/svg/leftSideArrow.svg";
import LayoutContainer from "../../layout/LayoutContainer";
import pdfIcon from "../../../assets/png/pdfIcon.png";
import { useHistoryOfCompletedGamesQuery } from "../../../api/game";

import Loader from "../components/Loader";

const CompletedGames = () => {
  const navigate = useNavigate();
  const tours = useLocation();
  const tId = localStorage.getItem("teacherId");

  const tourId = tours?.state?.tourId;
  const tourName = tours?.state?.name;
  const [completedGamesData, setCompletedGamesData] = useState([]);
  const [gameClick, setGameClick] = useState(false);
  const [gameId, setGameId] = useState("");

  const {
    data: completedGames,
    refetch,
    isFetching,
  } = useHistoryOfCompletedGamesQuery({
    tourId,
    tId,
  });

  useEffect(() => {
    if (gameClick) {
      navigate(`/participatedStudents/gameId=${gameId}`, { state: gameId });
    }
  }, [gameClick, navigate, gameId]);

  const allCompletedGamesData = _.map(completedGamesData, (item) => {
    const itemDate = item.date;
    const dateObject = new Date(parseInt(itemDate));
    const dateFormate = `${dateObject?.getDate()} ${dateObject?.toLocaleString(
      "numeric",
      {
        month: "long",
      }
    )} ${dateObject?.getFullYear()}`;
    const timeFormate = dateObject?.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return {
      gameId: _.get(item, "gameId", ""),
      name: _.get(item, "name", ""),
      date: dateFormate,
      time: timeFormate,
      zip: _.get(item, "zip", ""),
    };
  });

  useEffect(() => {
    refetch();
    if (completedGames?.data?.status === "success") return;
    if (completedGames) {
      setCompletedGamesData(completedGames.data);
    } else {
      setCompletedGamesData([]);
    }
  }, [completedGames, refetch]);

  const downloadPdf = (item) => {
    const zip = document.createElement("a");
    zip.href = item.zip;
    window.location.replace(zip.href);
  };

  return (
    <>
      <LayoutContainer />
      <div className="max-[639px]:px-2 px-8 max-[330px]:pt-0">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-5">
            <span
              className="font-sfpro text-xl sm:text-2xl cursor-pointer"
              onClick={() => navigate("/recentHistory")}
            >
              Recent
            </span>
            <span className="font-sfpro text-xl sm:text-2xl cursor-default px-2 border-b-2 font-bold border-[#8656A5] text-[#8656A5]">
              locations
            </span>
          </div>
          <div className="flex gap-3 justify-start">
            <div
              onClick={() => navigate("/allToursHistory")}
              className="flex flex-row gap-3 cursor-pointer"
            >
              <img alt="leftSideArrow" src={leftSideArrow} />
              <span className="font-sfpro text-xl sm:text-2xl font-bold text-[#8656A5]">
                {tourName}
              </span>
            </div>
          </div>
          <div className="h-[32rem] max-[320px]:h-[18rem] max-[390px]:h-[25rem] max-[395px]:h-[32rem] min-[400px]:h-[36rem] sm:h-[26rem] md:h-[30rem] lg:h-[30rem] xl:h-[34rem] overflow-y-auto border-b-2">
            <div className="flex flex-row gap-4 flex-wrap">
              {isFetching ? (
                <Loader />
              ) : (
                _.map(allCompletedGamesData, (item) => {
                  return (
                    <div
                      key={item.gameId}
                      className="flex flex-row justify-between max-[639px]:p-4 sm:p-6 border-2 border-solid rounded-3xl shadow-sm sm:w-[25rem] max-[639px]:w-[18rem]"
                    >
                      <div className="flex flex-col gap-2">
                        <span
                          onClick={() => {
                            if (item.zip !== "") {
                              setGameClick(true);
                              localStorage.setItem("gameId", item.gameId);
                              setGameId(item.gameId);
                            }
                          }}
                          className={` text-black font-sfpro font-medium text-xl sm:text-2xl ${
                            item.zip !== ""
                              ? "cursor-pointer"
                              : "cursor-not-allowed"
                          }`}
                        >
                          {item.name}
                        </span>
                        <span className="text-[#939393] font-sfpro font-medium text-base sm:text-lg">
                          {item.date}
                        </span>
                        <span className="text-[#939393] font-sfpro font-medium text-base sm:text-lg">
                          {item.time}
                        </span>
                      </div>
                      {item.zip !== "" && (
                        <div
                          onClick={() => downloadPdf(item)}
                          className="flex flex-row gap-1 justify-center items-center cursor-pointer"
                        >
                          <img alt="pdfIcon" src={pdfIcon} />
                          <span className="text-[#8656A5] font-sfpro font-bold text-xl">
                            PDF
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompletedGames;
