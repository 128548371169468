import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useCreateTourMutation } from "../../../api/tour";
import "../../../assets/css/pages.css";
import createActivityImage from "../../../assets/svg/createActivityImage.svg";

const CreateTour = (props) => {
  const { setShowCreateTourForm, refetchCreatedTourData } = props;
  const [createTour] = useCreateTourMutation();

  const handleSave = async (values) => {
    const data = await createTour(values);
    if (data?.data?.status === "success") {
      toast("Location added successfully", { autoClose: 1000 });
      setShowCreateTourForm(false);
      refetchCreatedTourData();
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  const initialValues = {
    name: "",
    latitude: "",
    longitude: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    latitude: Yup.string().required("Latitude is required"),
    longitude: Yup.string().required("Longitude is required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSave}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="fixed inset-0 bg-black/25 w-auto h-auto z-1 p-8 flex">
            <div className="relative bg-white p-4 sm:p-10 m-auto w-auto max-h-full overflow-y-auto shadow-xl rounded-md">
              <div className="max-[639px]:py-4 sm:py-20 sm:px-8 gap-4 flex flex-row items-center justify-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-none d-lg-block">
                  <img alt="createActivityImage" src={createActivityImage} />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="flex flex-col sm:gap-5 max-[639px]:gap-4">
                    <span className="flex justify-center text-black font-sfpro font-semibold sm:text-3xl text-2xl">
                      Add New Location
                    </span>
                    <div className="flex flex-col sm:gap-8 max-[639px]:gap-3">
                      <div className="h-20 w-full">
                        <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                          New Location Name
                          <Field
                            type="text"
                            name="name"
                            className="mt-2 font-sfpro font-medium text-lg sm:text-xl text-black leading-6 w-full max-[639px]:py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                          />
                          <div className="text-red-600 text-sm">
                            <ErrorMessage name="name" />
                          </div>
                        </span>
                      </div>
                      <div className="h-20">
                        <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                          Latitude
                          <Field
                            type="text"
                            name="latitude"
                            className="mt-2 font-sfpro font-medium text-lg sm:text-xl text-black leading-6 w-full max-[639px]:py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                          />
                          <div className="text-red-600 text-sm">
                            <ErrorMessage name="latitude" />
                          </div>
                        </span>
                      </div>
                      <div className="h-20">
                        <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                          Longitude
                          <Field
                            type="text"
                            name="longitude"
                            className="mt-2 font-sfpro font-medium text-lg sm:text-xl text-black leading-6 w-full max-[639px]:py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                          />
                          <div className="text-red-600 text-sm">
                            <ErrorMessage name="longitude" />
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="w-full flex flex-col sm:flex-row justify-center gap-3 items-center">
                      <button
                        type="button"
                        onClick={() => setShowCreateTourForm(false)}
                        className="py-2 px-20 font-sfpro font-medium text-base leading-6 text-gray-700 border-1 border-solid rounded-xl"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onSubmit={handleSave}
                        className="py-2 px-20 font-sfpro font-medium text-base leading-5 bg-[#8656A5] text-white border-3 border-gray-300 rounded-xl"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateTour;
