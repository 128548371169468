import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/svg/logo.svg";
import userImg from "../../assets/svg/userImg.jpeg";
import userIcon from "../../assets/svg/userIcon.svg";
import feedbackIcon from "../../assets/svg/feedbackIcon.svg";
import logoutIcon from "../../assets/svg/logoutIcon.svg";

import { useSelector } from "react-redux";
import { useGetProfileQuery } from "../../api/profile";
import { useDispatch } from "react-redux";
import { setProfile } from "../../redux/slices/profileSlice";
import DeleteModal from "../pages/components/DeleteModal";

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const liveGamePath = /^\/liveTour\/gameId=/.test(location.pathname);
  const profileDetail = useSelector((state) => state?.profile?.profileData);
  const role = localStorage.getItem("userRole");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const { data: profileData, refetch } = useGetProfileQuery();

  useEffect(() => {
    refetch();
    if (profileData?.data?.status === "success") {
      return;
    } else {
      toast.error(profileData?.error?.data?.message, { autoClose: 1000 });
    }
    if (profileData) {
      dispatch(setProfile(profileData?.data));
    }
  }, [profileData, refetch, dispatch]);

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onImageError = (e) => {
    e.target.src = userImg;
  };

  return (
    <>
      <div className="p-2 bg-[#8656a533] flex flex-row justify-between border rounded relative">
        <div className="flex items-center gap-3">
          <img alt="logo" src={logo} className="hidden sm:flex" />
          <span
            onClick={() => navigate("/liveTour")}
            className="font-sfpro font-semibold text-lg sm:text-2xl leading-9 cursor-pointer"
          >
            Stepout Academy
          </span>
        </div>
        <div className="flex gap-3">
          <button
            id="basic-button"
            className="relative flex gap-3 cursor-pointer"
            onClick={handleClick}
          >
            <div className="h-14 w-14 rounded-full">
              <img
                className="rounded-full"
                alt="profile"
                src={profileDetail?.profileImage || userImg}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                onError={onImageError}
              />
            </div>
            <div className="hidden sm:flex flex-col items-start">
              <span className="font-sfpro font-semibold text-xl leading-7">
                {profileDetail?.firstName?.charAt(0).toUpperCase() +
                  profileDetail?.firstName?.slice(1) +
                  " " +
                  profileDetail?.lastName?.charAt(0).toUpperCase() +
                  profileDetail?.lastName?.slice(1)}
              </span>
              <span className="font-sfpro text-base leading-8">
                {profileDetail?.role?.charAt(0).toUpperCase() +
                  profileDetail?.role?.slice(1)}
              </span>
            </div>
          </button>
          {isMenuOpen && (
            <div
              className={`absolute flex right-0 z-1 ${
                liveGamePath ? "top-[89px] sm:top-20" : "top-[89px] sm:top-24"
              }`}
            >
              <div
                className={`bg-white md:min-w-[15rem] flex flex-col shadow-2xl rounded-sm ${
                  liveGamePath ? "py-2 px-4 gap-2" : "p-3 sm:p-4 gap-2 sm:gap-4"
                }`}
              >
                <div
                  onClick={() => navigate("/profile")}
                  className="flex flex-row gap-2 cursor-pointer"
                >
                  <img alt="userIcon" src={userIcon} />
                  <span className="font-sfpro font-medium text-lg sm:text-xl leading-6">
                    Profile
                  </span>
                </div>
                {role === "admin" && (
                  <div
                    onClick={() => navigate("/feedbacks")}
                    className="flex flex-row gap-2 cursor-pointer"
                  >
                    <img alt="userIcon" src={feedbackIcon} />
                    <span className="font-sfpro font-medium text-lg sm:text-xl leading-6">
                      Feedbacks
                    </span>
                  </div>
                )}
                <div
                  className="flex flex-row gap-2 cursor-pointer"
                  onClick={() => setShowLogoutModal(true)}
                >
                  <img alt="userIcon" src={logoutIcon} />
                  <span className="font-sfpro font-medium text-lg sm:text-xl leading-6">
                    Logout
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showLogoutModal && (
        <DeleteModal
          setShowLogoutModal={setShowLogoutModal}
          showLogoutModal={showLogoutModal}
        />
      )}
    </>
  );
};

export default Header;
