import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";

export const tourApi = createApi({
  reducerPath: "tourApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/tour`,
    prepareHeaders,
  }),
  tagTypes: ["Tour"],
  endpoints: (builder) => ({
    getTour: builder.query({
      query: () => {
        return {
          url: "/getTour",
          method: "GET",
        };
      },
    }),
    createTour: builder.mutation({
      query: (body) => {
        return {
          url: "/createTour",
          method: "POST",
          body,
        };
      },
    }),
    updateTour: builder.mutation({
      query: (payload) => {
        const { values, tourId } = payload;
        return {
          url: `/updateTour/${tourId}`,
          method: "PUT",
          body: values,
        };
      },
    }),
    deleteTour: builder.mutation({
      query: (tourId) => {
        return {
          url: `/deleteTour/${tourId}`,
          method: "DELETE",
        };
      },
    }),
    historyOfTour: builder.query({
      query: (tId) => {
        const role = localStorage.getItem("userRole");
        return {
          url: `/historyOfTour${role === "teacher" ? `?teacherId=${tId}` : ""}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetTourQuery,
  useCreateTourMutation,
  useUpdateTourMutation,
  useDeleteTourMutation,
  useHistoryOfTourQuery,
} = tourApi;
