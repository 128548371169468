import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import * as _ from "lodash";
import * as Yup from "yup";
import { toast } from "react-toastify";
import closeIcon from "../../../assets/svg/closeIcon.svg";
import updateGroupImage from "../../../assets/svg/updateGroupImage.svg";
import { useGetTeacherQuery } from "../../../api/teacher";
import { useUpdateGroupMutation } from "../../../api/group";
import DeleteModal from "../components/DeleteModal";
import userImg from "../../../assets/svg/userImg.jpeg";

const UpdateGroup = (props) => {
  const {
    setShowUpdateGroupForm,
    refetchUpdatedGroupData,
    refetchDeletedGroupData,
    groups,
  } = props;

  const role = localStorage.getItem("userRole");
  const groupId = groups.groupId;
  const groupTeacherId = groups.teacherId;
  const [selectedTeachers, setSelectedTeachers] = useState(null);
  const [Error, setError] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [updateGroup] = useUpdateGroupMutation();
  const { data: teacherData } = useGetTeacherQuery();
  const teachers = role === "admin" ? teacherData : "";

  const list = _.map(teachers?.data, (item) => {
    return {
      name: item.profile.firstName + " " + item.profile.lastName,
      profileImage: item.profile.profileImage,
      id: item.teacherId,
    };
  });

  const onImageError = (e) => {
    e.target.src = userImg;
  };

  const teacherOptions = _.map(list, (item) => ({
    value: item.id,
    label: (
      <>
        <div className="flex flex-row w-24 h-10 whitespace-nowrap gap-3 p-1">
          <img
            src={item.profileImage}
            alt="profileImage"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "5px",
            }}
            onError={onImageError}
          />
          <span className="font-sfpro mt-1 font-semibold text-base text-neutral-800">
            {item.name}
          </span>
        </div>
      </>
    ),
  }));

  const matchedIds = list.filter((id) => {
    return groupTeacherId.includes(id?.id);
  });

  const teacherError = _.isEmpty(selectedTeachers);

  useEffect(() => {
    const matchedTeachers = _.map(matchedIds, (item) => ({
      value: item.id,
      label: item.name,
    }));
    setSelectedTeachers(matchedTeachers);
  }, []);

  const handleTeacherSelectChange = (selectedOptions) => {
    setSelectedTeachers(selectedOptions);
  };

  const handleUpdate = async (values) => {
    setError(teacherError);
    if (!Error) {
      const id = groups.groupId;
      const data = await updateGroup({ values, id });
      if (data?.data?.status === "success") {
        toast("Group updated successfully", { autoClose: 1000 });
        setShowUpdateGroupForm(false);
        refetchUpdatedGroupData();
      } else {
        toast.error(data?.error?.data?.message, { autoClose: 1000 });
      }
    }
  };

  const initialValues = {
    name: groups.name,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Group name is required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const data = {
          ...values,
          teacherId: selectedTeachers
            ? selectedTeachers.value || groupTeacherId
            : null,
        };
        handleUpdate(data);
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className="fixed inset-0 bg-black/25 w-auto h-auto z-1 p-8 flex">
            <div className="relative bg-white p-4 sm:p-10 m-auto w-auto max-h-full overflow-y-auto shadow-xl rounded-md">
              <div className="flex justify-end">
                <img
                  alt="closeIcon"
                  src={closeIcon}
                  className="cursor-pointer p-2 border-2 border-gray-500 border-solid rounded-xl"
                  onClick={() => setShowUpdateGroupForm(false)}
                />
              </div>
              <div className="max-[639px]:py-4 sm:py-20 sm:px-8 gap-4 flex flex-row items-center justify-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-none d-lg-block">
                  <img alt="updateGroupImage" src={updateGroupImage} />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="flex flex-col sm:gap-5 max-[639px]:gap-4">
                    <span className="flex justify-center text-black font-sfpro font-semibold sm:text-3xl text-2xl">
                      Update Group
                    </span>
                    <div className="flex flex-col sm:gap-8 max-[639px]:gap-3">
                      <div className="h-20 w-full">
                        <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                          Group Name
                          <Field
                            type="text"
                            name="name"
                            className="mt-2 font-sfpro font-medium text-lg sm:text-xl text-black leading-6 w-full max-[639px]:py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                          />
                          <div className="text-red-600 text-sm">
                            <ErrorMessage name="name" />
                          </div>
                        </span>
                      </div>
                      {role === "admin" && (
                        <div className="h-20 w-full">
                          <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                            Teacher list
                            <Select
                              name="teacher"
                              field="teacher"
                              setFieldValue={setFieldValue}
                              options={teacherOptions}
                              value={selectedTeachers}
                              onChange={handleTeacherSelectChange}
                              hideSelectedOptions={false}
                              closeMenuOnSelect={true}
                            />
                            {Error && teacherError && (
                              <div className="text-red-600 font-semibold text-sm">
                                <span>Please select a teacher</span>
                              </div>
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="w-full flex flex-col sm:flex-row justify-center gap-3 items-center">
                      <button
                        type="submit"
                        onSubmit={handleUpdate}
                        className="py-2 px-16 font-sfpro font-medium text-base leading-6 text-gray-700 border-1 border-solid rounded-xl"
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        onClick={() => setShowDeleteModal(true)}
                        className="py-2 px-16 font-sfpro font-medium text-base leading-5 bg-[#8656A5] text-white border-3 border-gray-300 rounded-xl"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showDeleteModal && (
            <DeleteModal
              setShowDeleteModal={setShowDeleteModal}
              setShowUpdateGroupForm={setShowUpdateGroupForm}
              showDeleteModal={showDeleteModal}
              refetchDeletedGroupData={refetchDeletedGroupData}
              groupId={groupId}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default UpdateGroup;
