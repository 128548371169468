import React, { useEffect, useState } from "react";
import LayoutContainer from "../../layout/LayoutContainer";
import * as _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import plusIcon from "../../../assets/svg/plusIcon.svg";
import { toast } from "react-toastify";
import editIcon1 from "../../../assets/svg/editIcon1.svg";
import leftSideArrow from "../../../assets/svg/leftSideArrow.svg";
import { useGetStopQuery } from "../../../api/stop";
import "../../../assets/css/pages.css";
import { TbMoodEmpty } from "react-icons/tb";

import Loader from "../components/Loader";
import CreateStops from "./CreateStops";
import UpdateStops from "./UpdateStops";

const StopsFromTour = () => {
  const navigate = useNavigate();
  const tours = useLocation();
  const tourId = tours?.state?.tourId;
  const teacherId = localStorage.getItem("teacherId");
  const role = localStorage.getItem("userRole");
  const [showCreateStopsForm, setShowCreateStopsForm] = useState(false);
  const [showUpdateStopsForm, setShowUpdateStopsForm] = useState(false);
  const [stopData, setStopData] = useState([]);
  const [stops, setStops] = useState([]);

  const {
    data: stop,
    refetch,
    isFetching,
  } = useGetStopQuery({ tourId, teacherId });

  useEffect(() => {
    refetch();
    if (stop?.data?.status === "success") {
      return;
    } else {
      toast.error(stop?.error?.data?.message, { autoClose: 1000 });
    }
    if (stop) {
      setStopData(stop.data);
    } else {
      setStopData([]);
    }
  }, [stop, refetch]);

  const allStopData = _.map(stopData, (item) => {
    return {
      stopId: _.get(item, "stopId", ""),
      stopName: _.get(item, "stopName", ""),
      tourId: _.get(item, "tourId", ""),
      tourName: _.get(item, "tourName", ""),
      latitude: _.get(item, "latitude", ""),
      longitude: _.get(item, "longitude", ""),
    };
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const totalPages = Math.ceil(allStopData.length / itemsPerPage);

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <LayoutContainer />
      <div className="max-[639px]:px-2 sm:px-8 max-[330px]:pt-0">
        <div className="flex gap-3 justify-start">
          <div
            onClick={() => navigate("/locations")}
            className="flex flex-row gap-3 cursor-pointer"
          >
            <img alt="leftSideArrow" src={leftSideArrow} />
            <span className="text-[#8656A5] font-sfpro font-bold text-xl sm:text-2xl border-[#8656A5]">
              {tours?.state?.name}
            </span>
          </div>
        </div>

        <div className="h-[32rem] max-[320px]:h-[20rem] max-[390px]:h-[25rem] max-[395px]:h-[32rem] min-[400px]:h-[38rem] sm:h-[26rem] md:h-[32rem] lg:h-[32rem] xl:h-[33rem] overflow-y-auto border-b-2">
          {!_.isEmpty(stopData) ? (
            <div className="py-4 flex flex-row flex-wrap gap-4">
              {allStopData
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((item) => {
                  return (
                    <div
                      key={item.stopId}
                      className="flex flex-col gap-2 max-[639px]:p-4 sm:p-6 border-2 border-solid rounded-3xl shadow-sm sm:w-[25rem] max-[639px]:w-[18rem]"
                    >
                      <div className="flex flex-row justify-between cursor-default">
                        <span className="text-black font-sfpro font-medium text-xl sm:text-2xl">
                          {item.stopName}
                        </span>
                        {role === "admin" && (
                          <img
                            alt="editIcon1"
                            src={editIcon1}
                            className="cursor-pointer"
                            onClick={() => {
                              setShowUpdateStopsForm(true);
                              setStops(item);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : !_.isEmpty(stopData) || isFetching ? (
            <Loader />
          ) : (
            <div className="py-4 mt-20 flex flex-col justify-center gap-3">
              <span className="flex justify-center">
                <TbMoodEmpty size={100} />
              </span>
              <span className="flex justify-center text-gray-800 font-sfpro text-lg leading-7">
                No stop records..!
              </span>
            </div>
          )}
        </div>

        <div className="flex flex-col sm:flex-row btnGrp_flex justify-between">
          {role === "admin" && (
            <div className="flex justify-end mt-3">
              <button
                className="flex flex-row items-center gap-2 bg-[#8656A5] py-2 px-12 border-3 border-[#AFB7D6] rounded-2xl text-white font-sfpro text-lg leading-7"
                type="button"
                onClick={() => setShowCreateStopsForm(true)}
              >
                <img alt="plusIcon" src={plusIcon} />
                New Stop
              </button>
            </div>
          )}
          <div className="flex justify-end mt-3 gap-3">
            {currentPage > 1 && (
              <button
                type="button"
                onClick={goToPreviousPage}
                className="flex flex-row items-center gap-2 py-2 px-3.5 border-1 border-solid rounded-xl text-gray-700 font-sfpro text-lg leading-7"
              >
                Previous
              </button>
            )}
            {currentPage < totalPages && (
              <button
                type="button"
                onClick={goToNextPage}
                className="flex flex-row items-center gap-2 py-2 px-3.5 border-1 border-solid rounded-xl text-gray-700 font-sfpro text-lg leading-7"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
      {showCreateStopsForm && (
        <CreateStops
          setShowCreateStopsForm={setShowCreateStopsForm}
          refetchCreatedStopData={refetch}
          tourId={tourId}
        />
      )}
      {showUpdateStopsForm && (
        <UpdateStops
          setShowUpdateStopsForm={setShowUpdateStopsForm}
          refetchUpdatedStopData={refetch}
          stops={stops}
          refetchDeletedStopData={refetch}
          tourId={tourId}
        />
      )}
    </>
  );
};

export default StopsFromTour;
