import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";

export const gameApi = createApi({
  reducerPath: "gameApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/game`,
    prepareHeaders,
  }),
  tagTypes: ["Game"],
  endpoints: (builder) => ({
    getGame: builder.query({
      query: (payload) => {
        const { tourId, teacherId } = payload;
        const role = localStorage.getItem("userRole");
        return {
          url: `/getGame/${tourId}${
            role === "teacher" ? `?teacherId=${teacherId}` : ""
          }`,
          method: "GET",
        };
      },
      providesTags: ["Game"],
    }),
    createGame: builder.mutation({
      query: (body) => {
        return {
          url: "/createGame",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Game"],
    }),
    updateGame: builder.mutation({
      query: (payload) => {
        const { values, id } = payload;
        return {
          url: `/updateGame/${id}`,
          method: "PUT",
          body: values,
        };
      },
      invalidatesTags: ["Game"],
    }),
    deleteGame: builder.mutation({
      query: (gId) => {
        return {
          url: `/deleteGame/${gId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Game"],
    }),
    allGames: builder.query({
      query: (tId) => {
        const role = localStorage.getItem("userRole");
        return {
          url: `/allGames${role === "teacher" ? `?teacherId=${tId}` : ""}`,
          method: "GET",
        };
      },
      providesTags: ["Game"],
    }),
    gameStatus: builder.mutation({
      query: (payload) => {
        const { body, gameId } = payload;
        return {
          url: `/gameStatus/${gameId}`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Game"],
    }),
    forceComplete: builder.mutation({
      query: (payload) => {
        const { body, gameId } = payload;
        return {
          url: `/forceComplete/${gameId}`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Game"],
    }),
    lastOneMonthHistoryOfCompletedGames: builder.query({
      query: (tId) => {
        const role = localStorage.getItem("userRole");
        return {
          url: `/lastOneMonthHistoryOfCompletedGames${
            role === "teacher" ? `?teacherId=${tId}` : ""
          }`,
          method: "GET",
        };
      },
      providesTags: ["Game"],
    }),
    historyOfCompletedGames: builder.query({
      query: (payload) => {
        const role = localStorage.getItem("userRole");
        const { tourId, tId } = payload;
        return {
          url: `/historyOfCompletedGames/${tourId}${
            role === "teacher" ? `?teacherId=${tId}` : ""
          }`,
          method: "GET",
        };
      },
      providesTags: ["Game"],
    }),
    findByGameId: builder.query({
      query: (payload) => {
        const role = localStorage.getItem("userRole");
        const { gameId, tId } = payload;
        return {
          url: `/findByGameId?gameId=${gameId}${
            role === "teacher" ? `&teacherId=${tId}` : ""
          }`,
          method: "GET",
        };
      },
      providesTags: ["Game"],
    }),
    liveGames: builder.query({
      query: (tId) => {
        const role = localStorage.getItem("userRole");
        return {
          url: `/liveGames${role === "teacher" ? `?teacherId=${tId}` : ""}`,
          method: "GET",
        };
      },
      providesTags: ["Game"],
    }),
    liveStudentByGame: builder.mutation({
      query: (body) => {
        return {
          url: "/liveStudentByGame",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Game"],
    }),
  }),
});

export const {
  useGetGameQuery,
  useCreateGameMutation,
  useUpdateGameMutation,
  useDeleteGameMutation,
  useAllGamesQuery,
  useGameStatusMutation,
  useForceCompleteMutation,
  useLastOneMonthHistoryOfCompletedGamesQuery,
  useHistoryOfCompletedGamesQuery,
  useFindByGameIdQuery,
  useLiveGamesQuery,
  useLiveStudentByGameMutation,
} = gameApi;
