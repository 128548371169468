import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import verificationImage from "../../../assets/svg/verificationImage.svg";
import {
  useCompareVerificationCodeMutation,
  useForgetPasswordMutation,
} from "../../../api/auth";

const Verification = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [compareVerificationCode] = useCompareVerificationCodeMutation();
  const [forgetPassword] = useForgetPasswordMutation();

  const email = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleVerificationCode = async (values) => {
    const data = await compareVerificationCode(values);
    if (data?.data?.status === "Success") {
      localStorage.setItem("token", data?.data?.token);
      navigate("/changePassword", { state: values });
    } else {
      toast.error(data?.error?.data?.message);
    }
  };

  const handleResendOtp = async () => {
    const values = { email: email.state.email };
    const data = await forgetPassword(values);
    if (data?.data?.email) {
      localStorage.setItem("token", data?.data?.token);
      toast("New OPT will send on your email address", { autoClose: 1000 });
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  const initialValues = {
    verificationCode: "",
    email: "",
  };

  return (
    <>
      <div className="container">
        <div className="w-full row items-center justify-center h-screen">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-none d-lg-block">
            <div className="mr-16">
              <img alt="loginImage" src={verificationImage} />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <span className="text-[#8656A5] font-sfpro font-bold text-3xl">
              Enter your verification code
            </span>
            <br />
            <Formik
              initialValues={initialValues}
              onSubmit={(values, { setSubmitting }) => {
                const code = {
                  ...values,
                  verificationCode: otp,
                  email: email.state.email,
                };
                handleVerificationCode(code);
                setSubmitting(false);
              }}
            >
              {({ handleSubmit }) => (
                <>
                  <Form onSubmit={handleSubmit}>
                    <div className="w-full mt-14 gap-4">
                      <OTPInput
                        value={otp}
                        inputStyle={{
                          width: windowWidth > 415 ? "55px" : "30px",
                          height: windowWidth > 415 ? "55px" : "30px",
                          borderRadius: "6px",
                          background: "rgba(134, 86, 165, 0.2)",
                          boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
                          fontWeight: "bold",
                          fontSize: "1.5em",
                        }}
                        containerStyle={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <div>
                      <label className="w-full mt-3 text-start font-sfpro font-medium text-base text-black">
                        We have sent verification code to your email address.
                      </label>
                    </div>
                    <div className="mt-20">
                      <span className="w-full text-start font-sfpro font-bold text-base text-black">
                        I didn’t received the code?
                        <button
                          type="button"
                          className="ml-2 font-sfpro font-bold underline text-base text-[#B54708] cursor-pointer"
                          onClick={handleResendOtp}
                        >
                          Send again
                        </button>
                      </span>
                    </div>
                    <div className="flex items-center justify-center mt-6">
                      <button
                        type="submit"
                        onSubmit={handleVerificationCode}
                        className="flex justify-center gap-4 items-center font-sfpro bg-[#8656A5] text-white w-[30rem] py-2 border-3 border-gray-300 rounded-xl"
                      >
                        Verify
                      </button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
