import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import * as _ from "lodash";
import calendarIcon1 from "../../../assets/svg/calendarIcon1.svg";
import { toast } from "react-toastify";
import { TbMoodEmpty } from "react-icons/tb";
import { useGetFeedbackQuery } from "../../../api/feedback";
import Loader from "../components/Loader";
import userImg from "../../../assets/svg/userImg.jpeg";

const Feedback = () => {
  const [feedbackData, setFeedBackData] = useState([]);

  const { data: feedback, refetch, isFetching } = useGetFeedbackQuery();

  useEffect(() => {
    refetch();
    if (feedback?.data?.status === "success") {
      return;
    } else {
      toast.error(feedback?.error?.data?.message, { autoClose: 1000 });
    }
    if (feedback) {
      setFeedBackData(feedback?.data);
    }
  }, [feedback, refetch]);

  const allFeedbackData = _.map(feedbackData, (item) => {
    const firstName = _.get(item, "firstName", "");
    const lastName = _.get(item, "lastName", "");
    const itemDate = item.date_time;
    const dateObject = new Date(parseInt(itemDate));
    const dateFormate = `${dateObject?.getDate()} ${dateObject?.toLocaleString(
      "numeric",
      {
        month: "long",
      }
    )} ${dateObject?.getFullYear()}`;
    return {
      feedbackId: _.get(item, "feedbackId", ""),
      profileImage: _.get(item, "profileImage", ""),
      firstName: _.get(item, "firstName", ""),
      lastName: _.get(item, "lastName", ""),
      studentName:
        firstName.charAt(0).toUpperCase() +
        firstName.slice(1) +
        " " +
        lastName.charAt(0).toUpperCase() +
        lastName.slice(1),
      feedbackText: _.get(item, "feedbackText", ""),
      date_time: dateFormate,
    };
  });

  const onImageError = (e) => {
    e.target.src = userImg;
  };

  return (
    <>
      <div className="w-full h-screen">
        <div className="max-[639px]:px-2 px-8 max-[639px]:pt-2 pt-8">
          <Header />
          <div className="py-8">
            <div className="flex flex-col gap-3">
              <div>
                <span className="font-sfpro text-[#8656A5] text-3xl font-bold">
                  Feedbacks
                </span>
              </div>

              <div className="h-[36rem] overflow-y-auto">
                {!_.isEmpty(feedbackData) ? (
                  _.map(allFeedbackData, (item) => {
                    return (
                      <div
                        key={item.feedbackId}
                        className="rounded-2xl shadow-md"
                      >
                        <div className="p-8 flex flex-col gap-3">
                          <div className="flex flex-row gap-3 items-center">
                            <div className="h-14 w-14">
                              <img
                                className="rounded-3xl relative"
                                src={item.profileImage || userImg}
                                alt="profileImage"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                onError={onImageError}
                              />
                            </div>
                            <div>
                              <span className="font-sfpro font-bold text-xl">
                                {item.studentName}
                              </span>
                            </div>
                            <div>
                              <img alt="calendarIcon1" src={calendarIcon1} />
                            </div>
                            <div>
                              <span className="font-sfpro font-medium text-lg text-[#939393]">
                                {item.date_time}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <span className="font-sfpro font-normal text-xl">
                              {item.feedbackText}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : !_.isEmpty(feedbackData) || isFetching ? (
                  <Loader />
                ) : (
                  <div className="py-4 mt-20 flex flex-col justify-center gap-3">
                    <span className="flex justify-center">
                      <TbMoodEmpty size={100} />
                    </span>
                    <span className="flex justify-center text-gray-800 font-sfpro text-lg leading-7">
                      No feedbacks..!
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
