import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import LayoutContainer from "../../layout/LayoutContainer";
import editIcon1 from "../../../assets/svg/editIcon1.svg";
import groupImage from "../../../assets/svg/groupImage.svg";
import { useGetGroupQuery } from "../../../api/group";
import { toast } from "react-toastify";
import plusIcon from "../../../assets/svg/plusIcon.svg";
import leftSideArrow from "../../../assets/svg/leftSideArrow.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAddGroupInGameMutation } from "../../../api/group";
import DeleteModal from "../components/DeleteModal";
import CreateGroup from "./CreateGroup";
import UpdateGroup from "./UpdateGroup";

import Loader from "../components/Loader";

const GroupTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const gameName = location.state;
  const { gameId } = useParams();
  const gamePath = /^\/groupsFromCreateGame\/gameId=/.test(location.pathname);
  const groupPath = location.pathname === "/groups";

  const [groupData, setGroupData] = useState([]);
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [showStudentsFromGroup, setShowStudentsFromGroup] = useState(false);
  const [showStudentsFromCreateGame, setShowStudentsFromCreateGame] =
    useState(false);
  const [showCreateGroupForm, setShowCreateGroupForm] = useState(false);
  const [showUpdateGroupForm, setShowUpdateGroupForm] = useState(false);
  const [invitationData, setInvitationData] = useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [groupId, setgroupId] = useState("");
  const [groups, setGroups] = useState("");

  const handleCheckboxChange = (checkboxId) => {
    setSelectedGroupIds((prevIds) =>
      prevIds.includes(checkboxId)
        ? prevIds.filter((id) => id !== checkboxId)
        : [...prevIds, checkboxId]
    );
  };

  const { data: group, refetch, isFetching } = useGetGroupQuery();
  const [addGroupInGame] = useAddGroupInGameMutation();

  useEffect(() => {
    if (showStudentsFromGroup) {
      navigate(`/studentFromGroup/groupId=${groupId}`, {
        state: groups,
      });
    } else if (showStudentsFromCreateGame) {
      navigate(`/studentFromTourCreateGame/groupId=${groupId}`, {
        state: groups,
      });
    }
  }, [
    showStudentsFromGroup,
    showStudentsFromCreateGame,
    navigate,
    groupId,
    groups,
  ]);

  useEffect(() => {
    if (group?.data?.status === "success") {
      return;
    } else {
      toast.error(group?.error?.data?.message, { autoClose: 1000 });
    }
    if (group) {
      setGroupData(group.data);
    } else {
      setGroupData([]);
    }
  }, [group, refetch]);

  const allGroupData = _.map(groupData, (item) => {
    return {
      groupId: _.get(item, "group.groupId", ""),
      name: _.get(item, "group.name", ""),
      teacherId: _.get(item, "teacherId", ""),
    };
  });

  const allInvitationData = _.map(invitationData, (item) => {
    return {
      groupId: _.get(item, "groupId", ""),
      groupName: _.get(item, "groupName", ""),
      studentCount: _.get(item, "studentCount", ""),
    };
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const totalPages = Math.ceil(allGroupData.length / itemsPerPage);

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleInvitation = async () => {
    const extractedGameId = gameId.substring("gameId=".length);
    const groupIdData = {
      gameId: extractedGameId,
      groupId: selectedGroupIds,
    };
    const data = await addGroupInGame(groupIdData);
    if (data?.data?.status === "success") {
      setInvitationData(data?.data?.data);
      setShowInvitationModal(true);
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  return (
    <>
      <LayoutContainer />
      {!_.isEmpty(groupData) ? (
        <div className="max-[639px]:px-2 px-8 max-[330px]:pt-0">
          {gamePath && (
            <div className="flex gap-3 justify-start">
              <div
                onClick={() => window.history.back()}
                className="mb-4 flex flex-row gap-3 cursor-pointer"
              >
                <img alt="leftSideArrow" src={leftSideArrow} />
                <span className="text-[#8656A5] font-sfpro font-bold text-2xl border-[#8656A5]">
                  {gameName}
                </span>
              </div>
            </div>
          )}

          <div
            className={`h-[32rem] overflow-y-auto border-b-2 ${
              gamePath
                ? "max-[320px]:h-[15rem] max-[390px]:h-[21rem] max-[395px]:h-[28rem] min-[400px]:h-[34rem] sm:h-[26rem] md:h-[32rem] lg:h-[32rem] xl:h-[33rem]"
                : "max-[320px]:h-[22rem] max-[390px]:h-[26rem] max-[395px]:h-[28rem] min-[400px]:h-[39rem] sm:h-[26rem] md:h-[32rem] lg:h-[32rem] xl:h-[35rem]"
            }`}
          >
            <div className="flex flex-row flex-wrap gap-4">
              {allGroupData
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((item) => {
                  return (
                    <div
                      key={item.groupId}
                      className="flex flex-col gap-2 max-[639px]:p-4 sm:p-6 border-2 border-solid rounded-3xl shadow-sm sm:w-[25rem] max-[639px]:w-[18rem]"
                    >
                      <div className="flex flex-row justify-between">
                        <div className="flex justify-start items-center max-[639px]:gap-2 sm:gap-4">
                          {gamePath && (
                            <input
                              type="checkbox"
                              className="max-[639px]:w-6 sm:w-8"
                              onChange={() =>
                                handleCheckboxChange(item.groupId)
                              }
                              checked={selectedGroupIds.includes(item.groupId)}
                            />
                          )}

                          <span
                            onClick={() => {
                              setGroups(item);
                              setgroupId(item.groupId);
                              localStorage.setItem("groupId", item.groupId);
                              gamePath
                                ? setShowStudentsFromCreateGame(true)
                                : groupPath && setShowStudentsFromGroup(true);
                            }}
                            className="text-black font-sfpro font-medium text-xl sm:text-2xl cursor-pointer"
                          >
                            {item.name}
                          </span>
                        </div>
                        <div>
                          <img
                            alt="editIcon1"
                            src={editIcon1}
                            className="cursor-pointer"
                            onClick={() => {
                              setShowUpdateGroupForm(true);
                              setGroups(item);
                            }}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="flex flex-row btnGrp_flex justify-between">
            <div className="flex flex-col sm:flex-row gap-2 justify-end mt-3">
              <button
                className="flex flex-row justify-center items-center gap-2 bg-[#8656A5] max-[639px]:py-1 sm:py-2 px-8 border-3 border-[#AFB7D6] rounded-2xl text-white font-sfpro text-lg leading-7"
                type="button"
                onClick={() => setShowCreateGroupForm(true)}
              >
                <img alt="plusIcon" src={plusIcon} />
                Add group
              </button>
              {gamePath && (
                <button
                  className="flex flex-row justify-center items-center max-[639px]:py-1 sm:py-2 px-4 border-3 border-[#AFB7D6] rounded-2xl text-[#8656A5] font-sfpro text-lg leading-7"
                  type="submit"
                  onClick={() => handleInvitation(selectedGroupIds)}
                >
                  "Send invite to play"
                </button>
              )}
            </div>
            <div className="flex flex-col sm:flex-row justify-end items-center mt-3 gap-3">
              {currentPage > 1 && (
                <button
                  type="button"
                  onClick={goToPreviousPage}
                  className="flex flex-row items-center gap-2 py-2 px-3.5 border-1 border-solid rounded-xl text-gray-700 font-sfpro text-lg leading-7"
                >
                  Previous
                </button>
              )}
              {currentPage < totalPages && (
                <button
                  type="button"
                  onClick={goToNextPage}
                  className="flex flex-row items-center gap-2 py-2 px-3.5 border-1 border-solid rounded-xl text-gray-700 font-sfpro text-lg leading-7"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      ) : !_.isEmpty(groupData) || isFetching ? (
        <Loader />
      ) : (
        <div className="w-full p-10 flex flex-row items-center justify-center">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <div className="flex flex-col gap-8">
              <div>
                <span className="text-[#0F172A] font-sfpro font-bold min-[200px]:text-4xl sm:text-5xl xl:text-7xl leading-[86px]">
                  Let’s
                </span>{" "}
                <br />
                <span className="text-[#0F172A] font-sfpro font-bold min-[200px]:text-4xl sm:text-5xl xl:text-7xl leading-[86px]">
                  Create{" "}
                  <span className="text-[#8656A5] font-sfpro font-bold min-[200px]:text-4xl sm:text-5xl xl:text-7xl leading-[86px]">
                    Group
                  </span>
                </span>
              </div>
              <span className="text-[#4B5262] font-sfpro text-2xl leading-8">
                "Step out and learn"
              </span>
              <button
                className="bg-[#8656A5] py-2 min-[450px]:w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/3 border-3 border-[#AFB7D6] rounded-lg text-white font-sfpro text-lg leading-7"
                type="button"
                onClick={() => setShowCreateGroupForm(true)}
              >
                Start to create group
              </button>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-none d-lg-block">
            <img alt="groupImage" src={groupImage} className="h-[32rem]" />
          </div>
        </div>
      )}

      {showInvitationModal && (
        <DeleteModal
          allInvitationData={allInvitationData}
          showInvitationModal={showInvitationModal}
          setShowInvitationModal={setShowInvitationModal}
        />
      )}
      {showCreateGroupForm && (
        <CreateGroup
          setShowCreateGroupForm={setShowCreateGroupForm}
          refetchCreatedGroupData={refetch}
        />
      )}
      {showUpdateGroupForm && (
        <UpdateGroup
          setShowUpdateGroupForm={setShowUpdateGroupForm}
          refetchUpdatedGroupData={refetch}
          refetchDeletedGroupData={refetch}
          groups={groups}
        />
      )}
    </>
  );
};

export default GroupTab;
