import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "../../../assets/css/pages.css";
import { useCreateTeacherMutation } from "../../../api/teacher";

const CreateTeacher = (props) => {
  const { setShowCreateTeacherForm, refetchCreatedTeacherData } = props;
  const [createTeacher] = useCreateTeacherMutation();
  const [selectedImage, setSelectedImage] = useState();

  const handlePhoto = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleSave = async (values) => {
    const formData = new FormData();
    formData.append("profileImage", selectedImage);
    formData.append("email", values.email);
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("password", values.password);
    const data = await createTeacher(formData);
    if (data?.data?.status === "success") {
      toast("Teacher created successfully", { autoClose: 1000 });
      setShowCreateTeacherForm(false);
      refetchCreatedTeacherData();
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    profileImage: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSave}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="flex flex-col justify-start items-start max-[639px]:gap-3 sm:gap-10">
            <span className="w-full flex justify-center font-sfpro font-medium text-3xl text-black leading-9 custom_font">
              Add techer details
            </span>

            <div className="w-full grid md:grid-rows-2 sm:gap-2 md:gap-4">
              <div className="flex custome_create_col sm:flex-col md:flex-row justify-between sm:gap-3 md:gap-5">
                <div className="sm:w-full md:w-1/2 flex flex-col gap-3">
                  <span className="font-sfpro h-20 font-medium sm:text-xl text-[#939393] leading-6">
                    First Name
                    <Field
                      type="text"
                      name="firstName"
                      className="font-sfpro font-medium sm:text-xl text-black leading-6 w-full py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                    />
                    <div className="text-red-600 text-sm">
                      <ErrorMessage name="firstName" />
                    </div>
                  </span>
                </div>
                <div className="sm:w-full md:w-1/2 flex flex-col gap-3">
                  <span className="h-20 font-sfpro font-medium sm:text-xl text-[#939393] leading-6">
                    Last Name
                    <Field
                      type="text"
                      name="lastName"
                      className="font-sfpro font-medium sm:text-xl text-black leading-6 w-full py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                    />
                    <div className="text-red-600 text-sm">
                      <ErrorMessage name="lastName" />
                    </div>
                  </span>
                </div>
              </div>

              <div className="flex custome_create_col sm:flex-col md:flex-row justify-between sm:gap-3 md:gap-5">
                <div className="sm:w-full md:w-1/2 flex flex-col gap-3">
                  <span className="h-20 font-sfpro font-medium sm:text-xl text-[#939393] leading-6">
                    Email
                    <Field
                      type="text"
                      name="email"
                      className="font-sfpro font-medium sm:text-xl text-black leading-6 w-full py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                    />
                    <div className="text-red-600 text-sm">
                      <ErrorMessage name="email" />
                    </div>
                  </span>
                </div>
                <div className="sm:w-full md:w-1/2 flex flex-col gap-3">
                  <span className="h-20 font-sfpro font-medium sm:text-xl text-[#939393] leading-6">
                    Password
                    <Field
                      type="password"
                      name="password"
                      className="font-sfpro font-medium sm:text-xl text-black leading-6 w-full py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                    />
                    <div className="text-red-600 text-sm">
                      <ErrorMessage name="password" />
                    </div>
                  </span>
                </div>
              </div>
              <div className="w-full flex flex-col gap-1">
                <label className="font-sfpro font-medium sm:text-xl text-[#939393] leading-6">
                  Profile picture
                </label>
                <div className="col-span-3 rounded-3xl">
                  <input
                    type="file"
                    accept="image/*"
                    class="input_border"
                    onChange={handlePhoto}
                  />
                </div>
              </div>
            </div>

            <div className="w-full flex justify-center gap-3 items-center">
              <button
                type="button"
                onClick={() => setShowCreateTeacherForm(false)}
                className="font-sfpro font-medium text-base leading-6 text-gray-700 border-1 border-solid py-2 min-[200px]:px-6 sm:px-6 md:px-10 lg:px-16 xl:px-24 rounded-xl"
              >
                Cancel
              </button>
              <button
                type="submit"
                onSubmit={handleSave}
                className="font-sfpro font-medium text-base leading-5 bg-[#8656A5] text-white border-3 border-gray-300 py-2 min-[200px]:px-6 sm:px-6 md:px-10 lg:px-16 xl:px-24 rounded-xl"
              >
                Save
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateTeacher;
