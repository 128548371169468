import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import LayoutContainer from "../../layout/LayoutContainer";
import { useLiveGamesQuery } from "../../../api/game";
import { useNavigate } from "react-router-dom";

import Loader from "../components/Loader";
import { TbMoodEmpty } from "react-icons/tb";

const LiveGame = () => {
  const navigate = useNavigate();
  const tId = localStorage.getItem("teacherId");

  const [liveGameData, setLiveGameData] = useState([]);
  const [liveGame, setLiveGame] = useState([]);
  const [gameId, setGameId] = useState("");
  const [showStudent, setShowStudent] = useState(false);

  const { data: liveGames, refetch, isFetching } = useLiveGamesQuery(tId);

  useEffect(() => {
    if (showStudent) {
      navigate(`/liveTour/gameId=${gameId}`, {
        state: liveGame,
      });
    }
  }, [showStudent, navigate, gameId, liveGame]);

  useEffect(() => {
    refetch();
    if (liveGames?.data?.status === "success") return;
    if (liveGames) {
      setLiveGameData(liveGames.data);
    } else {
      setLiveGameData([]);
    }
  }, [liveGames, refetch]);

  const allLiveGameData = _.map(liveGameData, (item) => {
    return {
      gameId: _.get(item, "gameId", ""),
      tourName: _.get(item, "tourName", ""),
      tourId: _.get(item, "tourId", ""),
      gameName: _.get(item, "gameName", ""),
      totalStudents: _.get(item, "totalStudents", ""),
    };
  });

  return (
    <>
      <LayoutContainer />
      <div className="max-[639px]:px-2 px-8 max-[330px]:pt-0">
        <div className="h-[32rem] max-[320px]:h-[25rem] max-[390px]:h-[30rem] max-[395px]:h-[36rem] min-[400px]:h-[44rem] sm:h-[26rem] md:h-[35rem] lg:h-[36rem] xl:h-[38rem] overflow-y-auto border-b-2">
          {!_.isEmpty(liveGameData) ? (
            <div className="flex flex-row flex-wrap gap-4">
              {_.map(allLiveGameData, (item) => {
                return (
                  <div
                    key={item.gameId}
                    className="cursor-pointer flex flex-col sm:gap-2 max-[639px]:p-4 sm:p-6 border-2 border-solid rounded-3xl shadow-sm sm:w-[25rem] max-[639px]:w-[15rem]"
                    onClick={() => {
                      setLiveGame(item);
                      setGameId(item.gameId);
                      localStorage.setItem("gameId", item.gameId);
                      setShowStudent(true);
                    }}
                  >
                    <div className="flex flex-row justify-start gap-2">
                      <div className="flex flex-row gap-2">
                        <span className="w-20 font-sfpro text-lg sm:text-xl font-medium text-black">
                          Locations
                        </span>
                        <span className="font-sfpro text-xl font-medium text-black">
                          :
                        </span>
                      </div>

                      <span className="font-sfpro text-base sm:text-lg font-medium text-[#939393]">
                        {item.tourName}
                      </span>
                    </div>
                    <div className="flex flex-row justify-start gap-2">
                      <div className="flex flex-row gap-2">
                        <span className="w-20 font-sfpro text-lg sm:text-xl font-medium text-black">
                          Tour
                        </span>
                        <span className="font-sfpro text-xl font-medium text-black">
                          :
                        </span>
                      </div>
                      <span className="font-sfpro text-base sm:text-lg font-medium text-[#939393]">
                        {item.gameName}
                      </span>
                    </div>
                    <div className="flex flex-row justify-start gap-2">
                      <div className="flex flex-row gap-2">
                        <span className="w-20 font-sfpro text-lg sm:text-xl font-medium text-black">
                          Student
                        </span>
                        <span className="font-sfpro text-xl font-medium text-black">
                          :
                        </span>
                      </div>
                      <span className="font-sfpro text-base sm:text-lg font-medium text-[#939393]">
                        {item.totalStudents}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : !_.isEmpty(liveGameData) || isFetching ? (
            <Loader />
          ) : (
            <div className="py-4 mt-20 flex flex-col justify-center gap-3">
              <span className="flex justify-center">
                <TbMoodEmpty size={100} />
              </span>
              <span className="flex justify-center text-gray-800 font-sfpro text-lg leading-7">
                No live tour data..!
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LiveGame;
