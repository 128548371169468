import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";

export const teacherApi = createApi({
  reducerPath: "teacherApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/teacher`,
    prepareHeaders,
  }),
  tagTypes: ["Teacher"],
  endpoints: (builder) => ({
    getTeacher: builder.query({
      query: () => {
        return {
          url: "/getTeacher",
          method: "GET",
        };
      },
      providesTags: ["Teacher"],
    }),
    createTeacher: builder.mutation({
      query: (body) => {
        return {
          url: "/createTeacher",
          method: "POST",
          body,
        };
      },
    }),
    updateTeacher: builder.mutation({
      query: (payload) => {
        const { formData, id } = payload;

        return {
          url: `/updateTeacher/${id}`,
          method: "PUT",
          body: formData,
        };
      },
    }),
    updatePassword: builder.mutation({
      query: (payload) => {
        const { password, id } = payload;
        return {
          url: `/updatePassword/${id}`,
          method: "PUT",
          body: { password: password },
        };
      },
    }),
    deleteTeacher: builder.mutation({
      query: (tId) => {
        return {
          url: `/deleteTeacher/${tId}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetTeacherQuery,
  useCreateTeacherMutation,
  useUpdateTeacherMutation,
  useDeleteTeacherMutation,
  useUpdatePasswordMutation,
} = teacherApi;
