import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";

export const groupApi = createApi({
  reducerPath: "groupApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/group`,
    prepareHeaders,
  }),
  tagTypes: ["Group"],
  endpoints: (builder) => ({
    getGroup: builder.query({
      query: () => {
        return {
          url: "/getGroup",
          method: "GET",
        };
      },
    }),
    addGroupInGame: builder.mutation({
      query: (body) => {
        return {
          url: "/addGroupInGame",
          method: "POST",
          body,
        };
      },
    }),
    addStudentInGroup: builder.mutation({
      query: (body) => {
        return {
          url: "/addStudentInGroup",
          method: "POST",
          body,
        };
      },
    }),
    createGroup: builder.mutation({
      query: (body) => {
        return {
          url: "/createGroup",
          method: "POST",
          body,
        };
      },
    }),
    updateGroup: builder.mutation({
      query: (payload) => {
        const { values, id } = payload;
        return {
          url: `/updateGroup/${id}`,
          method: "PUT",
          body: values,
        };
      },
    }),
    deleteGroup: builder.mutation({
      query: (grId) => {
        return {
          url: `/deleteGroup/${grId}`,
          method: "DELETE",
        };
      },
    }),
    addStudentsInGame: builder.mutation({
      query: (body) => {
        return {
          url: "/addStudentsInGame",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetGroupQuery,
  useAddGroupInGameMutation,
  useAddStudentInGroupMutation,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useAddStudentsInGameMutation,
} = groupApi;
