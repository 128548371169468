import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { toast } from "react-toastify";
import LayoutContainer from "../../layout/LayoutContainer";
import plusIcon from "../../../assets/svg/plusIcon.svg";
import { useAllGamesQuery, useGameStatusMutation } from "../../../api/game";
import { TbMoodEmpty } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import CreateGame from "../game/CreateGame";

import Loader from "../components/Loader";
import editIcon1 from "../../../assets/svg/editIcon1.svg";
import UpdateGame from "./UpdateGame";
import DeleteModal from "../components/DeleteModal";

const GameTab = () => {
  const navigate = useNavigate();
  const tId = localStorage.getItem("teacherId");

  const [gameId, setGameId] = useState("");
  const [gameTabData, setGameTabData] = useState([]);
  const [games, setGames] = useState([]);
  const [showStudents, setShowStudents] = useState(false);
  const [showLiveStudents, setShowLiveStudents] = useState(false);
  const [showCreateGameModal, setShowCreateGameModal] = useState(false);
  const [showUpdateGameModal, setShowUpdateGameModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [pendingStudentData, setpendingStudentData] = useState([]);
  const [showAlertForCompleteGame, setShowAlertForCompleteGame] =
    useState(false);

  const { data: allGames, refetch, isFetching } = useAllGamesQuery(tId);
  const [gameStatus] = useGameStatusMutation();

  useEffect(() => {
    if (showStudents) {
      navigate(`/studentFromTours/gameId=${gameId}`, { state: games });
    } else if (showLiveStudents) {
      navigate(`/liveTourFromTours/gameId=${gameId}`, { state: games });
    }
  }, [showStudents, showLiveStudents, navigate, gameId, games]);

  useEffect(() => {
    if (allGames?.data?.status === "success") return;
    if (allGames) {
      setGameTabData(allGames.data);
    } else {
      setGameTabData([]);
    }
  }, [allGames, refetch]);

  const handleGameStatus = async (status, gameId) => {
    const body = {
      status: status,
    };
    const data = await gameStatus({ body, gameId });
    if (data?.data?.status === "success") {
      toast("Tour status get successfully", { autoClose: 1000 });
      refetch();
    } else if (data?.data?.status === "fail") {
      const allPendingStudent = _.map(data.data.data, (item) => {
        const firstName = _.get(item, "profileId.firstName", "");
        const lastName = _.get(item, "profileId.lastName", "");
        const studentName =
          firstName.charAt(0).toUpperCase() +
          firstName.slice(1) +
          " " +
          lastName.charAt(0).toUpperCase() +
          lastName.slice(1);
        return studentName;
      });
      setShowAlertForCompleteGame(true);
      setpendingStudentData(allPendingStudent);
    } else if (data?.error?.data?.status === "failed") {
      setShowAlertModal(true);
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  const allGameTabData = _.map(gameTabData, (item) => {
    return {
      gameId: _.get(item, "gameId", ""),
      tourId: _.get(item, "tourId", ""),
      tourName: _.get(item, "tourName", ""),
      date: _.get(item, "date", ""),
      name: _.get(item, "name", ""),
      status: _.get(item, "status", ""),
      teacherFindId: _.get(item, "teacherFindId", ""),
    };
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const totalPages = Math.ceil(allGameTabData.length / itemsPerPage);

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <LayoutContainer />
      {!_.isEmpty(gameTabData) ? (
        <div className="max-[639px]:px-2 px-8 max-[330px]:pt-0">
          <div>
            <span className="text-[#8656A5] cursor-default font-sfpro font-bold text-xl sm:text-2xl border-b-2 pb-1 border-[#8656A5]">
              All tours
            </span>
          </div>

          <div className="h-[32rem] max-[320px]:h-[20rem] max-[390px]:h-[26rem] max-[395px]:h-[33rem] min-[400px]:h-[39rem] sm:h-[26rem] md:h-[32rem] lg:h-[32rem] xl:h-[33rem] overflow-y-auto border-b-2">
            <div className="py-4 flex flex-row flex-wrap gap-4">
              {allGameTabData
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((item) => {
                  return (
                    <div
                      key={item.gameId}
                      className="flex flex-col gap-2 max-[639px]:p-4 sm:p-6 border-2 border-solid rounded-3xl shadow-sm sm:w-[25rem] max-[639px]:w-[15rem]"
                    >
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-col gap-2 pt-1">
                          <span
                            onClick={() => {
                              setGameId(item.gameId);
                              localStorage.setItem("gameId", item.gameId);
                              setGames(item);
                              if (item.status === "waiting for start") {
                                setShowStudents(true);
                              } else if (item.status === "live") {
                                setShowLiveStudents(true);
                              }
                            }}
                            className={`flex items-center text-black font-sfpro font-medium text-xl sm:text-2xl ${
                              item.status === "waiting for start"
                                ? "cursor-pointer"
                                : item.status === "live"
                                ? "cursor-pointer"
                                : "cursor-not-allowed"
                            }`}
                          >
                            {item.name}
                          </span>
                          <span className="text-[#939393] font-sfpro font-medium text-base sm:text-lg">
                            Location name: {item.tourName}
                          </span>
                          <span className="text-[#939393] font-sfpro font-medium text-base sm:text-lg">
                            Status: {item.status}
                          </span>
                        </div>
                        {item.status === "waiting for start" && (
                          <div className="flex justify-center items-start">
                            <img
                              alt="editIcon1"
                              src={editIcon1}
                              className="cursor-pointer"
                              onClick={() => {
                                setShowUpdateGameModal(true);
                                setGames(item);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex justify-between">
                        <div>
                          {item.status !== "completed" ? (
                            <button
                              type="button"
                              className="py-2 px-8 font-sfpro font-medium text-lg sm:text-2xl leading-5 bg-[#8656A5] text-white border-3 border-gray-300 rounded-xl"
                              onClick={() => {
                                setGameId(item.gameId);
                                if (item.status === "waiting for start") {
                                  handleGameStatus("live", item.gameId);
                                } else if (item.status === "live") {
                                  handleGameStatus("completed", item.gameId);
                                }
                              }}
                            >
                              {item.status === "waiting for start"
                                ? "Start"
                                : item.status === "live"
                                ? "Complete"
                                : ""}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="flex flex-row justify-between">
            <div className="flex flex-col sm:flex-row gap-2 justify-end mt-3">
              <button
                className="flex flex-row items-center gap-2 bg-[#8656A5] py-2 px-8 border-3 border-[#AFB7D6] rounded-2xl text-white font-sfpro text-lg leading-7"
                type="button"
                onClick={() => setShowCreateGameModal(true)}
              >
                <img alt="plusIcon" src={plusIcon} />
                Add tour
              </button>
            </div>
            <div className="flex flex-col sm:flex-row justify-end items-center mt-3 gap-3">
              {currentPage > 1 && (
                <button
                  type="button"
                  onClick={goToPreviousPage}
                  className="flex flex-row items-center gap-2 py-2 px-3.5 border-1 border-solid rounded-xl text-gray-700 font-sfpro text-lg leading-7"
                >
                  Previous
                </button>
              )}
              {currentPage < totalPages && (
                <button
                  type="button"
                  onClick={goToNextPage}
                  className="flex flex-row items-center gap-2 py-2 px-3.5 border-1 border-solid rounded-xl text-gray-700 font-sfpro text-lg leading-7"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      ) : !_.isEmpty(gameTabData) || isFetching ? (
        <Loader />
      ) : (
        <div className="py-4 mt-20 flex flex-col justify-center gap-3">
          <span className="flex justify-center">
            <TbMoodEmpty size={100} />
          </span>
          <span className="flex justify-center text-gray-800 font-sfpro text-lg leading-7">
            No tour records..!
          </span>
          <div className="flex flex-col sm:flex-row gap-2 justify-center items-center mt-3">
            <button
              className=" w-52 flex flex-row justify-center items-center gap-2 bg-[#8656A5] py-2 px-8 border-3 border-[#AFB7D6] rounded-2xl text-white font-sfpro text-lg leading-7"
              type="button"
              onClick={() => setShowCreateGameModal(true)}
            >
              <img alt="plusIcon" src={plusIcon} />
              Create tour
            </button>
          </div>
        </div>
      )}
      {showCreateGameModal && (
        <CreateGame
          setShowCreateGameModal={setShowCreateGameModal}
          refetchCreatedGameData={refetch}
        />
      )}
      {showUpdateGameModal && (
        <UpdateGame
          setShowUpdateGameModal={setShowUpdateGameModal}
          refetchUpdatedGameData={refetch}
          refetchDeletedGameData={refetch}
          games={games}
        />
      )}
      {showAlertModal && (
        <DeleteModal
          setShowAlertModal={setShowAlertModal}
          showAlertModal={showAlertModal}
        />
      )}
      {showAlertForCompleteGame && (
        <DeleteModal
          setShowAlertForCompleteGame={setShowAlertForCompleteGame}
          showAlertForCompleteGame={showAlertForCompleteGame}
          pendingStudentData={pendingStudentData}
          gameId={gameId}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default GameTab;
