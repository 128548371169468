import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import "../../../assets/css/pages.css";

import {
  useUpdateTeacherMutation,
  useUpdatePasswordMutation,
} from "../../../api/teacher";

const UpdateTeacher = (props) => {
  const { setShowUpdateTeacherForm, teacherId, refetchUpdatedTeacherData } =
    props;
  const [selectedImage, setSelectedImage] = useState("");
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState();
  const [updateTeacher] = useUpdateTeacherMutation();
  const [updatePassword] = useUpdatePasswordMutation();

  const handlePhoto = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleUpdate = async (values) => {
    const id = values.id;
    const formData = new FormData();
    formData.append("profileImage", selectedImage);
    formData.append("email", values.email);
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    const data = await updateTeacher({ formData, id });
    if (data?.data?.status === "success") {
      toast("Teacher updated successfully", { autoClose: 1000 });
      setShowUpdateTeacherForm(false);
      refetchUpdatedTeacherData();
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  const handleUpdatePassword = async (values) => {
    const id = teacherId.id;
    const password = values.password;
    const data = await updatePassword({ password, id });
    if (data?.data?.status === "success") {
      toast("Password updated successfully", { autoClose: 1000 });
      setShowUpdatePasswordModal(false);
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  const initialValues = {
    firstName: teacherId.firstName,
    lastName: teacherId.lastName,
    email: teacherId.email,
    password: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        const updateData = {
          ...values,
          id: teacherId.id,
          profileImage: selectedImage,
        };
        handleUpdate(updateData);
      }}
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="flex flex-col justify-start items-start max-[639px]:gap-3 sm:gap-10">
            <span className="w-full flex justify-center font-sfpro font-medium text-3xl text-black leading-9 custom_font">
              Update techer details
            </span>

            <div className="w-full grid md:grid-rows-2 sm:gap-2 md:gap-4">
              <div className="flex custome_create_col sm:flex-col md:flex-row justify-between sm:gap-3 md:gap-5">
                <div className="sm:w-full md:w-1/2 flex flex-col gap-3">
                  <span className="h-20 font-sfpro font-medium sm:text-xl text-[#939393] leading-6">
                    First Name
                    <Field
                      type="text"
                      name="firstName"
                      className="font-sfpro font-medium sm:text-xl text-black leading-6 w-full py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                    />
                  </span>
                </div>
                <div className="sm:w-full md:w-1/2 flex flex-col gap-3">
                  <span className="h-20 font-sfpro font-medium sm:text-xl text-[#939393] leading-6">
                    Last Name
                    <Field
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      className="font-sfpro font-medium sm:text-xl text-black leading-6 w-full py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                    />
                  </span>
                </div>
              </div>

              <div className="flex custome_create_col sm:flex-col md:flex-row justify-between sm:gap-3 md:gap-5">
                <div className="sm:w-full md:w-1/2 flex flex-col gap-3">
                  <span className="font-sfpro font-medium sm:text-xl text-[#939393] leading-6">
                    Email
                    <Field
                      type="text"
                      name="email"
                      value={values.email}
                      className="font-sfpro font-medium sm:text-xl text-black leading-6 w-full py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                    />
                  </span>
                </div>
                <div className="mt-4 mb-4 sm:mb-0 sm:w-full md:w-1/2 flex gap-3">
                  <button
                    type="button"
                    onClick={() => setShowUpdatePasswordModal(true)}
                    className="min-[200px]:w-full sm:w-full font-sfpro font-medium text-base leading-5 bg-[#8656A5] text-white border-3 border-gray-300 py-2 min-[200px]:px-6 sm:px-6 md:px-10 lg:px-16 xl:px-24 rounded-xl"
                  >
                    Update Password
                  </button>
                </div>
                {showUpdatePasswordModal && (
                  <div className="fixed inset-0 bg-black/25 z-50 p-8 flex">
                    <div className="bg-white m-auto flex flex-col max-[639px]:gap-2 sm:gap-6 shadow-2xl rounded-md p-4">
                      <div>
                        <span className="font-sfpro font-bold text-xl sm:text-3xl leading-9 text-black">
                          Update password
                        </span>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="font-sfpro font-bold sm:text-xl text-[#828282] leading-6">
                          New password
                        </span>
                        <Field
                          type="password"
                          name="password"
                          className="font-sfpro font-medium sm:text-xl text-black leading-6 w-full py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                        />
                      </div>
                      <div className="flex flex-row gap-3">
                        <button
                          type="button"
                          onClick={() => setShowUpdatePasswordModal(false)}
                          className="font-sfpro font-medium text-base leading-6 text-gray-700 border-1 border-solid py-2 min-[200px]:px-6 sm:px-6 md:px-10 lg:px-16 xl:px-24 rounded-xl"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={() => handleUpdatePassword(values)}
                          className="font-sfpro font-medium text-base leading-5 bg-[#8656A5] text-white border-3 border-gray-300 py-2 min-[200px]:px-6 sm:px-6 md:px-10 lg:px-16 xl:px-24 rounded-xl"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="w-full flex flex-col gap-1">
                <label className="font-sfpro font-medium sm:text-xl text-[#939393] leading-6">
                  Profile picture
                </label>
                <div className="col-span-3 rounded-3xl">
                  <input
                    type="file"
                    accept="image/*"
                    class="input_border"
                    onChange={handlePhoto}
                  />
                </div>
              </div>
            </div>

            <div className="w-full flex justify-center gap-3 items-center">
              <button
                type="button"
                onClick={() => setShowUpdateTeacherForm(false)}
                className="font-sfpro font-medium text-base leading-6 text-gray-700 border-1 border-solid py-2 min-[200px]:px-6 sm:px-6 md:px-10 lg:px-16 xl:px-24 rounded-xl"
              >
                Cancel
              </button>
              <button
                type="submit"
                onSubmit={handleUpdate}
                className="font-sfpro font-medium text-base leading-5 bg-[#8656A5] text-white border-3 border-gray-300 py-2 min-[200px]:px-6 sm:px-6 md:px-10 lg:px-16 xl:px-24 rounded-xl"
              >
                Update
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateTeacher;
