import { Route, Routes } from "react-router-dom";
import Login from "./view/pages/user/Login";
import ForgetPassword from "./view/pages/user/ForgetPassword";
import Verification from "./view/pages/user/Verification";
import ChangePassword from "./view/pages/user/ChangePassword";
import TeacherTab from "./view/pages/teacher/TeacherTab";
import StudentTab from "./view/pages/student/StudentTab";
import TourTab from "./view/pages/tour/TourTab";
import GameTab from "./view/pages/game/GameTab";
import CompletedGames from "./view/pages/history/CompletedGames";
import Recent from "./view/pages/history/Recent";
import AllTours from "./view/pages/history/AllTours";
import StudentHistory from "./view/pages/history/StudentHistory";
import GroupTab from "./view/pages/group/GroupTab";
import LiveGame from "./view/pages/liveGame/LiveGame";
import LiveLocation from "./view/pages/liveGame/LiveLocation";
import Profile from "./view/pages/user/Profile";
import Feedback from "./view/pages/user/Feedback";
import StopsFromTour from "./view/pages/tour/StopsFromTour";
import SelectedStudentTab from "./view/pages/student/SelectedStudent";
import TeacherSingUp from "./view/pages/user/TeacherSingUp";

// function RequireAuth({ children }) {
//   return localStorage.getItem("authorization") ? (
//     children
//   ) : (
//     <Navigate to="/" replace />
//   );
// }

// To remove the cookie when the tab is closed, you can use the `beforeunload` event
// window.addEventListener("unload", () => {
//   localStorage.removeItem("authorization");
//   localStorage.removeItem("userRole");
//   localStorage.removeItem("email");
//   localStorage.removeItem("profileId");
//   localStorage.removeItem("teacherId");
//   localStorage.removeItem("gameId");
//   localStorage.removeItem("groupId");
//   localStorage.removeItem("tourId");
//   localStorage.removeItem("token");
// });

// function RequireAuth({ children }) {
//   const isAuthenticated = localStorage.getItem("authorization");
//   const isAuthenticatedCookie = localStorage.getItem("authorization");
//   if (!isAuthenticated) {
//     localStorage.removeItem("authorization");
//     localStorage.removeItem("userRole");
//     localStorage.removeItem("email");
//     localStorage.removeItem("profileId");
//     localStorage.removeItem("teacherId");
//     localStorage.removeItem("gameId");
//     localStorage.removeItem("groupId");
//     localStorage.removeItem("tourId");
//     localStorage.removeItem("stopId");
//     localStorage.removeItem("token");
//     return <Navigate to="/" replace />;
//   } else if (!isAuthenticatedCookie) {
//     localStorage.removeItem("authorization");
//     return <Navigate to="/" replace />;
//   } else if (isAuthenticated) {
//     return children;
//   }
// }

const RouterWrapper = () => {
  const token = localStorage.getItem("authorization");
  return (
    <Routes>
      <Route index element={token ? <LiveGame /> : <Login />} />
      <Route path="/" element={token ? <LiveGame /> : <Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/teacher-signup" element={<TeacherSingUp />} />

      <Route
        path="/teacher"
        element={
          //<RequireAuth>
          <TeacherTab />
          //</RequireAuth>
        }
      />
      <Route path="/forgetPassword" element={<ForgetPassword />} />
      <Route path="/verification" element={<Verification />} />
      <Route path="/changePassword" element={<ChangePassword />} />
      <Route
        path="/student"
        element={
          //<RequireAuth>
          <StudentTab />
          //</RequireAuth>
        }
      />
      <Route
        path="/locations"
        element={
          //<RequireAuth>
          <TourTab />
          //</RequireAuth>
        }
      />
      <Route
        path="/StopsFromLocation/:tourId"
        element={
          //<RequireAuth>
          <StopsFromTour />
          //</RequireAuth>
        }
      />
      <Route
        path="/groupsFromCreateGame/:gameId"
        element={
          //<RequireAuth>
          <GroupTab />
          //</RequireAuth>
        }
      />
      <Route
        path="/studentFromTourGame/:gameId"
        element={
          //<RequireAuth>
          <SelectedStudentTab />
          //</RequireAuth>
        }
      />
      <Route
        path="/studentFromTourCreateGame/:groupId"
        element={
          //<RequireAuth>
          <SelectedStudentTab />
          //</RequireAuth>
        }
      />
      <Route
        path="/tours"
        element={
          //<RequireAuth>
          <GameTab />
          //</RequireAuth>
        }
      />
      <Route
        path="/studentFromTours/:gameId"
        element={
          //<RequireAuth>
          <SelectedStudentTab />
          //</RequireAuth>
        }
      />
      <Route
        path="/liveTourFromTours/:gameId"
        element={
          //<RequireAuth>
          <LiveLocation />
          //</RequireAuth>
        }
      />
      <Route
        path="/liveTour"
        element={
          //<RequireAuth>
          <LiveGame />
          //</RequireAuth>
        }
      />
      <Route
        path="/recentHistory"
        element={
          //<RequireAuth>
          <Recent />
          //</RequireAuth>
        }
      />
      <Route
        path="/allToursHistory"
        element={
          //<RequireAuth>
          <AllTours />
          //</RequireAuth>
        }
      />
      <Route path="/completedTours/:tourId" element={<CompletedGames />} />
      <Route
        path="/participatedStudents/:gameId"
        element={<StudentHistory />}
      />
      <Route
        path="/groups"
        element={
          //<RequireAuth>
          <GroupTab />
          //</RequireAuth>
        }
      />
      <Route
        path="/groupsFromGame/:gameId"
        element={
          //<RequireAuth>
          <GroupTab />
          //</RequireAuth>
        }
      />
      <Route
        path="/studentFromGroup/:groupId"
        element={
          //<RequireAuth>
          <SelectedStudentTab />
          //</RequireAuth>
        }
      />
      <Route
        path="/liveTour"
        element={
          //<RequireAuth>
          <LiveGame />
          //</RequireAuth>
        }
      />
      <Route
        path="/liveTour/:gameId"
        element={
          //<RequireAuth>
          <LiveLocation />
          //</RequireAuth>
        }
      />
      <Route
        path="/profile"
        element={
          //<RequireAuth>
          <Profile />
          //</RequireAuth>
        }
      />
      <Route
        path="/feedbacks"
        element={
          //<RequireAuth>
          <Feedback />
          //</RequireAuth>
        }
      />
    </Routes>
  );
};

export default RouterWrapper;

{
  /* 
gameId is for tourId
tourId is for locationId
*/
}
