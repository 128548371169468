import React, { useState, useRef, useEffect } from "react";
import Header from "../../layout/Header";
import * as _ from "lodash";

import { toast } from "react-toastify";
import userImg from "../../../assets/svg/userImg.jpeg";
import logoutIcon1 from "../../../assets/svg/logoutIcon1.svg";
import editProfileIcon from "../../../assets/svg/editProfileIcon.svg";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  useUpdateProfileMutation,
  useGetProfileQuery,
} from "../../../api/profile";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setProfile } from "../../../redux/slices/profileSlice";
import DeleteModal from "../components/DeleteModal";

const Profile = () => {
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const fileInputRef = useRef();
  const profileDetail = useSelector((state) => state?.profile?.profileData);

  const { data: profile, refetch } = useGetProfileQuery();
  const [updateProfile] = useUpdateProfileMutation();

  useEffect(() => {
    refetch();
    if (profile?.data?.status === "success") {
      return;
    } else {
      toast.error(profile?.error?.data?.message, { autoClose: 1000 });
    }
    if (profile) {
      dispatch(setProfile(profile?.data));
    }
  }, [profile, profileDetail, refetch, dispatch]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleUpdateProfile = async (values) => {
    const profileId = _.get(profileDetail, "profileId", "");
    const formData = new FormData();
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("profileImage", selectedImage);
    const data = await updateProfile({ formData, profileId });
    if (data?.data?.status === "success") {
      refetch();
      toast("Profile updated successfully", { autoClose: 1000 });
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  const initialValues = {
    firstName: profileDetail?.firstName,
    lastName: profileDetail?.lastName,
    email: profileDetail?.email,
  };

  const onImageError = (e) => {
    e.target.src = userImg;
  };

  return (
    <>
      <div className="w-full">
        <div className="max-[639px]:px-2 sm:px-8 max-[639px]:pt-2 sm:pt-8">
          <Header />
          <div className="max-[639px]:pt-2 sm:pt-8">
            <div className="flex flex-col gap-4">
              <Formik
                initialValues={initialValues}
                onSubmit={handleUpdateProfile}
              >
                {({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="flex md:flex-row justify-between">
                      <div className="flex flex-row gap-3">
                        <div className="max-[639px]:w-40 sm:w-48 max-[639px]:h-40 sm:h-48 rounded-3xl">
                          <img
                            className="rounded-3xl relative"
                            src={
                              selectedImage
                                ? URL.createObjectURL(selectedImage)
                                : profileDetail?.profileImage || userImg
                            }
                            alt="profileImage"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            onError={onImageError}
                          />
                          <div className="absolute top-36 sm:top-52 left-16 sm:left-24 cursor-pointer">
                            <img
                              alt="editProfileIcon"
                              src={editProfileIcon}
                              onClick={() => fileInputRef.current.click()}
                            />
                            <input
                              className="hidden"
                              type="file"
                              accept="image/*"
                              ref={fileInputRef}
                              onChange={handleImageChange}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col justify-center cursor-default">
                          <span className="font-sfpro font-bold text-2xl sm:text-4xl text-[#8656A5]">
                            {profileDetail?.firstName?.charAt(0).toUpperCase() +
                              profileDetail?.firstName?.slice(1) +
                              " " +
                              profileDetail?.lastName?.charAt(0).toUpperCase() +
                              profileDetail?.lastName?.slice(1)}
                          </span>
                          <span className="font-sfpro font-medium text-lg sm:text-2xl text-[#939393]">
                            {profileDetail?.role?.charAt(0).toUpperCase() +
                              profileDetail?.role?.slice(1)}
                          </span>
                          <div className="flex md:hidden items-center">
                            <button
                              type="button"
                              className="flex justify-center gap-2 items-center font-sfpro font-semibold text-[#8656A5] text-base py-2 px-8 border-3 border-gray-300 rounded-2xl"
                              onClick={() => setShowLogoutModal(true)}
                            >
                              <img alt="logoutIcon1" src={logoutIcon1} />
                              Logout
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex max-md:hidden items-center">
                        <button
                          type="button"
                          className="flex justify-center gap-2 items-center font-sfpro font-semibold text-[#8656A5] text-base w-full py-2 px-8 border-3 border-gray-300 rounded-2xl"
                          onClick={() => setShowLogoutModal(true)}
                        >
                          <img alt="logoutIcon1" src={logoutIcon1} />
                          Logout
                        </button>
                      </div>
                    </div>
                    <div className="max-[320px]:hidden max-[639px]:my-2 sm:my-4 w-full bg-[#F9F9F9] max-[639px]:p-2 sm:p-8">
                      <span className="font-sfpro font-semibold text-xl sm:text-3xl">
                        About me
                      </span>
                    </div>
                    <div className="max-[639px]:px-2 sm:px-8 flex flex-col sm:gap-3">
                      <div className="flex max-[639px]:flex-col sm:flex-row sm:gap-40">
                        <div className="flex flex-col w-full">
                          <span className="font-sfpro h-20 font-semibold text-lg sm:text-xl text-[#939393] leading-6">
                            First Name
                            <Field
                              name="firstName"
                              className="mt-2 font-sfpro font-medium text-lg sm:text-xl text-black leading-6 w-full max-[639px]:py-1 sm:py-2 max-[639px]:px-1 sm:px-3 border-1 border-solid border-[#939393]"
                            />
                            <div className="text-red-600 text-sm">
                              <ErrorMessage name="firstName" />
                            </div>
                          </span>
                        </div>
                        <div className="flex flex-col w-full">
                          <span className="font-sfpro h-20 font-semibold text-lg sm:text-xl text-[#939393] leading-6">
                            Last Name
                            <Field
                              name="lastName"
                              className="mt-2 font-sfpro font-medium text-lg sm:text-xl text-black leading-6 w-full max-[639px]:py-1 sm:py-2 max-[639px]:px-1 sm:px-3 border-1 border-solid border-[#939393]"
                            />
                            <div className="text-red-600 text-sm">
                              <ErrorMessage name="lastName" />
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-row gap-40">
                        <div className="flex flex-col w-full">
                          <span className="font-sfpro h-20 font-semibold text-lg sm:text-xl text-[#939393] leading-6">
                            Email
                            <Field
                              disabled={true}
                              name="email"
                              className="cursor-not-allowed mt-2 font-sfpro font-medium text-lg sm:text-xl text-black leading-6 w-full max-[639px]:py-1 sm:py-2 max-[639px]:px-1 sm:px-3 border-1 border-solid border-[#939393]"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="sm:mt-3 flex justify-center">
                        <button
                          type="submit"
                          onSubmit={handleUpdateProfile}
                          className="font-sfpro font-medium text-base bg-[#8656A5] text-white border-3 border-gray-300 py-2 max-[639px]:px-16 sm:px-44 rounded-xl"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {showLogoutModal && (
        <DeleteModal
          setShowLogoutModal={setShowLogoutModal}
          showLogoutModal={showLogoutModal}
        />
      )}
    </>
  );
};

export default Profile;
