import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";
import * as _ from "lodash";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import alertIcon from "../../../assets/svg/alertIcon.svg";
import alertIcon1 from "../../../assets/svg/alertIcon1.svg";
import dotIcon1 from "../../../assets/svg/dotIcon1.svg";
import successIcon from "../../../assets/svg/successIcon.svg";
import successIcon1 from "../../../assets/svg/successIcon1.svg";
import logoutIcon2 from "../../../assets/svg/logoutIcon2.svg";
import "../../../assets/css/pages.css";
import { useDeleteTeacherMutation } from "../../../api/teacher";
import { useDeleteStudentMutation } from "../../../api/student";
import {
  useDeleteGameMutation,
  useForceCompleteMutation,
} from "../../../api/game";
import { useDeleteGroupMutation } from "../../../api/group";
import { useDeleteStopMutation } from "../../../api/stop";
import { useLogoutMutation } from "../../../api/auth";
import { persistor } from "../../../redux/store";

const DeleteModal = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    teacherId,
    studentId,
    gameId,
    tourId,
    refetchDeletedTeacherData,
    refetchDeletedStudentData,
    showDeleteModal,
    setShowUpdateGameModal,
    setShowDeleteModal,
    refetchDeletedGameData,
    refetchDeletedGroupData,
    setShowUpdateGroupForm,
    groupId,
    allInvitationData,
    showInvitationModal,
    setShowInvitationModal,
    refetchDeletedStopData,
    setShowLogoutModal,
    showLogoutModal,
    setShowUpdateStopsForm,
    stopId,
    setShowAlertModal,
    showAlertModal,
    setShowGameDeleteModal,
    showGameDeleteModal,
    setShowAlertForCompleteGame,
    showAlertForCompleteGame,
    pendingStudentData,
    refetch,
  } = props;
  const tId = teacherId;
  const sId = studentId;
  const gId = gameId;
  const grId = groupId;
  const stId = stopId;
  const [deleteTeacher] = useDeleteTeacherMutation();
  const [deleteStudent] = useDeleteStudentMutation();
  const [deleteGame] = useDeleteGameMutation();
  const [forceComplete] = useForceCompleteMutation();
  const [deleteGroup] = useDeleteGroupMutation();
  const [deleteStop] = useDeleteStopMutation();
  const [logout] = useLogoutMutation();
  const student = location.pathname === "/student";
  const teacher = location.pathname === "/teacher";
  const groups =
    location.pathname === "/groups" ||
    /^\/groupsFromCreateGame\/gameId=/.test(location.pathname);
  const stop = location.pathname === `/StopsFromLocation/tourId=${tourId}`;

  const handleLogout = async () => {
    const token = localStorage.getItem("authorization");
    const data = await logout({ authorization: token });
    if (data?.data?.status === 200) {
      navigate("/");
      window.location.reload();
      persistor.purge();
      localStorage.removeItem("authorization");
      localStorage.removeItem("userRole");
      localStorage.removeItem("email");
      localStorage.removeItem("teacherId");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("profileImage");
      localStorage.removeItem("profileId");
      localStorage.removeItem("groupId");
      localStorage.removeItem("tourId");
      localStorage.removeItem("gameId");
    }
  };

  const handleClose = () => {
    if (showDeleteModal) {
      setShowDeleteModal(false);
    } else if (showInvitationModal) {
      setShowInvitationModal(false);
      window.history.back();
    } else if (showLogoutModal) {
      setShowLogoutModal(false);
    } else if (showGameDeleteModal) {
      setShowGameDeleteModal(false);
    } else if (showAlertModal) {
      setShowAlertModal(false);
    } else if (showAlertForCompleteGame) {
      setShowAlertForCompleteGame(false);
    }
  };

  const handleDelete = async (tId, sId, gId, grId, stId) => {
    if (teacher) {
      const data = await deleteTeacher(tId);
      if (data?.data?.status === "success") {
        toast("Teacher deleted successfully", { autoClose: 1000 });
        setShowDeleteModal(false);
        refetchDeletedTeacherData();
      } else {
        toast.error(data?.error?.data?.message, { autoClose: 1000 });
      }
    } else if (student) {
      const data = await deleteStudent(sId);
      if (data?.data?.status === "success") {
        toast("Student deleted successfully", { autoClose: 1000 });
        setShowDeleteModal(false);
        refetchDeletedStudentData();
      } else {
        toast.error(data?.error?.data?.message, { autoClose: 1000 });
      }
    } else if (showGameDeleteModal) {
      const data = await deleteGame(gId);
      if (data?.data?.status === "success") {
        toast("Tour deleted successfully", { autoClose: 1000 });
        setShowGameDeleteModal(false);
        setShowUpdateGameModal(false);
        refetchDeletedGameData();
      } else {
        toast.error(data?.error?.data?.message, { autoClose: 1000 });
      }
    } else if (groups) {
      const data = await deleteGroup(grId);
      if (data?.data?.status === "success") {
        toast("Group deleted successfully", { autoClose: 1000 });
        setShowDeleteModal(false);
        setShowUpdateGroupForm(false);
        refetchDeletedGroupData();
      } else {
        toast.error(data?.error?.data?.message, { autoClose: 1000 });
      }
    } else if (stop) {
      const data = await deleteStop(stId);
      if (data?.data?.status === "success") {
        toast("Stop deleted successfully", { autoClose: 1000 });
        setShowDeleteModal(false);
        setShowUpdateStopsForm(false);
        refetchDeletedStopData();
      } else {
        toast.error(data?.error?.data?.message, { autoClose: 1000 });
      }
    }
  };

  const handleForceStop = async (gameId) => {
    const body = {
      force: true,
    };
    const data = await forceComplete({ body, gameId });
    if (data?.data?.status === "success") {
      toast("Tour status get successfully", { autoClose: 1000 });
      refetch();
      setShowAlertForCompleteGame(false);
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  return (
    <div className="flex mb-4 justify-between">
      <Dialog
        className={`${
          showInvitationModal ? "invitation_modal" : "delete_modal"
        } `}
        open={
          showDeleteModal ||
          showInvitationModal ||
          showLogoutModal ||
          showAlertModal ||
          showGameDeleteModal ||
          showAlertForCompleteGame
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="flex mb-4 justify-start">
            {showInvitationModal ? (
              <img alt="successIcon" src={successIcon} />
            ) : showLogoutModal ? (
              <img alt="logoutIcon2" src={logoutIcon2} />
            ) : showAlertModal ? (
              <img alt="alertIcon1" src={alertIcon1} />
            ) : showGameDeleteModal ? (
              <img alt="alertIcon" src={alertIcon} />
            ) : showAlertForCompleteGame ? (
              <img alt="alertIcon1" src={alertIcon1} />
            ) : (
              <img alt="alertIcon" src={alertIcon} />
            )}
          </div>
          {showInvitationModal ? (
            <div className="flex flex-col gap-1">
              <span className="text-gray-900 text-xl font-bold">
                Invited successfully
              </span>
              <span className="text-gray-500 text-sm fonr-normal">
                Group student now can participate in the location
              </span>
            </div>
          ) : (
            <span className="font-bold font-sfpro text-[#8656A5] text-2xl leading-7">
              {teacher
                ? "Delete teacher detail"
                : student
                ? "Delete student detail"
                : showGameDeleteModal
                ? "Delete tour"
                : groups
                ? "Delete group"
                : stop
                ? "Delete stop"
                : showLogoutModal
                ? "Logout your account"
                : showAlertModal
                ? ""
                : ""}
            </span>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {showInvitationModal ? (
              <div className="flex flex-col gap-3">
                {allInvitationData &&
                  _.map(allInvitationData, (item) => {
                    return (
                      <div
                        key={item.groupId}
                        className="flex flex-row justify-between"
                      >
                        <span className="text-gray-900 text-lg font-semibold">
                          {item.groupName + " " + "(" + item.studentCount + ")"}
                        </span>
                        <img alt="successIcon1" src={successIcon1} />
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="flex flex-col gap-3">
                <span
                  className={`font-normal font-sfpro ${
                    showAlertModal || showAlertForCompleteGame
                      ? "text-black"
                      : "text-gray-500"
                  } text-xl leading-5`}
                >
                  {teacher
                    ? "Are you sure you want to delete this teacher ?"
                    : student
                    ? "Are you sure you want to delete this student ?"
                    : showGameDeleteModal
                    ? "Are you sure you want to delete this tour ?"
                    : groups
                    ? "Are you sure you want to delete this group ?"
                    : stop
                    ? "Are you sure you want to delete this stop ?"
                    : showLogoutModal
                    ? "Are you sure you want to logout? "
                    : showAlertModal
                    ? "Please add some students before starting the game!"
                    : setShowAlertForCompleteGame
                    ? "Kindly request these students to finish the assignment."
                    : ""}
                </span>
                {showAlertForCompleteGame && (
                  <div className="flex flex-col gap-2">
                    {pendingStudentData &&
                      _.map(pendingStudentData, (item, index) => {
                        return (
                          <div key={index} className="flex flex-row gap-2">
                            <span>
                              <img alt="dotIcon1" src={dotIcon1} />
                            </span>
                            <span className="font-normal font-sfpro text-gray-500 text-lg">
                              {item}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <div className="flex gap-2 w-full py-3 px-4 justify-center">
          {showInvitationModal ? (
            <button
              className="py-2 px-8 w-full font-sfpro font-medium text-base leading-6 bg-[#8656A5] text-white border-3 border-gray-300 rounded-xl"
              type="button"
              onClick={handleClose}
            >
              Done
            </button>
          ) : showAlertModal ? (
            <button
              className="py-2 px-8 w-1/2 justify-center font-sfpro font-medium text-base leading-6 text-gray-700 border-1 border-solid rounded-3xl"
              type="button"
              onClick={handleClose}
            >
              Cancel
            </button>
          ) : (
            <>
              <button
                className="py-2 px-8 w-1/2 font-sfpro font-medium text-base leading-6 text-gray-700 border-1 border-solid rounded-3xl"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="py-2 px-8 w-1/2 font-sfpro font-medium text-base leading-6 bg-[#8656A5] text-white border-3 border-gray-300 rounded-3xl"
                type="button"
                onClick={() => {
                  if (showDeleteModal || showGameDeleteModal) {
                    handleDelete(tId, sId, gId, grId, stId);
                  } else if (showLogoutModal) {
                    handleLogout();
                  } else if (showAlertForCompleteGame) {
                    handleForceStop(gameId);
                  }
                }}
              >
                {showDeleteModal || showGameDeleteModal
                  ? "Delete"
                  : showAlertForCompleteGame
                  ? "Force complete"
                  : showLogoutModal && "Logout"}
              </button>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteModal;
