import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import {
  useGetStudentQuery,
  useSelectedStudentInGameMutation,
  useSelectedStudentInGroupMutation,
} from "../../../api/student";
import LayoutContainer from "../../layout/LayoutContainer";
import { toast } from "react-toastify";
import laptop from "../../../assets/svg/laptop.svg";
import plusIcon from "../../../assets/svg/plusIcon.svg";
import userImg from "../../../assets/svg/userImg.jpeg";
import leftSideArrow from "../../../assets/svg/leftSideArrow.svg";
import {
  useAddStudentInGroupMutation,
  useAddStudentsInGameMutation,
} from "../../../api/group";

import Loader from "../components/Loader";
import searchIcon1 from "../../../assets/svg/searchIcon1.svg";

const SelectedStudentTab = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const studentSearchText = searchParams.get("search");
  const role = localStorage.getItem("userRole");
  const groupName = location.state?.name;
  const groupId = location.state?.groupId;
  const gameId = location.state?.gameId;
  const groupPath = /^\/studentFromGroup\/groupId=/.test(location.pathname);
  const createGamePath = /^\/studentFromTourCreateGame\/groupId=/.test(
    location.pathname
  );
  const gamePath = /^\/studentFromTours\/gameId=/.test(location.pathname);
  const gameName = location.state?.name;

  const [studentData, setStudentData] = useState([]);
  const [selectedStudentData, setSelectedStudentData] = useState([]);
  const [isTrue, setIsTrue] = useState(false);
  const [checked, setChecked] = useState([]);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);

  const { data, refetch, isFetching } = useGetStudentQuery(
    gameId ? gameId : ""
  );
  const [addStudentGroup] = useAddStudentInGroupMutation();
  const [addStudentGame] = useAddStudentsInGameMutation();
  const [selectedStudentInGame] = useSelectedStudentInGameMutation();
  const [selectedStudentInGroup] = useSelectedStudentInGroupMutation();

  const handleCheckbox = (studId) => {
    setSelectedStudentIds((selectedStudentIds) => {
      return selectedStudentIds.includes(studId)
        ? selectedStudentIds.filter((id) => id !== studId)
        : [...selectedStudentIds, studId];
    });
  };

  const handleChecked = (studentId, isChecked) => {
    setChecked((checked) => ({
      ...checked,
      [studentId]: isChecked,
    }));
  };

  useEffect(() => {
    refetch();
    if (data?.data?.status === "success") {
      return;
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
    if (data) {
      if (role === "admin") {
        const filterdStudentData = studentSearchText
          ? _.filter(studentData, (student) => {
              const fullName =
                `${student.profile.firstName} ${student.profile.lastName}`.toLowerCase();
              const searchText = studentSearchText.toLowerCase();
              const firstNameMatch = student.profile.firstName
                .toLowerCase()
                .includes(searchText);
              const lastNameMatch = student.profile.lastName
                .toLowerCase()
                .includes(searchText);
              const fullNameMatch = fullName.includes(searchText);
              return firstNameMatch || lastNameMatch || fullNameMatch;
            })
          : data.data;
        if (filterdStudentData <= 0) {
          setIsTrue(true);
        } else {
          setStudentData(filterdStudentData);
          setIsTrue(false);
        }
      } else if (role === "teacher") {
        const filterdStudentData = studentSearchText
          ? _.filter(studentData, (student) => {
              const fullName =
                `${student.profile.firstName} ${student.profile.lastName}`.toLowerCase();
              const searchText = studentSearchText.toLowerCase();
              const firstNameMatch = student.profile.firstName
                .toLowerCase()
                .includes(searchText);
              const lastNameMatch = student.profile.lastName
                .toLowerCase()
                .includes(searchText);
              const fullNameMatch = fullName.includes(searchText);
              return firstNameMatch || lastNameMatch || fullNameMatch;
            })
          : data?.teacherSideData;
        if (filterdStudentData <= 0) {
          setIsTrue(true);
        } else {
          setStudentData(filterdStudentData);
          setIsTrue(false);
        }
      }
    } else {
      setStudentData([]);
    }
    if (gamePath || createGamePath || groupPath || createGamePath) {
      handleSelectedStudent();
      newFun();
    }
  }, [
    data,
    refetch,
    role,
    studentSearchText,
    studentData,
    selectedStudentInGame,
    selectedStudentInGroup,
  ]);

  const handleInvitation = async () => {
    if (groupPath || createGamePath) {
      const studentGroupData = {
        groupId: groupId,
        students: selectedStudentIds.map((id) => ({
          studentId: id,
          isAdded: checked[id] || false,
        })),
      };
      const data = await addStudentGroup(studentGroupData);
      if (data?.data?.status === "success") {
        toast("Students added to the group successfully", { autoClose: 1000 });
      } else {
        toast.error(data?.error?.data?.message, { autoClose: 1000 });
      }
    } else if (gamePath) {
      const studentGameData = {
        gameId: gameId,
        students: selectedStudentIds.map((id) => ({
          studentId: id,
          isAdded: checked[id] || false,
        })),
      };
      const data = await addStudentGame(studentGameData);
      if (data?.data?.status === "success") {
        toast("Students added to the tour successfully", { autoClose: 1000 });
        refetch();
      } else {
        toast.error(data?.error?.data?.message, { autoClose: 1000 });
      }
    }
  };

  const handleSelectedStudent = async () => {
    if (gamePath) {
      const studentGameId = {
        gameId: gameId,
      };
      const data = await selectedStudentInGame(studentGameId);
      if (data.data?.status === "success") {
        setSelectedStudentData(data?.data?.data);
      }
    } else if (groupPath || createGamePath) {
      const studentGroupId = {
        groupId: groupId,
      };
      const data = await selectedStudentInGroup(studentGroupId);
      if (data.data?.status === "success") {
        setSelectedStudentData(data?.data?.data);
      }
    }
  };

  const newFun = (item) => {
    let isMatched;
    for (let index = 0; index < selectedStudentData.length; index++) {
      const element = selectedStudentData[index];
      if (element?.isSelected === true) {
        if (item?.studentId === element?.studentId) {
          isMatched = true;
          break;
        }
      }
    }
    return isMatched;
  };

  const onImageError = (e) => {
    e.target.src = userImg;
  };

  const rows =
    studentData &&
    _.map(studentData, (item) => {
      const firstName = _.get(item, "profile.firstName", "");
      const lastName = _.get(item, "profile.lastName", "");
      return {
        id: _.get(item, "studentId", ""),
        firstName: _.get(item, "profile.firstName", ""),
        lastName: _.get(item, "profile.lastName", ""),
        profileImage: _.get(item, "profile.profileImage", ""),
        studentName:
          firstName.charAt(0).toUpperCase() +
          firstName.slice(1) +
          " " +
          lastName.charAt(0).toUpperCase() +
          lastName.slice(1),
        email: _.get(item, "email", ""),
        age: _.get(item, "profile.age", ""),
        code: _.get(item, "code.code", ""),
        class: _.get(item, "class", ""),
        teacherId: _.get(item, "teacherId", ""),
        isSelected: newFun(item),
      };
    });

  const columns = [
    {
      accessorKey: "select",
      header: "Select",
      size: 80,
      enableColumnActions: false,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Select
        </span>
      ),
      Cell: (params) => {
        return (
          <div className="flex justify-center items-center w-full">
            <input
              type="checkbox"
              defaultChecked={params.row.original.isSelected}
              onChange={(e) => {
                handleChecked(params.row.original.id, e.target.checked);
                handleCheckbox(params.row.original.id);
              }}
            />
          </div>
        );
      },
    },
    {
      accessorKey: "profileImage",
      header: "Profile",
      size: 80,
      enableColumnActions: false,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Profile
        </span>
      ),
      Cell: (params) => {
        return (
          <div className="flex justify-center items-center w-full">
            <div className="w-12 h-12 rounded-full p-1">
              <img
                className="rounded-full"
                alt="profileImage"
                src={params.renderedCellValue || userImg}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                onError={onImageError}
              />
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "studentName",
      header: "Student Name",
      enableColumnActions: false,
      enableSorting: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Student Name
        </span>
      ),
      Cell: (params) => {
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {params.row.original.studentName}
          </span>
        );
      },
    },
    {
      accessorKey: "age",
      header: "Age",
      enableColumnActions: false,
      enableSorting: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Age
        </span>
      ),
      Cell: (params) => {
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {params.row.original.age}
          </span>
        );
      },
    },
    {
      accessorKey: "class",
      header: "Year",
      enableColumnActions: false,
      enableSorting: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Year
        </span>
      ),
      Cell: (params) => {
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {params.row.original.class}
          </span>
        );
      },
    },
    {
      accessorKey: "code",
      header: "Code",
      enableColumnActions: false,
      enableSorting: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Code
        </span>
      ),
      Cell: (params) => {
        const code = params.row.original.code;
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {!_.isEmpty(code) ? params.row.original.code : "Pending Invitation"}
          </span>
        );
      },
    },
    {
      accessorKey: "email",
      header: "Email",
      enableColumnActions: false,
      enableSorting: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Email
        </span>
      ),
      Cell: (params) => {
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {params.row.original.email}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <LayoutContainer />
      <div className="max-[639px]:px-2 sm:px-8 pb-2 sm:pb-8 max-[330px]:pt-0 grid_border">
        {isTrue && !_.isEmpty(studentData) ? (
          <div className="mt-20 flex flex-col justify-center items-center m-32 gap-8">
            <span>
              <img alt="searchIcon1" src={searchIcon1} />
            </span>
            <span className="font-sfpro text-center text-4xl font-medium leading-6">
              Search not found
            </span>
            <span className="font-sfpro text-center text-3xl font-normal leading-5 text-gray-500">
              Your search “String” did not match any student. Please try again.
            </span>
          </div>
        ) : !_.isEmpty(studentData) ? (
          <div className="flex flex-col gap-2 sm:gap-8">
            {groupPath || gamePath || createGamePath ? (
              <div className="flex gap-3 justify-start">
                <div
                  onClick={() => {
                    if (groupPath) {
                      navigate("/groups");
                    } else if (gamePath) {
                      navigate("/tours");
                    } else if (createGamePath) {
                      window.history.back();
                    }
                  }}
                  className="flex flex-row gap-3 cursor-pointer"
                >
                  <img alt="leftSideArrow" src={leftSideArrow} />
                  <span className="text-[#8656A5] font-sfpro font-bold text-2xl border-[#8656A5]">
                    {groupPath || createGamePath
                      ? groupName
                      : gamePath
                      ? gameName
                      : ""}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}

            <div
              className="h-[32rem] md:h-[32rem] lg:h-[32rem] border-b-2 overflow-y-auto overflow-x-hidden
                  max-[320px]:h-[18rem] max-[373px]:h-[28rem] max-[390px]:h-[26rem] min-[391px]:h-[33rem] min-[400px]:h-[38rem] sm:h-[26rem] xl:h-[34rem]"
            >
              <MaterialReactTable
                columns={columns}
                data={rows}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                initialState={{
                  columnVisibility: groupPath && { code: false },
                }}
              />
            </div>

            <div className="flex sm:flex-col md:flex-row gap-3 w-full sm:justify-end items-end">
              <button
                className="flex justify-center px-12 gap-2 items-center uppercase font-sfpro font-medium text-base leading-5 bg-[#8656A5] text-white py-2 border-3 border-gray-300 rounded-2xl"
                onClick={handleInvitation}
              >
                <img alt="plusIcon" src={plusIcon} />
                {groupPath || createGamePath
                  ? "Add to group"
                  : gamePath && "Add to game"}
              </button>
            </div>
          </div>
        ) : !_.isEmpty(studentData) || isFetching ? (
          <Loader />
        ) : (
          _.isEmpty(studentData) && (
            <div className="mt-20 flex flex-col justify-center items-center md:gap-5 sm:gap-4 min-[260px]:gap-3">
              <div className="flex justify-center">
                <img alt="laptop" src={laptop} className="responsive_img" />
              </div>
              <div className="flex flex-col justify-center items-center md:gap-4 sm:gap-2">
                <span className="font-sfpro font-medium md:text-4xl sm:text-3xl text-gray-900 leading-6">
                  Let’s create student from student list
                </span>
                <span className="font-sfpro font-normal md:text-3xl sm:text-2xl text-gray-500 leading-5">
                  Your student data not found.
                </span>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default SelectedStudentTab;
