import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";

export const stopApi = createApi({
  reducerPath: "stopApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/stop`,
    prepareHeaders,
  }),
  tagTypes: ["Stop"],
  endpoints: (builder) => ({
    getStop: builder.query({
      query: (payload) => {
        const { tourId, teacherId } = payload;
        const role = localStorage.getItem("userRole");
        return {
          url: `/getStop/${tourId}${
            role === "teacher" ? `?teacherId=${teacherId}` : ""
          }`,
          method: "GET",
        };
      },
    }),
    createStop: builder.mutation({
      query: (body) => {
        return {
          url: "/createStop",
          method: "POST",
          body,
        };
      },
    }),
    updateStop: builder.mutation({
      query: (payload) => {
        const { values, stopId } = payload;
        return {
          url: `/updateStop/${stopId}`,
          method: "PUT",
          body: values,
        };
      },
    }),
    deleteStop: builder.mutation({
      query: (stId) => {
        return {
          url: `/deleteStop/${stId}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetStopQuery,
  useCreateStopMutation,
  useUpdateStopMutation,
  useDeleteStopMutation,
} = stopApi;
