import React, { useState } from "react";
import * as _ from "lodash";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import changePasswordImage from "../../../assets/svg/changePasswordImage.svg";
import passowrdIcon from "../../../assets/svg/passwordIcon.svg";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { useResetPasswordMutation } from "../../../api/auth";

const ChangePassword = () => {
  const email = useLocation();
  const navigate = useNavigate();
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const [resetPassword] = useResetPasswordMutation();

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible((prevVisible) => !prevVisible);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible((prevVisible) => !prevVisible);
  };

  const handleError = (errors) => {
    if (!_.isEmpty(errors)) {
      setError(errors.confirmPassword);
    } else {
      setError("");
    }
  };

  const handleResetPassword = async (values) => {
    const data = await resetPassword(values);
    if (data?.data?.status === "Success") {
      toast("Reset password successful", { autoClose: 1000 });
      localStorage.setItem("token", data?.data?.token);
      navigate("/", { state: values });
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Password must match"
    ),
  });

  return (
    <>
      <div className="container">
        <div className="w-full row items-center justify-center h-screen">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-none d-lg-block">
            <div className="mr-16">
              <img alt="loginImage" src={changePasswordImage} />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <span className="text-[#8656A5] font-sfpro font-bold text-3xl">
              Create new password
            </span>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(value) => {
                const code = {
                  ...value,
                  email: email.state.email,
                };
                handleResetPassword(code);
              }}
            >
              {({ handleSubmit, errors }) => (
                <>
                  <Form onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-3 mt-5">
                      <div className="h-24">
                        <label className="w-full text-start font-sfpro font-bold text-base text-[#939393]">
                          New passoword
                          <div className="relative mt-2">
                            <img
                              alt="password"
                              className="absolute z-10 top-3 left-5"
                              src={passowrdIcon}
                            />
                            <Field
                              name="password"
                              type={newPasswordVisible ? "text" : "password"}
                              className="w-full relative font-semibold font-sfpro text-[#8656A5] text-base border-2 rounded-full border-[#8656A5] pl-12 pr-2 py-2"
                            />
                            <button
                              type="button"
                              className="absolute z-10 top-3 right-5"
                              onClick={toggleNewPasswordVisibility}
                            >
                              {newPasswordVisible ? (
                                <BsEye size={22} />
                              ) : (
                                <BsEyeSlash size={22} />
                              )}
                            </button>
                          </div>
                          <div className="text-red-600 text-sm">
                            <ErrorMessage name="password" />
                          </div>
                        </label>
                      </div>
                      <div className="h-24">
                        <label className="w-full text-start font-sfpro font-bold text-base text-[#939393]">
                          Confirm new passowrd
                          <div className="relative mt-2">
                            <img
                              alt="password"
                              className="absolute z-10 top-3 left-5"
                              src={passowrdIcon}
                            />
                            <Field
                              name="confirmPassword"
                              type={
                                confirmPasswordVisible ? "text" : "password"
                              }
                              errors={handleError(errors)}
                              className="w-full relative font-semibold font-sfpro text-[#8656A5] text-base border-2 rounded-full border-[#8656A5] pl-12 pr-2 py-2"
                            />
                            <button
                              type="button"
                              className="absolute z-10 top-3 right-5"
                              onClick={toggleConfirmPasswordVisibility}
                            >
                              {confirmPasswordVisible ? (
                                <BsEye size={22} />
                              ) : (
                                <BsEyeSlash size={22} />
                              )}
                            </button>
                          </div>
                          {error && (
                            <div className="text-sm text-red-600">{error}</div>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="flex items-center justify-center mt-20">
                      <button
                        type="submit"
                        onSubmit={handleResetPassword}
                        className="flex justify-center gap-4 items-center uppercase font-sfpro bg-[#8656A5] text-white w-[30rem] py-2 border-3 border-gray-300 rounded-xl"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
