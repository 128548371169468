import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";

export const feedbackApi = createApi({
  reducerPath: "feedbackApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/feedback`,
    prepareHeaders,
  }),
  tagTypes: ["Feedback"],
  endpoints: (builder) => ({
    getFeedback: builder.query({
      query: () => {
        return {
          url: "/getFeedback",
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetFeedbackQuery } = feedbackApi;
