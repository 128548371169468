import React from "react";
import Header from "./Header";
import NavbarMenu from "./NavbarMenu";

const LayoutContainer = () => {
  return (
    <>
      <div className="w-full">
        <div className="max-[639px]:px-2 sm:px-8 max-[639px]:pt-2 sm:pt-8">
          <Header />
          <NavbarMenu />
        </div>
      </div>
    </>
  );
};

export default LayoutContainer;
