import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/profile`,
    prepareHeaders,
  }),
  tagTypes: ["Profile"],
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => {
        const profileId = localStorage.getItem("profileId");
        return {
          url: `/getProfile/${profileId}`,
          method: "GET",
        };
      },
    }),
    updateProfile: builder.mutation({
      query: (payload) => {
        const { profileId, formData } = payload;
        return {
          url: `/updateProfile/${profileId}`,
          method: "PUT",
          body: formData,
        };
      },
    }),
  }),
});

export const { useUpdateProfileMutation, useGetProfileQuery } = profileApi;
