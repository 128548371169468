import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import { useGetStudentQuery } from "../../../api/student";
import LayoutContainer from "../../layout/LayoutContainer";
import { toast } from "react-toastify";
import laptop from "../../../assets/svg/laptop.svg";
import plusIcon from "../../../assets/svg/plusIcon.svg";
import editIcon from "../../../assets/svg/editIcon.svg";
import deleteIcon from "../../../assets/svg/deleteIcon.svg";
import userImg from "../../../assets/svg/userImg.jpeg";
import Modal from "../../pages/components/Modal";
import Csv from "../components/Csv";
import CreateStudent from "./CreateStudent";
import UpdateStudent from "./UpdateStudent";
import DeleteModal from "../components/DeleteModal";
import Loader from "../components/Loader";
import searchIcon1 from "../../../assets/svg/searchIcon1.svg";

const StudentTab = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const studentSearchText = searchParams.get("search");
  const role = localStorage.getItem("userRole");

  const [studentId, setStudentId] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [showCsvModal, setShowCsvModal] = useState(false);
  const [showCreateStudentForm, setShowCreateStudentForm] = useState(false);
  const [showUpdateStudentForm, setShowUpdateStudentForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isTrue, setIsTrue] = useState(false);

  const { data, refetch, isFetching } = useGetStudentQuery();

  useEffect(() => {
    refetch();
    if (data?.data?.status === "success") {
      return;
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
    if (data) {
      if (role === "admin") {
        const filterdStudentData = studentSearchText
          ? _.filter(studentData, (student) => {
              const fullName =
                `${student.profile.firstName} ${student.profile.lastName}`.toLowerCase();
              const searchText = studentSearchText.toLowerCase();
              const firstNameMatch = student.profile.firstName
                .toLowerCase()
                .includes(searchText);
              const lastNameMatch = student.profile.lastName
                .toLowerCase()
                .includes(searchText);
              const fullNameMatch = fullName.includes(searchText);
              return firstNameMatch || lastNameMatch || fullNameMatch;
            })
          : data.data;
        if (filterdStudentData <= 0) {
          setIsTrue(true);
        } else {
          setStudentData(filterdStudentData);
          setIsTrue(false);
        }
      } else if (role === "teacher") {
        const filterdStudentData = studentSearchText
          ? _.filter(studentData, (student) => {
              const fullName =
                `${student.profile.firstName} ${student.profile.lastName}`.toLowerCase();
              const searchText = studentSearchText.toLowerCase();
              const firstNameMatch = student.profile.firstName
                .toLowerCase()
                .includes(searchText);
              const lastNameMatch = student.profile.lastName
                .toLowerCase()
                .includes(searchText);
              const fullNameMatch = fullName.includes(searchText);
              return firstNameMatch || lastNameMatch || fullNameMatch;
            })
          : data?.teacherSideData;
        if (filterdStudentData <= 0) {
          setIsTrue(true);
        } else {
          setStudentData(filterdStudentData);
          setIsTrue(false);
        }
      }
    } else {
      setStudentData([]);
    }
  }, [data, refetch, role, studentSearchText, studentData]);

  const onImageError = (e) => {
    e.target.src = userImg;
  };

  const rows =
    studentData &&
    _.map(studentData, (item) => {
      const firstName = _.get(item, "profile.firstName", "");
      const lastName = _.get(item, "profile.lastName", "");
      return {
        id: _.get(item, "studentId", ""),
        firstName: _.get(item, "profile.firstName", ""),
        lastName: _.get(item, "profile.lastName", ""),
        profileImage: _.get(item, "profile.profileImage", ""),
        studentName:
          firstName.charAt(0).toUpperCase() +
          firstName.slice(1) +
          " " +
          lastName.charAt(0).toUpperCase() +
          lastName.slice(1),
        email: _.get(item, "email", ""),
        age: _.get(item, "profile.age", ""),
        class: _.get(item, "class", ""),
        teacherId: _.get(item, "teacherId", ""),
        action: "action",
      };
    });

  const columns = [
    {
      accessorKey: "profileImage",
      header: "Profile",
      size: 80,
      enableColumnActions: false,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Profile
        </span>
      ),
      Cell: (params) => {
        return (
          <div className="flex justify-center items-center w-full">
            <div className="w-12 h-12 rounded-full p-1">
              <img
                className="rounded-full"
                alt="profileImage"
                src={params.renderedCellValue || userImg}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                onError={onImageError}
              />
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "studentName",
      header: "Student Name",
      enableColumnActions: false,
      enableSorting: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Student Name
        </span>
      ),
      Cell: (params) => {
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {params.row.original.studentName}
          </span>
        );
      },
    },
    {
      accessorKey: "age",
      header: "Age",
      enableColumnActions: false,
      enableSorting: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Age
        </span>
      ),
      Cell: (params) => {
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {params.row.original.age}
          </span>
        );
      },
    },
    {
      accessorKey: "class",
      header: "Year",
      enableColumnActions: false,
      enableSorting: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Year
        </span>
      ),
      Cell: (params) => {
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {params.row.original.class}
          </span>
        );
      },
    },
    {
      accessorKey: "email",
      header: "Email",
      enableColumnActions: false,
      enableSorting: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Email
        </span>
      ),
      Cell: (params) => {
        return (
          <span className="MuiDataGrid-cellContent font-sfpro font-normal text-2xl text-neutral-800 leading-5">
            {params.row.original.email}
          </span>
        );
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 50,
      muiTableHeadCellProps: { align: "center" },
      enableColumnActions: false,
      enableSorting: false,
      Header: () => (
        <span className="font-sfpro font-bold text-lg sm:text-xl text-[#8656A5] leading-6">
          Action
        </span>
      ),
      Cell: (params) => {
        return (
          <div className="justify-center items-center flex flex-row gap-3 w-full">
            <img
              alt="editIcon"
              src={editIcon}
              className="cursor-pointer"
              onClick={() => {
                setShowUpdateStudentForm(true);
                setStudentId(params.row.original);
              }}
            />
            <img
              alt="deleteIcon"
              src={deleteIcon}
              className="cursor-pointer"
              onClick={() => {
                setShowDeleteModal(true);
                setStudentId(params.row.original.id);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <LayoutContainer />
      <div className="max-[639px]:px-2 sm:px-8 pb-2 sm:pb-8 max-[330px]:pt-0 grid_border">
        {isTrue && !_.isEmpty(studentData) ? (
          <div className="mt-20 flex flex-col justify-center items-center m-32 gap-8">
            <span>
              <img alt="searchIcon1" src={searchIcon1} />
            </span>
            <span className="font-sfpro text-center text-4xl font-medium leading-6">
              Search not found
            </span>
            <span className="font-sfpro text-center text-3xl font-normal leading-5 text-gray-500">
              Your search “String” did not match any student. Please try again.
            </span>
          </div>
        ) : !_.isEmpty(studentData) ? (
          <div className="flex flex-col gap-2 sm:gap-8">
            <div
              className="h-[32rem] md:h-[32rem] lg:h-[32rem] border-b-2 overflow-y-auto overflow-x-hidden
                max-[320px]:h-[22rem] max-[375px]:h-[28rem] max-[390px]:h-[28rem] min-[391px]:h-[35rem] min-[400px]:h-[40rem] sm:h-[28rem] xl:h-[35rem]
              "
            >
              <MaterialReactTable
                columns={columns}
                data={rows}
                enableTopToolbar={false}
                enableBottomToolbar={false}
              />
            </div>

            <div className="flex sm:flex-col md:flex-row gap-3 w-full sm:justify-end items-end">
              <>
                <button
                  className="flex justify-center lg:w-1/5 md:w-1/4 sm:w-1/2 w-1/2 gap-2 items-center uppercase font-sfpro font-medium text-sm sm:text-base leading-5 bg-[#8656A5] text-white py-2 border-3 border-gray-300 rounded-xl"
                  onClick={() => setShowCreateStudentForm(true)}
                >
                  <img alt="plusIcon" src={plusIcon} />
                  Add student
                </button>
                <button
                  className="flex justify-center lg:w-1/5 md:w-1/4 sm:w-1/2 w-1/2 gap-2 items-center uppercase font-sfpro font-medium text-sm sm:text-base leading-5 bg-[#8656A5] text-white py-2 border-3 border-gray-300 rounded-xl"
                  onClick={() => setShowCsvModal(true)}
                >
                  <img alt="plusIcon" src={plusIcon} />
                  Import
                </button>
              </>
            </div>
          </div>
        ) : !_.isEmpty(studentData) || isFetching ? (
          <Loader />
        ) : (
          _.isEmpty(studentData) && (
            <div className="mt-20 flex flex-col justify-center items-center md:gap-5 sm:gap-4 min-[260px]:gap-3">
              <div className="flex justify-center">
                <img alt="laptop" src={laptop} className="responsive_img" />
              </div>
              <div className="flex flex-col justify-center items-center md:gap-4 sm:gap-2">
                <span className="font-sfpro font-medium md:text-4xl sm:text-3xl text-gray-900 leading-6">
                  Let’s create student
                </span>
                <span className="font-sfpro font-normal md:text-3xl sm:text-2xl text-gray-500 leading-5">
                  Your student data not found.
                </span>
              </div>
              <div className="flex flex-col  justify-center items-center md:gap-4 sm:gap-2">
                <button
                  className="flex justify-center gap-2 items-center uppercase font-sfpro font-medium md:text-sm sm:text-xs min-[300px]:text-xs leading-5 bg-[#8656A5] text-white w-full py-2 px-4 border-3 border-gray-300 rounded-lg"
                  onClick={() => setShowCreateStudentForm(true)}
                >
                  <img alt="plusIcon" src={plusIcon} />
                  Add student
                </button>
                <span className="uppercase">or</span>
                <button
                  className="flex justify-center gap-2 items-center uppercase font-sfpro font-medium md:text-sm sm:text-xs min-[300px]:text-xs leading-5 bg-[#8656A5] text-white w-full py-2 px-4 border-3 border-gray-300 rounded-lg"
                  onClick={() => setShowCsvModal(true)}
                >
                  <img alt="plusIcon" src={plusIcon} />
                  Import
                </button>
              </div>
            </div>
          )
        )}
      </div>
      {showCreateStudentForm && (
        <Modal>
          {showCreateStudentForm && (
            <>
              <CreateStudent
                setShowCreateStudentForm={setShowCreateStudentForm}
                refetchCreatedStudentData={refetch}
              />
            </>
          )}
        </Modal>
      )}
      {showUpdateStudentForm && (
        <Modal>
          {showUpdateStudentForm && (
            <>
              <UpdateStudent
                setShowUpdateStudentForm={setShowUpdateStudentForm}
                studentId={studentId}
                refetchUpdatedStudentData={refetch}
              />
            </>
          )}
        </Modal>
      )}
      {showDeleteModal && (
        <DeleteModal
          setShowDeleteModal={setShowDeleteModal}
          showDeleteModal={showDeleteModal}
          studentId={studentId}
          refetchDeletedStudentData={refetch}
        />
      )}
      {showCsvModal && (
        <Csv setShowCsvModal={setShowCsvModal} refetchStudentCsv={refetch} />
      )}
    </>
  );
};

export default StudentTab;
