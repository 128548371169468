import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import * as _ from "lodash";
import * as Yup from "yup";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { useUpdateGameMutation } from "../../../api/game";
import { useGetTeacherQuery } from "../../../api/teacher";
import closeIcon from "../../../assets/svg/closeIcon.svg";
import calendarIcon from "../../../assets/svg/calendarIcon.svg";
import DeleteModal from "../components/DeleteModal";
import userImg from "../../../assets/svg/userImg.jpeg";

const UpdateGame = (props) => {
  const {
    setShowUpdateGameModal,
    refetchUpdatedGameData,
    refetchDeletedGameData,
    games,
  } = props;
  const role = localStorage.getItem("userRole");
  const teacherId = localStorage.getItem("teacherId");
  const admin = role === "admin";
  const teacher = role === "teacher";
  const gameId = games?.gameId;
  const tourId = games?.tourId;
  const gameTeacherId = games?.teacherFindId;
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTeachers, setSelectedTeachers] = useState(null);
  const [Error, setError] = useState(false);
  const [showGameDeleteModal, setShowGameDeleteModal] = useState(false);

  const [updateGame] = useUpdateGameMutation();
  const { data: teacherData } = useGetTeacherQuery();
  const teachers = role === "admin" ? teacherData : "";

  // *** Date formate for old date *** //
  useEffect(() => {
    const dateObject = new Date(parseInt(games?.date));

    setStartDate(dateObject);
    const oldDateFormate = `${dateObject?.getDate()} ${dateObject.toLocaleString(
      "default",
      {
        month: "short",
      }
    )} ${dateObject.getFullYear()}`;
    const oldTimeFormate = dateObject.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: false,
    });
    const oldDateTimeFormatted = `${oldDateFormate} ${oldTimeFormate}`;
    setSelectedDate(new Date(oldDateTimeFormatted));
  }, []);

  // *** Date formate for selected date *** //
  const handleDate = (date) => {
    setStartDate(date);
    const selectedDateFormate = `${date.getDate()} ${date.toLocaleString(
      "default",
      {
        month: "short",
      }
    )} ${date.getFullYear()}`;
    const selectedTimeFormate = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: false,
    });
    const selectedDateTimeFormatted = `${selectedDateFormate} ${selectedTimeFormate}`;
    setSelectedDate(new Date(selectedDateTimeFormatted));
  };

  const dateError = selectedDate <= new Date();

  const list = _.map(teachers?.data, (item) => {
    return {
      name: item.profile.firstName + " " + item.profile.lastName,
      profileImage: item.profile.profileImage,
      id: item.teacherId,
    };
  });

  const onImageError = (e) => {
    e.target.src = userImg;
  };

  const teacherOptions = _.map(list, (item) => ({
    value: item.id,
    label: (
      <>
        <div className="flex flex-row w-24 h-10 whitespace-nowrap gap-3 p-1">
          <img
            src={item.profileImage}
            alt="profileImage"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "5px",
            }}
            onError={onImageError}
          />
          <span className="font-sfpro mt-1 font-semibold text-base text-neutral-800">
            {item.name}
          </span>
        </div>
      </>
    ),
  }));

  const matchedIds = list.filter((id) => {
    return gameTeacherId?.includes(id?.id);
  });

  const teacherError = _.isEmpty(selectedTeachers);

  useEffect(() => {
    const matchedTeachers = _.map(matchedIds, (item) => ({
      value: item.id,
      label: item.name,
    }));
    setSelectedTeachers(matchedTeachers);
  }, []);

  const handleTeacherSelectChange = (selectedOptions) => {
    setSelectedTeachers(selectedOptions);
  };

  const handleUpdate = async (values) => {
    setError(admin ? teacherError || dateError : teacher && dateError);
    if (admin ? !(teacherError || dateError) : teacher && !dateError) {
      const id = games.gameId;
      const data = await updateGame({ values, id });
      if (data?.data?.status === "success") {
        toast("Tour updated successfully", { autoClose: 1000 });
        setShowUpdateGameModal(false);
        refetchUpdatedGameData();
      } else {
        toast.error(data?.error?.data?.message, { autoClose: 1000 });
      }
    }
  };

  const initialValues = {
    name: games?.name,
    date: games?.date,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Tour name is required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const data = {
          ...values,
          teacherId:
            !_.isEmpty(selectedTeachers) || null
              ? selectedTeachers.value || gameTeacherId
              : teacherId,
          date: selectedDate,
        };
        handleUpdate(data);
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className="fixed inset-0 bg-black/25 w-auto h-auto z-1 p-8 flex">
            <div className="relative bg-white p-4 sm:p-10 m-auto w-auto max-h-full overflow-y-auto shadow-xl rounded-md">
              <div className="flex justify-end">
                <img
                  alt="closeIcon"
                  src={closeIcon}
                  className="cursor-pointer p-2 border-2 border-gray-500 border-solid rounded-xl"
                  onClick={() => setShowUpdateGameModal(false)}
                />
              </div>
              <div className="flex flex-col max-[639px]:gap-5 sm:gap-7">
                <div>
                  <span className="text-black font-sfpro font-semibold text-2xl sm:text-3xl">
                    Update tour
                  </span>
                </div>
                <div className="h-20 w-full">
                  <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                    Tour Name
                    <Field
                      type="text"
                      name="name"
                      className="mt-2 font-sfpro font-medium text-lg sm:text-xl text-black leading-6 w-full py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                    />
                    <div className="text-red-600 text-sm">
                      <ErrorMessage name="name" />
                    </div>
                  </span>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                    Date
                  </span>
                  <div className="h-12">
                    <div className="border-1 border-solid border-[#939393] rounded-3xl p-2">
                      <DatePicker
                        name="date"
                        className="focus:outline-none relative cursor-pointer"
                        selected={startDate}
                        onChange={handleDate}
                        timeInputLabel="Time:"
                        dateFormat="dd MMM yyyy hh:mm aa"
                        showTimeInput
                      />
                      <img
                        className={`max-[320px]:hidden absolute right-9 ${
                          role === "admin"
                            ? "max-[639px]:bottom-[16.6rem] sm:bottom-[14.1rem]"
                            : "max-[639px]:bottom-[10.3rem] sm:bottom-[7.5rem]"
                        }`}
                        alt="calenderIcon"
                        src={calendarIcon}
                      />
                    </div>
                    {Error && dateError && (
                      <div className="text-red-600 font-semibold text-sm">
                        <span>Selected date should be a future date</span>
                      </div>
                    )}
                  </div>
                </div>
                {role === "admin" && (
                  <div className="h-20 w-full">
                    <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                      Teacher list
                      <Select
                        name="teacher"
                        field="teacher"
                        setFieldValue={setFieldValue}
                        options={teacherOptions}
                        value={selectedTeachers}
                        onChange={handleTeacherSelectChange}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={true}
                      />
                      {Error && teacherError && (
                        <div className="text-red-600 font-semibold text-sm">
                          <span>Please select a teacher</span>
                        </div>
                      )}
                    </span>
                  </div>
                )}
                <div className="w-full flex flex-col sm:flex-row justify-center items-center gap-2">
                  <button
                    type="submit"
                    className="flex flex-row items-center gap-2 py-2 px-12 border-3 border-[#8656A5] rounded-full text-[#8656A5] font-sfpro text-lg leading-7"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowGameDeleteModal(true)}
                    className="flex flex-row items-center gap-2 bg-[#8656A5] py-2.5 px-14 rounded-full text-white font-sfpro text-lg leading-7"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          {showGameDeleteModal && (
            <DeleteModal
              setShowGameDeleteModal={setShowGameDeleteModal}
              showGameDeleteModal={showGameDeleteModal}
              gameId={gameId}
              tourId={tourId}
              refetchDeletedGameData={refetchDeletedGameData}
              setShowUpdateGameModal={setShowUpdateGameModal}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default UpdateGame;
