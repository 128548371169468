import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor, prepareHeaders } from "./util";
import { api } from "../utils/constants/url";

export const studentApi = createApi({
  reducerPath: "studentApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: `${api.baseURL}/student`,
    prepareHeaders,
  }),
  tagTypes: ["Student"],
  endpoints: (builder) => ({
    getStudent: builder.query({
      query: (gameId) => {
        const role = localStorage.getItem("userRole");
        const tId = localStorage.getItem("teacherId");
        return {
          url: `/getStudent${
            role === "teacher"
              ? `?teacherId=${tId}`
              : role === "teacher" && gameId
              ? `?teacherId=${tId}&gameId=${gameId}`
              : gameId
              ? `?gameId=${gameId}`
              : ""
          }`,
          method: "GET",
        };
      },
    }),
    createStudent: builder.mutation({
      query: (payload) => {
        const role = localStorage.getItem("userRole");
        const { formData, tId } = payload;
        return {
          url: `/createStudent${role === "teacher" ? `?teacherId=${tId}` : ""}`,
          method: "POST",
          body: formData,
        };
      },
    }),
    updateStudent: builder.mutation({
      query: (payload) => {
        const { formData, sId } = payload;
        return {
          url: `/updateStudent/${sId}`,
          method: "PUT",
          body: formData,
        };
      },
    }),
    deleteStudent: builder.mutation({
      query: (sId) => {
        return {
          url: `/deleteStudent/${sId}`,
          method: "DELETE",
        };
      },
    }),
    selectedStudentInGame: builder.mutation({
      query: (body) => {
        return {
          url: "/selectedStudentInGame",
          method: "POST",
          body,
        };
      },
    }),
    selectedStudentInGroup: builder.mutation({
      query: (body) => {
        return {
          url: "/selectedStudentInGroup",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetStudentQuery,
  useCreateStudentMutation,
  useUpdateStudentMutation,
  useDeleteStudentMutation,
  useSelectedStudentInGameMutation,
  useSelectedStudentInGroupMutation,
} = studentApi;
