import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuthInterceptor } from "./util";
import { api } from "../utils/constants/url";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithAuthInterceptor({
    baseUrl: api.baseURL,
  }),
  tagTypes: ["CurrentUser"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => {
        return {
          url: "/login",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CurrentUser"],
    }),
    forgetPassword: builder.mutation({
      query: (body) => {
        return {
          url: "/forgetPassword",
          method: "POST",
          body,
        };
      },
    }),
    compareVerificationCode: builder.mutation({
      query: (body) => {
        return {
          url: "/compareverificationcode",
          method: "POST",
          body,
        };
      },
    }),
    resetPassword: builder.mutation({
      query: (body) => {
        return {
          url: "/resetpassword",
          method: "POST",
          body,
        };
      },
    }),
    logout: builder.mutation({
      query: (body) => {
        return {
          url: "/logout",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CurrentUser"],
    }),
    createTeacherSingUp: builder.mutation({
      query: (body) => {
        return {
          url: "/signupForTeacher/teacherSignup",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CurrentUser"],
    }),
  }),
});

export const {
  useLoginMutation,
  useForgetPasswordMutation,
  useCompareVerificationCodeMutation,
  useResetPasswordMutation,
  useLogoutMutation,
  useCreateTeacherSingUpMutation,
} = authApi;
