import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import LayoutContainer from "../../layout/LayoutContainer";
import { useHistoryOfTourQuery } from "../../../api/tour";
import { useNavigate } from "react-router-dom";

import Loader from "../components/Loader";
import { TbMoodEmpty } from "react-icons/tb";

const AllTours = () => {
  const navigate = useNavigate();
  const tId = localStorage.getItem("teacherId");
  const [allToursData, setAllToursData] = useState([]);
  const [tourClick, setTourClick] = useState(false);
  const [tourId, setTourId] = useState("");
  const [tours, setTours] = useState("");

  const { data: allTours, refetch, isFetching } = useHistoryOfTourQuery(tId);

  useEffect(() => {
    if (tourClick) {
      navigate(`/completedTours/tourId=${tourId}`, {
        state: tours,
      });
    }
  }, [tourClick, navigate, tourId, tours]);

  useEffect(() => {
    if (allTours?.data?.status === "success") return;
    if (allTours) {
      setAllToursData(allTours.data);
    } else {
      setAllToursData([]);
    }
  }, [allTours, refetch]);

  return (
    <>
      <LayoutContainer />
      <div className="max-[639px]:px-2 px-8 max-[330px]:pt-0">
        <div className="flex flex-row gap-5">
          <span
            className="font-sfpro text-xl sm:text-2xl cursor-pointer"
            onClick={() => navigate("/recentHistory")}
          >
            Recent
          </span>
          <span className="font-sfpro text-xl sm:text-2xl cursor-default px-2 border-b-2 font-bold border-[#8656A5] text-[#8656A5]">
            All locations
          </span>
        </div>
        <div className="h-[32rem] max-[320px]:h-[23rem] max-[390px]:h-[30rem] max-[395px]:h-[36rem] min-[400px]:h-[42rem] sm:h-[26rem] md:h-[34rem] lg:h-[34rem] xl:h-[40rem] overflow-y-auto border-b-2">
          <div className="py-4 flex flex-row flex-wrap gap-4">
            {!_.isEmpty(allToursData) ? (
              _.map(allToursData, (item) => {
                return (
                  <div
                    key={item.tourId}
                    className="cursor-default flex flex-row justify-between max-[639px]:p-4 sm:p-6 border-2 border-solid rounded-3xl shadow-sm lg:w-[30rem] md:w-[25rem] w-[18rem]"
                  >
                    <div className="flex flex-col gap-2">
                      <span
                        onClick={() => {
                          if (item.gameCount !== 0) {
                            setTourClick(true);
                            localStorage.setItem("tourId", item.tourId);
                            setTourId(item.tourId);
                            setTours(item);
                          }
                        }}
                        className={`${
                          item.gameCount !== 0
                            ? "cursor-pointer"
                            : "cursor-not-allowed"
                        }  text-black font-sfpro font-medium text-xl sm:text-2xl`}
                      >
                        {item.name}
                      </span>
                      <span className="text-[#939393] cursor-default font-sfpro font-normal text-base">
                        {item.gameCount + " " + "Tours"}
                      </span>
                    </div>
                  </div>
                );
              })
            ) : !_.isEmpty(allToursData) || isFetching ? (
              <Loader />
            ) : (
              _.isEmpty(allToursData) && (
                <div className="mx-auto mt-20 py-4 flex flex-col justify-center gap-3">
                  <span className="flex justify-center">
                    <TbMoodEmpty size={100} />
                  </span>
                  <span className="flex justify-center text-gray-800 font-sfpro text-lg leading-7">
                    No locations data..!
                  </span>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllTours;
