import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import downloadIcon from "../../../assets/svg/downloadIcon.svg";
import { toast } from "react-toastify";
import closeIcon from "../../../assets/svg/closeIcon.svg";
import teacherSample from "../../../assets/csv/teacherSample.csv";
import studentSample from "../../../assets/csv/studentSample.csv";
import { useTeacherCSVMutation, useStudentCSVMutation } from "../../../api/csv";

const Csv = (props) => {
  const { setShowCsvModal, refetchTeacherCsv, refetchStudentCsv } = props;
  const location = useLocation();
  const teacher = location.pathname === "/teacher";
  const student = location.pathname === "/student";
  const teacherId = localStorage.getItem("teacherId");

  const [files, setFiles] = useState();

  const [teacherCSV] = useTeacherCSVMutation();
  const [studentCSV] = useStudentCSVMutation();

  const handleClose = () => {
    setShowCsvModal(false);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files[0];
    setFiles(selectedFiles);
  };

  const handleRemoveFile = () => {
    setFiles(null);
  };

  const teacherDownloadCSV = () => {
    const link = document.createElement("a");
    link.href = teacherSample;
    link.click();
  };

  const studentDownloadCSV = () => {
    const link = document.createElement("a");
    link.href = studentSample;
    link.click();
  };

  const handleTeacherImport = async (files) => {
    const formData = new FormData();
    formData.append("csv", files);
    const data = await teacherCSV(formData);
    if (
      data?.data?.status === "success" &&
      data?.data?.data?.skippedRecords.length === 0
    ) {
      toast("CSV file uploaded successful", { autoClose: 1000 });
      refetchTeacherCsv();
      setShowCsvModal(false);
    } else if (data?.data?.data?.skippedRecords.length > 0) {
      toast.error("Please follow the formate of sample file", {
        autoClose: 1000,
      });
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  const handleStudentImport = async (files) => {
    const formData = new FormData();
    formData.append("csv", files);
    formData.append("teacherId", teacherId);
    const data = await studentCSV(formData);
    if (
      data?.data?.status === "success" &&
      data?.data?.data?.skippedRecords.length === 0
    ) {
      toast("CSV file uploaded successful", { autoClose: 1000 });
      refetchStudentCsv();
      setShowCsvModal(false);
    } else if (data?.data?.data?.skippedRecords.length > 0) {
      toast.error("Please follow the formate of sample file", {
        autoClose: 1000,
      });
    } else {
      toast.error(data?.error?.data?.message, { autoClose: 1000 });
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-black/25 h-auto w-auto z-1 max-[320px]:p-4 min-[350px]:p-8 overflow-y-auto flex">
        <div className="relative bg-white p-4 sm:p-10 w-auto h-auto m-auto shadow-xl rounded-md">
          <form method="POST">
            <div className="flex flex-col gap-5">
              <div className="w-full flex flex-col sm:flex-row justify-between sm:gap-2">
                <span className="font-sfpro font-bold text-2xl sm:text-4xl text-black leading-10">
                  Add CSV file
                </span>
                <div className="flex flex-row gap-3 items-center">
                  <img
                    alt="downloadIcon"
                    src={downloadIcon}
                    className=" cursor-pointer"
                    onClick={
                      (teacher && teacherDownloadCSV) ||
                      (student && studentDownloadCSV)
                    }
                  />
                  <span className="font-sfpro font-bold text-base text-black leading-5 whitespace-nowrap">
                    Download sample CSV
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="w-full rounded-3xl divide-y divide-dashed">
                  <input
                    type="file"
                    accept="text/csv"
                    className="input_border"
                    onChange={handleFileChange}
                  />
                </div>
                {files && (
                  <div>
                    <hr className="border-2 border-dashed border-[#939393]" />
                  </div>
                )}

                {files && (
                  <div className="grid grid-cols-12 justify-between rounded-3xl">
                    <div className="col-span-11">
                      <input
                        type="text"
                        value={files.name}
                        className="input_border"
                        readOnly
                      />
                    </div>
                    <div className="flex justify-center items-center">
                      <img
                        alt="closeIcon"
                        src={closeIcon}
                        className="cursor-pointer"
                        onClick={handleRemoveFile}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex gap-2 w-full">
                <button
                  className="py-2 px-8 w-1/2 font-sfpro font-medium text-base leading-6 text-gray-700 border-1 border-solid border-gray-300 rounded-3xl"
                  type="button"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  className="py-2 px-8 w-1/2 font-sfpro font-medium text-base leading-6 bg-[#8656A5] text-white border-3 border-gray-300 rounded-3xl"
                  type="button"
                  onClick={() => {
                    (teacher && handleTeacherImport(files)) ||
                      (student && handleStudentImport(files));
                  }}
                >
                  Import
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Csv;
