import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { BsEye, BsEyeSlash } from "react-icons/bs";

import singUp from "../../../assets/svg/teacherSingUp.svg";
import InputField from "../../../assets/svg/inputFieldIcon.svg";
import emailIcon from "../../../assets/svg/emailIcon.svg";
import passowrdIcon from "../../../assets/svg/passwordIcon.svg";
import { useCreateTeacherSingUpMutation } from "../../../api/auth";

const appendFormValuesToFormData = (formData, values) => {
  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      formData.append(key, values[key]);
    }
  }
};

const TeacherSingUp = () => {
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [SingnUp] = useCreateTeacherSingUpMutation();

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    profileImage: Yup.mixed(),
  });

  const initialValues = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    profileImage: "",
  };

  const handleSingUp = async (values) => {
    const formData = new FormData();
    appendFormValuesToFormData(formData, values);

    const responsse = await SingnUp(formData);
    if (responsse?.data?.status === 201) {
      toast.success(responsse?.data?.message);
      navigate("/login");
    } else if (responsse?.error?.status === 400) {
      toast.error(responsse?.error?.data?.message);
    }
  };

  return (
    <>
      <div className="container">
        <div className="w-full flex flex-row gap-20 items-center justify-center h-screen">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-none d-lg-block">
            <img src={singUp} alt={""} className="w-100" />
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <div className="flex flex-col gap-16">
              <div className="text-start">
                <span className="fs-3 fw-bold text-[#8656A5]">Register</span>
                <p className="fs-5 fw-bold text-[#939393]">
                  Please register by completing the information below
                </p>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSingUp}
              >
                {({ handleSubmit, setFieldValue, values }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-28">
                      <Row className="">
                        <Col sm={12}>
                          <div className="d-flex justify-content-center">
                            <input
                              className="d-none"
                              type="file"
                              id="profileImage"
                              name="profileImage"
                              onChange={(event) => {
                                setFieldValue(
                                  "profileImage",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                            <div className="position-relative">
                              <label
                                htmlFor="profileImage"
                                className="border-2 border-[#8656A5] rounded-full w-[164px] h-[164px]"
                              >
                                {values.profileImage && (
                                  <img
                                    src={URL.createObjectURL(
                                      values.profileImage
                                    )}
                                    alt="Selected"
                                    className="w-full h-full object-contain rounded-full"
                                  />
                                )}
                              </label>
                              <label
                                htmlFor="profileImage"
                                className="w-[24px] h-[24px] border-2 border-[#8656A5] bg-[#FFF] text-[#939393] fs-5 fw-bold position-absolute rounded-full text-center"
                                style={{ bottom: "25px", right: "5px" }}
                              >
                                <div
                                  className=""
                                  style={{
                                    marginTop: "-6px",
                                    marginLeft: "2px",
                                  }}
                                >
                                  +
                                </div>
                              </label>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6} className="mt-3">
                          <div className="w-full text-start font-sfpro font-bold text-base text-[#939393]">
                            First name
                            <div className="relative">
                              <img
                                src={InputField}
                                alt="firstName"
                                className="absolute z-10 top-2 left-4"
                              />
                              <Field
                                type="text"
                                name="firstName"
                                autoComplete="username"
                                className="w-full relative font-semibold font-sfpro text-[#8656A5] text-base border-2 rounded-full border-[#8656A5] pl-12 pr-2 py-2"
                              />
                            </div>
                            <div className="text-red-600 text-sm">
                              <ErrorMessage name="firstName" />
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6} className="mt-3">
                          <div className="w-full text-start font-sfpro font-bold text-base text-[#939393]">
                            Last name
                            <div className="relative">
                              <img
                                src={InputField}
                                alt="lastName"
                                className="absolute z-10 top-2 left-4"
                              />
                              <Field
                                type="text"
                                name="lastName"
                                autoComplete="username"
                                className="w-full relative font-semibold font-sfpro text-[#8656A5] text-base border-2 rounded-full border-[#8656A5] pl-12 pr-2 py-2"
                              />
                            </div>
                            <div className="text-red-600 text-sm">
                              <ErrorMessage name="lastName" />
                            </div>
                          </div>
                        </Col>
                        <Col sm="12" className="mt-3">
                          <div className="w-full text-start font-sfpro font-bold text-base text-[#939393]">
                            Email
                            <div className="relative mt-2">
                              <img
                                src={emailIcon}
                                alt="email"
                                className="absolute z-10 top-4 left-4"
                              />
                              <Field
                                type="text"
                                name="email"
                                autoComplete="username"
                                className="w-full relative font-semibold font-sfpro text-[#8656A5] text-base border-2 rounded-full border-[#8656A5] pl-12 pr-2 py-2"
                              />
                            </div>
                            <div className="text-red-600 text-sm">
                              <ErrorMessage name="email" />
                            </div>
                          </div>
                        </Col>
                        <Col sm="12" className="mt-3">
                          <div className="w-full text-start font-sfpro font-bold text-base text-[#939393]">
                            Password
                            <div className="relative mt-2">
                              <img
                                src={passowrdIcon}
                                alt="password"
                                className="absolute z-10 top-3 left-5"
                              />
                              <Field
                                name="password"
                                type={passwordVisible ? "text" : "password"}
                                autoComplete="current-password"
                                className="w-full relative font-semibold font-sfpro text-[#8656A5] text-base border-2 rounded-full border-[#8656A5] pl-12 pr-2 py-2"
                              />
                              <button
                                type="button"
                                className="absolute z-10 top-3 right-5"
                                onClick={togglePasswordVisibility}
                              >
                                {passwordVisible ? (
                                  <BsEye size={22} />
                                ) : (
                                  <BsEyeSlash size={22} />
                                )}
                              </button>
                            </div>
                            <div className="text-red-600 text-sm">
                              <ErrorMessage name="password" />
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} className="mt-5">
                          <div className="flex items-center justify-center">
                            <button
                              type="submit"
                              onSubmit={handleSingUp}
                              className="flex justify-center gap-4 items-center uppercase font-sfpro bg-[#8656A5] text-white w-10/12 py-2 border-3 border-gray-300 rounded-xl"
                            >
                              Register
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherSingUp;
