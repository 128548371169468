import _ from "lodash";
import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "../api/auth";
import { teacherApi } from "../api/teacher";
import { csvApi } from "../api/csv";
import { studentApi } from "../api/student";
import { tourApi } from "../api/tour";
import { gameApi } from "../api/game";
import { groupApi } from "../api/group";
import { feedbackApi } from "../api/feedback";
import { profileApi } from "../api/profile";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import profileReducer from "../redux/slices/profileSlice";
import authReducer from "../redux/slices/authSlice";
import { stopApi } from "../api/stop";

const rootReducer = combineReducers({
  profile: profileReducer,
  auth: authReducer,
  [authApi.reducerPath]: authApi.reducer,
  [teacherApi.reducerPath]: teacherApi.reducer,
  [csvApi.reducerPath]: csvApi.reducer,
  [studentApi.reducerPath]: studentApi.reducer,
  [tourApi.reducerPath]: tourApi.reducer,
  [gameApi.reducerPath]: gameApi.reducer,
  [groupApi.reducerPath]: groupApi.reducer,
  [feedbackApi.reducerPath]: feedbackApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [stopApi.reducerPath]: stopApi.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["profile"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(authApi.middleware)
      .concat(teacherApi.middleware)
      .concat(csvApi.middleware)
      .concat(studentApi.middleware)
      .concat(tourApi.middleware)
      .concat(gameApi.middleware)
      .concat(groupApi.middleware)
      .concat(feedbackApi.middleware)
      .concat(stopApi.middleware)
      .concat(profileApi.middleware),
});

export const persistor = persistStore(store);
export default store;
