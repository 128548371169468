import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import * as _ from "lodash";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useGetTeacherQuery } from "../../../api/teacher";
import { useCreateGroupMutation } from "../../../api/group";
import createGroupImage from "../../../assets/svg/createGroupImage.svg";
import userImg from "../../../assets/svg/userImg.jpeg";

const CreateGroup = (props) => {
  const { setShowCreateGroupForm, refetchCreatedGroupData } = props;
  const role = localStorage.getItem("userRole");
  const tId = localStorage.getItem("teacherId");

  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [Error, setError] = useState(false);

  const [createGroup] = useCreateGroupMutation();
  const { data: teacherData } = useGetTeacherQuery();
  const teachers = role === "admin" ? teacherData : "";

  const teacherId = selectedTeachers.value;

  const list = _.map(teachers?.data, (item) => {
    return {
      name: item.profile.firstName + " " + item.profile.lastName,
      profileImage: item.profile.profileImage,
      id: item.teacherId,
    };
  });

  const onImageError = (e) => {
    e.target.src = userImg;
  };

  const teacherOptions = _.map(list, (item) => ({
    value: item.id,
    label: (
      <>
        <div className="flex flex-row w-24 h-10 whitespace-nowrap gap-3 p-1">
          <img
            src={item.profileImage}
            alt="profileImage"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "5px",
            }}
            onError={onImageError}
          />
          <span className="font-sfpro mt-1 font-semibold text-base text-neutral-800">
            {item.name}
          </span>
        </div>
      </>
    ),
  }));

  const teacherError = _.isEmpty(selectedTeachers);

  const handleTeacherSelectChange = (selectedOptions) => {
    setSelectedTeachers(selectedOptions);
  };

  const handleSave = async (values) => {
    setError(teacherError);
    if (!Error) {
      const data = await createGroup(values);
      if (data?.data?.status === "success") {
        toast("Group added successfully", { autoClose: 1000 });
        setShowCreateGroupForm(false);
        refetchCreatedGroupData();
      } else {
        toast.error(data?.error?.data?.message, { autoClose: 1000 });
      }
    }
  };

  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Group name is required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const data = {
          ...values,
          teacherId: role === "admin" ? teacherId : tId,
        };
        handleSave(data);
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className="fixed inset-0 bg-black/25 w-auto h-auto z-1 p-8 flex">
            <div className="relative bg-white p-4 sm:p-10 m-auto w-auto max-h-full overflow-y-auto shadow-xl rounded-md">
              <div className="max-[639px]:py-4 sm:py-20 sm:px-8 gap-4 flex flex-row items-center justify-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 d-none d-lg-block">
                  <img alt="createGroupImage" src={createGroupImage} />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="flex flex-col sm:gap-5 max-[639px]:gap-4">
                    <span className="flex justify-center text-black font-sfpro font-semibold sm:text-3xl text-2xl">
                      Add Group
                    </span>
                    <div className="flex flex-col sm:gap-8 max-[639px]:gap-3">
                      <div className="h-20 w-full">
                        <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                          Group Name
                          <Field
                            type="text"
                            name="name"
                            className="mt-2 font-sfpro font-medium text-lg sm:text-xl text-black leading-6 w-full max-[639px]:py-1 sm:py-2 px-3 border-1 border-solid border-[#939393] rounded-3xl"
                          />
                          <div className="text-red-600 text-sm">
                            <ErrorMessage name="name" />
                          </div>
                        </span>
                      </div>
                      {role === "admin" && (
                        <div className="h-20 w-full">
                          <span className="font-sfpro font-semibold text-lg sm:text-xl text-[#939393]">
                            Teacher list
                            <Select
                              name="teacher"
                              field="teacher"
                              setFieldValue={setFieldValue}
                              options={teacherOptions}
                              value={selectedTeachers}
                              onChange={handleTeacherSelectChange}
                              hideSelectedOptions={false}
                              closeMenuOnSelect={true}
                            />
                            {Error && teacherError && (
                              <div className="text-red-600 font-semibold text-sm">
                                <span>Please select a teacher</span>
                              </div>
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="w-full flex sm:flex-row flex-col justify-center gap-3 items-center">
                      <button
                        type="button"
                        onClick={() => setShowCreateGroupForm(false)}
                        className="py-2 px-16 font-sfpro font-medium text-base leading-6 text-gray-700 border-1 border-solid rounded-xl"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="py-2 px-16 font-sfpro font-medium text-base leading-5 bg-[#8656A5] text-white border-3 border-gray-300 rounded-xl"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateGroup;
